import React from 'react';
import { SchematicField } from '../../../../../../../../../../Objects/types/AppearanceBase';
import { Parameter } from '../../../../../../../../../../Parameters/types/ParameterTypes';
import { Label } from '../../../../../../../../../../Users/components/TopPanel/components/Steps/StepFourth/styled';
import { FormikSelect } from '../../../../../../../../../../../../tools/FormikFields';

interface FieldProps {
    field: SchematicField;
    parameters: Parameter[];
    setParameter: (field: SchematicField, parameter: Parameter) => void;
}

export function Field(props: FieldProps) {
    function onChangeHandle(option: Parameter) {
        props.setParameter(props.field, option);
    }
    return (
        <div>
            <Label>{props.field.label}</Label>
            <FormikSelect
                cacheOptions
                options={props.parameters}
                value={props.field.parameter}
                getOptionLabel={(opt: Parameter) => opt.name!.label}
                getOptionValue={(opt: Parameter) => opt.id}
                defaultOptions
                onChange={onChangeHandle}
                placeholder="Parameter"
            />
        </div>
    );
}
