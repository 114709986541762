import React, { useContext } from 'react';
import { BottomNavBar, DataGrid } from '../../../../../../../components';
import {
    SummaryMonthDataGridData,
    SummaryPeriodDataGridData,
    SummaryYearDataGridData,
} from './types';
import {
    summaryMonthDataGridHeading,
    summaryMonthDataGridKeys,
    summaryPeriodDataGridHeading,
    summaryPeriodDataGridKeys,
    summaryYearDataGridHeading,
    summaryYearDataGridKeys,
} from './constants';
import { useOrganizationFormState } from '../../hooks/useOrganizationFormState';
import { NotificationHandlersContext } from '../../../../../../../containers/App/components/AppWithMenu/AppWithMenu';
import { useAddOrganization } from '../../hooks/useAddOrganization';
import { useOrganizationsState } from '../../../../../hooks/useOrganizationsState';

interface SummaryProps {
    prevStep: () => void;
    nextStep: () => void;
    closeDrawer: () => void;
}

export function Summary(props: SummaryProps) {
    const [state] = useOrganizationFormState();
    const [, , , getOrgs] = useOrganizationsState();
    const [
        addNewOrganization,
        ,
        organizationWasAdded,
        addedMessageError,
        resetStore,
        resetError,
    ] = useAddOrganization(state);

    const summaryMonthDataGridData: SummaryMonthDataGridData[] = [
        {
            monthSummary: state.summary.monthly.price,
        },
    ];

    const summaryPeriodDataGridData: SummaryPeriodDataGridData[] = [
        {
            periodSummary: state.summary.annually.price,
        },
    ];

    const summaryYearDataGridData: SummaryYearDataGridData[] = [
        {
            yearSummary: state.summary.wholePeriod.price,
        },
    ];

    const notifyHandlers = useContext(NotificationHandlersContext);

    async function addOrganization() {
        addNewOrganization();
    }

    if (organizationWasAdded) {
        notifyHandlers.showSuccess('Organizacja została dodana');
        props.closeDrawer();
        resetStore();
        getOrgs();
    }

    if (addedMessageError) {
        notifyHandlers.showError(addedMessageError);
        resetError();
    }

    return (
        <>
            <h2>Miesięcznie</h2>
            <DataGrid
                headings={summaryMonthDataGridHeading}
                keys={summaryMonthDataGridKeys}
                data={summaryMonthDataGridData}
            />
            <h2>Roczne</h2>
            <DataGrid
                headings={summaryYearDataGridHeading}
                keys={summaryYearDataGridKeys}
                data={summaryYearDataGridData}
            />
            <h2>Za cały okres trwania</h2>
            <DataGrid
                headings={summaryPeriodDataGridHeading}
                keys={summaryPeriodDataGridKeys}
                data={summaryPeriodDataGridData}
            />
            <BottomNavBar
                nextStep={addOrganization}
                prevStep={props.prevStep}
                showNext={true}
                buttonNextText="Dodaj"
            />
        </>
    );
}
