import {
    AddObject,
    ObjectById,
    ObjectSettings,
    ObjectTypes,
    OrganizationObjectById,
    OrganizationObjects,
    UpdateObject,
} from '../../../../api/api';
import { MachineObject, UpdateMachineObject } from '../types';
import { request } from '../../../../tools';
import { ObjectsActionTypes } from '../models/actionTypes';
import { Dispatch } from 'redux';

export function getObjects(organizationId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ObjectsActionTypes.GetObjects,
            organizationId,
        });

        try {
            const objects = await request<MachineObject[]>(
                'GET',
                OrganizationObjects(organizationId),
            );
            dispatch(getObjectsSuccessfully(objects, Number(organizationId)));
        } catch (err) {
            dispatch(getObjectsFail(Number(organizationId)));
        }
    };
}

function getObjectsFail(organizationId: number) {
    return {
        type: ObjectsActionTypes.GetObjectsFailure,
        organizationId,
    };
}

function getObjectsSuccessfully(
    objects: MachineObject[],
    organizationId: number,
) {
    return {
        type: ObjectsActionTypes.GetObjectsSuccessfully,
        objects,
        organizationId,
    };
}

export function updateObjectForm(
    formValue: Partial<MachineObject>,
    draftId: string,
) {
    return {
        type: ObjectsActionTypes.UpdateAddObjectFormData,
        formValue,
        draftId,
    };
}

export function setRemoved(formValue: Partial<MachineObject>) {
    return {
        type: ObjectsActionTypes.UpdateAddObjectFormData,
        formValue,
    };
}

export function resetObjectForm() {
    return {
        type: ObjectsActionTypes.ResetAddObjectFormData,
    };
}

export function resetErrorsObjectForm() {
    return {
        type: ObjectsActionTypes.ResetErrorsAddObjectFormData,
    };
}

export function getObjectTypes() {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ObjectsActionTypes.GetObjectTypes,
        });

        try {
            const objectTypes = await request('GET', ObjectTypes);
            dispatch(getObjectTypesSuccess(objectTypes));
        } catch (err) {
            dispatch(getObjectTypesFailed(JSON.stringify(err)));
        }
    };
}

export function getObjectTypesSuccess(objectTypes: any) {
    return {
        type: ObjectsActionTypes.GetObjectTypesSuccess,
        objectTypes,
    };
}

export function getObjectTypesFailed(error: string) {
    return {
        type: ObjectsActionTypes.GetObjectTypesFailed,
        error,
    };
}

export function getObjectParameters(objectId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ObjectsActionTypes.GetObjectParameters,
        });

        try {
            const parameters = await request('GET', ObjectById(objectId));
            dispatch(getObjectParametersSuccess(parameters));
        } catch (err) {
            dispatch(getObjectParametersFailed(JSON.stringify(err)));
        }
    };
}

function getObjectParametersSuccess(parameters: any) {
    return {
        type: ObjectsActionTypes.GetObjectParametersSuccess,
        parameters,
    };
}

function getObjectParametersFailed(error: string) {
    return {
        type: ObjectsActionTypes.GetObjectParametersFailed,
        error,
    };
}

export function getObjectsSettings(objectId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ObjectsActionTypes.GetObjectSettings,
        });

        try {
            const settings = await request('GET', ObjectSettings(objectId));
            dispatch(getObjectsSettingsSuccess(settings));
        } catch (err) {
            dispatch(getObjectsSettingsFailed(JSON.stringify(err)));
        }
    };
}

function getObjectsSettingsSuccess(objectSettings: any) {
    return {
        type: ObjectsActionTypes.GetObjectSettingsSuccess,
        objectSettings,
    };
}

function getObjectsSettingsFailed(error: string) {
    return {
        type: ObjectsActionTypes.GetObjectParametersFailed,
        error,
    };
}

export function updateObject(formState: UpdateMachineObject) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ObjectsActionTypes.UpdateObject,
        });

        try {
            await request(
                'PATCH',
                UpdateObject(formState.basicData?.id || 0),
                JSON.stringify(formState),
            );
            dispatch({
                type: ObjectsActionTypes.UpdateObjectSuccess,
            });
        } catch (err) {
            dispatch({
                type: ObjectsActionTypes.UpdateObjectFailed,
                error: JSON.stringify(err),
            });
        }
    };
}

export function addObject(formState: MachineObject) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ObjectsActionTypes.AddObject,
        });

        const data = new FormData();
        Object.keys(formState).forEach(key => {
            if (key === 'photoGalleries' && formState[key].length) {
                data.append(
                    'photoGalleries',
                    JSON.stringify(
                        formState[key]
                            .filter(gallery => !!gallery.galleryPhotos.length)
                            .map(gallery => ({
                                [gallery.tempId]: gallery.galleryName,
                            })),
                    ),
                );
                formState[key].forEach(gallery => {
                    gallery.galleryPhotos.forEach((photo: File) => {
                        data.append(`${gallery.tempId}-photos[]`, photo);
                    });
                });
                return;
            }

            if (key === 'documents') {
                formState[key].forEach(document => {
                    data.append('documents[]', document);
                });
                return;
            }
            // @ts-ignore
            data.append(key, JSON.stringify(formState[key]));
        });

        try {
            // @ts-ignore
            await request('POST', AddObject, data);
            dispatch(addObjectSuccess());
        } catch (err) {
            dispatch(addObjectFailed(JSON.stringify(err)));
        }
    };
}

function addObjectSuccess() {
    return {
        type: ObjectsActionTypes.AddObjectSuccess,
    };
}

function addObjectFailed(error: string) {
    return {
        type: ObjectsActionTypes.AddObjectFailed,
        error,
    };
}

export function changeOrder(objects: MachineObject[], organizationId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ObjectsActionTypes.ChangeOrder,
            objects,
            organizationId,
        });

        const preparedObjects = objects.map((object, index) => ({
            order: index,
            value: object.basicData.id,
        }));

        try {
            await request(
                'PATCH',
                OrganizationObjects(organizationId),
                JSON.stringify(preparedObjects),
            );
        } catch (e) {}
    };
}

export async function deleteObject(organizationId: number, objectId: number) {
    try {
        await request(
            'DELETE',
            OrganizationObjectById(organizationId, objectId),
        );
    } catch (e) {}
}
