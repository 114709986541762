import React from 'react';
import { MenuItem as Item } from '@material-ui/core';
import { MenuItemTextContainer } from '../../../../styled';

interface MenuItemProps {
    clickHandle: (args?: any) => void;
    args: any;
    name: string;
}

export function MenuButtonItem(props: MenuItemProps) {
    function clickHandle() {
        if (props.args) {
            props.clickHandle(props.args);
            return;
        }
        props.clickHandle();
    }

    return (
        <Item onClick={clickHandle}>
            <MenuItemTextContainer>{props.name}</MenuItemTextContainer>
        </Item>
    );
}
