import { TimeParameter } from './TimeParameter';
import { CalculatedParameter } from './CalculatedParameter';
import { FigureParameter } from './FigureParameter';
import { OrderParameter } from './OrderParameter';
import { BinaryParameter } from './BinaryParameter';

export enum ParameterTypes {
    Figure = 'figure',
    Calculated = 'calculated',
    Order = 'order',
    Time = 'time',
    Binary = 'binary',
}

export const ParameterTypesNames = {
    figure: 'Liczbowa',
    calculated: 'Wyliczana',
    order: 'Rozkaz',
    time: 'Czas',
    binary: 'Binarna',
};

export type ParameterType =
    | ParameterTypes.Binary
    | ParameterTypes.Time
    | ParameterTypes.Calculated
    | ParameterTypes.Order
    | ParameterTypes.Figure;

export type Parameter =
    | BinaryParameter
    | CalculatedParameter
    | FigureParameter
    | OrderParameter
    | TimeParameter;
