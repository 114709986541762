import { FormState, OrganizationValues } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../../reducer';
import { setFormDataValue } from '../../../../actions/organizations';
import { addOrganizationFormData } from '../../../../selectors/organizations';

export function useOrganizationFormState(): [
    OrganizationValues,
    (data: Partial<FormState>) => void,
    any,
] {
    const dispatch = useDispatch();
    const organizationForm = useSelector<AppState, OrganizationValues>(state =>
        addOrganizationFormData(state),
    );

    function setData(data: Partial<FormState>): void {
        dispatch(setFormDataValue(data));
    }

    const keysForSummaryCalculate = [
        organizationForm.billing.users['monthSummary-1'],
        organizationForm.billing.users['monthSummary-2'],
        organizationForm.billing.modules['summaryPrice-1'],
        organizationForm.billing.modules['summaryPrice-2'],
        organizationForm.billing.modules['summaryPrice-3'],
        organizationForm.billing.modules['summaryPrice-4'],
        organizationForm.billing.modules['summaryPrice-5'],
        organizationForm.billing.modules['summaryPrice-6'],
        organizationForm.billing.modules['summaryPrice-7'],
        organizationForm.billing.additionalServices.serviceSummary,
    ];

    return [organizationForm, setData, keysForSummaryCalculate];
}
