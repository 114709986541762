import { Organization } from '../../../types';
import { FormState } from '../Organizations/types';

export enum ActionTypes {
    GetOrganizations = '[Organizations] get organizations',
    GetOrganizationsSuccess = '[Organizations] get organizations success',
    GetOrganizationsFailed = '[Organizations] get organizations failed',

    SetFormDataValue = '[Organizations] set form data value',
    ResetStore = '[Organizations] reset store',
    ResetError = '[Organizations] reset error',

    AddOrganization = '[Organizations] add new organization',
    AddOrganizationSuccess = '[Organizations] add new organization success',
    AddOrganizationFailed = '[Organizations] add new organization failed',
}

interface GetOrganizations {
    type: typeof ActionTypes.GetOrganizations;
}

interface ResetStore {
    type: typeof ActionTypes.ResetStore;
}

interface ResetError {
    type: typeof ActionTypes.ResetError;
}

interface GetOrganizationsSuccess {
    type: typeof ActionTypes.GetOrganizationsSuccess;
    organizations: Organization[];
}

interface GetOrganizationsFailed {
    type: typeof ActionTypes.GetOrganizationsFailed;
    errorMessage: string;
}

interface SetFormDataValue {
    type: typeof ActionTypes.SetFormDataValue;
    formData: FormState;
}

interface AddOrganization {
    type: typeof ActionTypes.AddOrganization;
}

interface AddOrganizationSuccess {
    type: typeof ActionTypes.AddOrganizationSuccess;
}

interface AddOrganizationFailed {
    type: typeof ActionTypes.AddOrganizationFailed;
    errorMessage: string;
}

export type OrganizationUnion =
    | GetOrganizationsFailed
    | GetOrganizationsSuccess
    | GetOrganizations
    | ResetStore
    | ResetError
    | SetFormDataValue
    | AddOrganization
    | AddOrganizationSuccess
    | AddOrganizationFailed;
