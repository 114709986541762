import { addItemToStorage, getItemFromStorage, removeItemFromStorage } from '../../../../tools';
import { StorageKeys } from '../../../../constants/storageKeys';
import { useObjectForm } from './useObjectForm';
import { useState } from 'react';
import { ObjectDraft } from '../types/ObjectDraft';

export interface UseDrafts {
    openDraft: (id: string) => Promise<void>;
    removeDraft: (id: string) => Promise<void>;
    drafts: ObjectDraft[];
    getAllDrafts: () => Promise<void>;
}

export function useDrafts(): UseDrafts {
    const [, updateFormData] = useObjectForm();
    const [drafts, setDrafts] = useState<any>([]);

    async function getAllDrafts(): Promise<void> {
        const drafts = await getItemFromStorage(StorageKeys.AddObjectDrafts);
        const parsedDrafts = JSON.parse(drafts || ('{}' as string));
        setDrafts(Object.keys(parsedDrafts).map(draft => parsedDrafts[draft]));
    }

    async function openDraft(id: string): Promise<void> {
        const drafts = await getItemFromStorage(StorageKeys.AddObjectDrafts);
        const parsedDrafts = JSON.parse(drafts as string);
        updateFormData(parsedDrafts[id].state);
    }

    async function removeDraft(id: string): Promise<void> {
        const drafts = await getItemFromStorage(StorageKeys.AddObjectDrafts);
        const parsedDrafts = JSON.parse(drafts || ('{}' as string));
        delete parsedDrafts[id];
        await removeItemFromStorage(StorageKeys.AddObjectDrafts);
        await addItemToStorage(
            StorageKeys.AddObjectDrafts,
            JSON.stringify(parsedDrafts),
        );
        setDrafts(Object.keys(parsedDrafts).map(draft => parsedDrafts[draft]));
    }

    return {
        openDraft,
        drafts,
        getAllDrafts,
        removeDraft,
    };
}
