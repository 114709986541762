import React from 'react';
import { Parameter } from '../../../../../Parameters/types/ParameterTypes';
import { Formik, FormikProps } from 'formik';
import {
    useObjectForm,
    useRemovedParameters,
} from '../../../../../Objects/hooks';
import { Fields } from './Fields';

interface FormProps {
    prevStepHandle: () => void;
    nextStepHandle: () => void;
}

export function Form(props: FormProps) {
    const [state, setData] = useObjectForm();
    const { setRemovedParameters } = useRemovedParameters();

    function prevHandle(value: Parameter[]) {
        setData({ ...state, parameters: value });
        props.prevStepHandle();
    }

    const onSubmitHandle = (value: Parameter[]) => {
        setData({ ...state, parameters: value });
    };

    return (
        <Formik<Parameter[]>
            onSubmit={onSubmitHandle}
            enableReinitialize={true}
            initialValues={state.parameters}
            render={(otherProps: FormikProps<Parameter[]>) => (
                <Fields
                    nextStepHandle={props.nextStepHandle}
                    prevStepHandle={prevHandle}
                    setRemovedParameters={setRemovedParameters}
                    formState={state}
                    {...otherProps}
                />
            )}
        />
    );
}
