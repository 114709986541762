import { Dispatch } from 'redux';
import { GroupsActionTypes } from '../models/actionTypes';
import { request } from '../../../../tools';
import { Groups, GroupsById } from '../../../../api/api';
import { Group } from '../types/Group';

export function getGroups(organizationId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: GroupsActionTypes.FetchGroups,
        });

        try {
            const groups = await request('GET', Groups(organizationId));
            dispatch({
                type: GroupsActionTypes.FetchGroupsSuccess,
                groups,
            });
        } catch (e) {}
    };
}

export function editGroup(group: Group, organizationId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: GroupsActionTypes.EditGroup,
        });

        try {
            await request(
                'PUT',
                GroupsById(organizationId, group.value as number),
                JSON.stringify(group),
            );
            dispatch({
                type: GroupsActionTypes.EditGroupSuccess,
            });
        } catch (e) {}
    };
}

export function addGroup(group: Group, organizationId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: GroupsActionTypes.AddNewGroup,
        });

        try {
            await request(
                'POST',
                Groups(organizationId),
                JSON.stringify(group),
            );
            dispatch({
                type: GroupsActionTypes.AddNewGroupSuccess,
            });
        } catch (e) {}
    };
}

export function changeOrder(groups: Group[], organizationId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: GroupsActionTypes.UpdateOrder,
            groups,
        });

        const preparedGroups = groups.map((group, index) => ({
            order: index,
            value: group.value,
        }));

        try {
            await request(
                'PATCH',
                Groups(organizationId),
                JSON.stringify(preparedGroups),
            );
        } catch (e) {}
    };
}

export function resetStore() {
    return {
        type: GroupsActionTypes.ResetStore,
    };
}

export function hydrateEditForm(group: Group) {
    return {
        type: GroupsActionTypes.UpdateEditForm,
        group,
    };
}

export async function removeGroupAction(organizationId: number, group: Group) {
    try {
        await request(
            'DELETE',
            GroupsById(organizationId, group.value as number),
        );
    } catch (e) {}
}
