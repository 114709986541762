import React from 'react';
import { FormikProps } from 'formik';
import { CalculatedParameter } from '../../../../types/CalculatedParameter';
import {
    ContentContainer,
    InlineFieldContainer,
    StyledForm,
    VerticalDivider,
} from '../../../../../formStyled';
import { SelectBox, WideField } from '../../../../../../../styled/';
import {
    FormikAsyncSelect,
    FormikCheckBox,
    FormikSelect,
} from '../../../../../../../tools/FormikFields';
import { PARAMETER_TYPES } from '../selectData/selectData';
import { useSelectParameterForm } from '../../../../hooks/useSelectParameterForm';
import { Variables } from './components';
import { useFetchAsyncSelectData } from '../../../../hooks/useFetchAsyncSelectData';
import { SubHeading } from '../../../SubHeading';
import { FormContainer } from '../../../../styled';
import { SubmitPanel } from '../../../SubmitPanel';
import {
    CalculationMethod,
    CalculationMethodVariable,
} from '../../../../types/CalculationMethod';
import { ParameterTypesNames } from '../../../../types/ParameterTypes';

interface FieldsProps extends FormikProps<CalculatedParameter> {
    onCancel: () => void;
}

export function Fields(props: FieldsProps) {
    const setSelectType = useSelectParameterForm<CalculatedParameter>(props);
    const { fetchUnits, fetchCalculationMethods } = useFetchAsyncSelectData();

    function chooseAggregationMethod(option: CalculationMethod) {
        props.setFieldValue('aggregationMethod', option);
    }

    function updateConfigConstantsAtAggregationMethod(
        data: CalculationMethodVariable[],
    ) {
        props.setFieldValue('aggregationMethod', {
            ...props.values.aggregationMethod,
            configConstants: data,
        });
    }

    function updateVariablesAtAggregationMethod(
        data: CalculationMethodVariable[],
    ) {
        props.setFieldValue('aggregationMethod', {
            ...props.values.aggregationMethod,
            variables: data,
        });
    }

    return (
        <FormContainer>
            <SubHeading />
            <StyledForm onSubmit={props.handleSubmit}>
                <ContentContainer>
                    <WideField
                        name="name"
                        value={props.values.name?.label || ''}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.name && props.touched.name}
                        label="Nazwa"
                        variant="outlined"
                        disabled
                    />
                    <InlineFieldContainer>
                        <FormikSelect
                            name="type"
                            cacheOptions
                            options={PARAMETER_TYPES}
                            value={{
                                label: ParameterTypesNames[props.values.type],
                                value: props.values.type,
                            }}
                            defaultValue={{
                                value: props.values.type,
                                label: props.values.type,
                            }}
                            error={props.errors.type && props.touched.type}
                            onChange={setSelectType}
                            onBlur={props.handleBlur}
                            placeholder="Typ parametru"
                        />
                    </InlineFieldContainer>
                    <WideField
                        name="address"
                        value={props.values.address}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.address && props.touched.address}
                        label="Adres/Nazwa zmiennej"
                        variant="outlined"
                    />
                    <SelectBox>
                        <FormikAsyncSelect
                            name="aggregationMethod"
                            cacheOptions={true}
                            defaultOptions
                            value={props.values.aggregationMethod}
                            loadOptions={fetchCalculationMethods}
                            error={
                                props.errors.aggregationMethod &&
                                props.touched.aggregationMethod
                            }
                            onChange={chooseAggregationMethod}
                            onBlur={props.handleBlur}
                            placeholder="Metoda agregacji"
                        />
                    </SelectBox>
                    <SelectBox>
                        <FormikAsyncSelect
                            name="unitShowing"
                            cacheOptions
                            defaultOptions
                            loadOptions={fetchUnits}
                            value={props.values.unitShowing}
                            error={
                                props.errors.unitShowing &&
                                props.touched.unitShowing
                            }
                            onChange={(option: any) =>
                                props.setFieldValue('unitShowing', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Jednostka wyświetlana"
                        />
                    </SelectBox>
                    <FormikCheckBox
                        name="isAvailableForChart"
                        checked={props.values.isAvailableForChart}
                        onChange={() =>
                            props.setFieldValue(
                                'isAvailableForChart',
                                !props.values.isAvailableForChart,
                            )
                        }
                        label="Dostępność na wykresie"
                    />
                    <WideField
                        name="shortDescription"
                        value={props.values.shortDescription}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        multiline
                        rows={3}
                        error={
                            props.errors.shortDescription &&
                            props.touched.shortDescription
                        }
                        label="Krótki opis (do 19 znaków)"
                        variant="outlined"
                    />
                    <WideField
                        name="fullDescription"
                        value={props.values.fullDescription}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        multiline
                        rows={5}
                        error={
                            props.errors.fullDescription &&
                            props.touched.fullDescription
                        }
                        label="Pełny opis"
                        variant="outlined"
                    />
                </ContentContainer>
                <VerticalDivider />
                <ContentContainer>
                    {props.values.aggregationMethod ? (
                        <Variables
                            configConstants={
                                props.values.aggregationMethod.configConstants
                            }
                            variables={props.values.aggregationMethod.variables}
                            updateConfigData={
                                updateConfigConstantsAtAggregationMethod
                            }
                            updateVariablesData={
                                updateVariablesAtAggregationMethod
                            }
                        />
                    ) : null}
                </ContentContainer>
            </StyledForm>
            <SubmitPanel
                onCancel={props.onCancel}
                submitForm={props.submitForm}
            />
        </FormContainer>
    );
}
