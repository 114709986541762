import styled from 'styled-components';

export const ContentContainer = styled.div`
    width: 100%;
    height: 300px;
    background-color: ghostwhite;
    border: 1px dashed black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;
