import { combineReducers } from 'redux';
import { location } from './location';
import { loginReducer } from '../views/Login/reducer';
import { organizationReducer } from '../views/Manage/reducer';
import { profileReducer } from './profile';
import { userIdByOrganizationId } from '../views/Organization/Users/reducer';
import { objectsReducer } from '../views/Organization/Objects/reducer/objects';
import { parameterReducer } from '../views/Organization/Parameters/reducer/parametersReducer';
import { alarmReducer } from '../views/Organization/Alarms/reducer/alarmReducer';
import { unitReducer } from './units';
import { templatesReducer } from '../views/Organization/Templates/reducer/templates';
import { groupsReducer } from '../views/Organization/Groups/reducer/groups';

const reducer = combineReducers({
    location,
    authorization: loginReducer,
    organizations: organizationReducer,
    profile: profileReducer,
    users: userIdByOrganizationId,
    objects: objectsReducer,
    parameters: parameterReducer,
    alarms: alarmReducer,
    units: unitReducer,
    templates: templatesReducer,
    groups: groupsReducer,
});

export type AppState = ReturnType<typeof reducer>;

export default reducer;
