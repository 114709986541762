import { Alarm } from '../../../../../types/Alarm';
import { AlarmPriorities } from '../../../../../types/AlarmPriorities';
import { AlarmPlatform } from '../../../../../types/AlarmPlatform';
import { generateRandomString } from '../../../../../../../../tools';

export const formInitValues: Alarm = {
    name: '',
    priority: {
        label: AlarmPriorities.Medium,
        value: 2,
    },
    platform: [
        {
            label: AlarmPlatform.InApp,
            value: 1,
        },
    ],
    description: '',
    variableName: undefined,
    value: {
        label: '0',
        value: 0,
    },
    tempId: generateRandomString(),
};
