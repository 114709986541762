// @ts-ignore
import * as Yup from 'yup';

export const BasicDataScheme = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    nip: Yup.number()
        .min(10, 'Too Short!')
        .required('Required'),
    shortName: Yup.string().required('Required'),
    address: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    addressTwo: Yup.string(),
    postCode: Yup.string()
        .min(5, 'Too Short!')
        .max(6)
        .required('Required'),
    town: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    country: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
});
