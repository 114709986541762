import React from 'react';
import { TableCell } from '@material-ui/core';

interface CellProps {
    children: React.ReactNode;
    width?: number | undefined;
}

export function Cell(props: CellProps) {
    if (props.width) {
        return (
            <TableCell
                style={{
                    maxWidth: props.width,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {props.children}
            </TableCell>
        );
    }

    return <TableCell>{props.children}</TableCell>;
}
