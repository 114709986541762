import { Template } from '../types/Template';
import { TemplatesActionTypes, TemplatesUnion } from '../models/actionTypes';

export interface TemplatesState {
    templatesHasFetched: boolean;
    templatesIsFetching: boolean;
    templates: Template[];
    addingTemplateIsPending: boolean;
    templateHasAdded: boolean;
    addingTemplateError: string;
}

const initTemplateState: TemplatesState = {
    templates: [],
    templatesHasFetched: false,
    templatesIsFetching: false,
    addingTemplateIsPending: false,
    templateHasAdded: false,
    addingTemplateError: '',
};

export function templatesReducer(
    state: TemplatesState = initTemplateState,
    action: TemplatesUnion,
): TemplatesState {
    switch (action.type) {
        case TemplatesActionTypes.FetchTemplates: {
            return {
                ...state,
                templatesIsFetching: true,
            };
        }

        case TemplatesActionTypes.FetchTemplatesSuccess: {
            return {
                ...state,
                templatesIsFetching: false,
                templatesHasFetched: true,
                templates: action.templates,
            };
        }

        case TemplatesActionTypes.FetchTemplatesFailed: {
            return {
                ...state,
                templatesHasFetched: false,
                templatesIsFetching: false,
            };
        }

        case TemplatesActionTypes.AddTemplate: {
            return {
                ...state,
                addingTemplateIsPending: true,
                addingTemplateError: '',
                templateHasAdded: false,
            };
        }

        case TemplatesActionTypes.AddTemplateSuccess: {
            return {
                ...state,
                addingTemplateIsPending: false,
                templateHasAdded: true,
            };
        }

        case TemplatesActionTypes.AddTemplateFailed: {
            return {
                ...state,
                addingTemplateError: action.error,
            };
        }

        case TemplatesActionTypes.ResetAddTemplateState: {
            return {
                ...state,
                addingTemplateError: '',
                addingTemplateIsPending: false,
                templateHasAdded: false,
            };
        }

        default:
            return state;
    }
}
