import React from 'react';
import { UsedParameters } from '../../Fields';
import { ContentContainer, ListContainer } from './styled';
import { Typography } from '@material-ui/core';

interface UsedParametersListProps {
    usedParameters: UsedParameters;
}

export function UsedParametersList(props: UsedParametersListProps) {
    return (
        <ContentContainer>
            {props.usedParameters.parameters.map((parameter, index) => (
                <ListContainer key={index}>
                    <div>{parameter.name}</div>
                    <ul>
                        {parameter.steps[parameter.id].map((step, index) => (
                            <li key={index}>{step}</li>
                        ))}
                    </ul>
                </ListContainer>
            ))}
            <Typography color="textSecondary">
                Te parametry należy usunąć manualnie w każdym kroku
            </Typography>
        </ContentContainer>
    );
}
