import { SelectOptions } from '../../../../../../../../../types';

export const offerDuration: SelectOptions<number>[] = [
    {
        label: '6 m-c',
        value: 6,
    },
    {
        label: '12 m-c',
        value: 12,
    },
    {
        label: '18 m-c',
        value: 18,
    },
    {
        label: '24 m-c',
        value: 24,
    },
    {
        label: '36 m-c',
        value: 36,
    },
    {
        label: '48 m-c',
        value: 48,
    },
    {
        label: '72 m-c',
        value: 72,
    },
];
