import React from 'react';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { StatusAppearance, ParametersAppearance } from './components';
import { BottomNavBar } from '../../../../../components/ProgressStepper/components';

interface AppearancesProps {
    prevStep: () => void;
    nextStep: () => void;
}

export function Appearances(props: AppearancesProps) {
    return (
        <>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Zakładka - Status</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <StatusAppearance />
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Zakładka - Parametry</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ParametersAppearance />
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <BottomNavBar
                prevStep={props.prevStep}
                showBack={true}
                nextStep={props.nextStep}
                buttonNextText="Dalej"
            />
        </>
    );
}
