import React from 'react';

import { FunctionComponentProps } from '../../types';
import { NavSearchContent } from './components/NavSearchContent/NavSearchContent';
import { Search, GroupWork } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ReactComponent as Logo } from './assets/bumerang-sygnet.svg';
import { Link } from 'react-router-dom';
import { SideMenuContainer } from './styled';
import { MenuItem, NavSection } from './components';
import { useDispatch } from 'react-redux';
import { logOut } from '../../views/Login/actions/login';
import { useGlobalAdmin } from '../../hooks/useGlobalAdmin';

interface SideNavMenuProps extends FunctionComponentProps {
    openDrawerHandle: (element: React.ReactNode) => void;
    closeDrawerHandle: () => void;
}

export function SideNavMenu(props: SideNavMenuProps) {
    const dispatch = useDispatch();
    const { isGlobalAdmin, orgId } = useGlobalAdmin();
    const logoLink = isGlobalAdmin ? '/' : `/organization/${orgId}/details`;

    function searchClickHandle() {
        props.openDrawerHandle(
            <NavSearchContent closeDrawerHandle={props.closeDrawerHandle} />,
        );
    }

    function logOutHandle() {
        dispatch(logOut());
    }

    return (
        <SideMenuContainer>
            <NavSection>
                <Link to={logoLink}>
                    <MenuItem icon={<Logo />} />
                </Link>
                {isGlobalAdmin ? (
                    <Link to="/manage">
                        <MenuItem icon={<GroupWork />} />
                    </Link>
                ) : null}
                {isGlobalAdmin ? (
                    <MenuItem
                        clickHandle={searchClickHandle}
                        icon={<Search />}
                    />
                ) : null}
            </NavSection>
            <NavSection>
                <MenuItem clickHandle={logOutHandle} icon={<ExitToAppIcon />} />
            </NavSection>
        </SideMenuContainer>
    );
}
