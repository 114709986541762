import React from 'react';
import styled, { css } from 'styled-components';
import { TextColors } from '../../types';

interface HeadingProps {
    children: React.ReactNode;
    color?: TextColors;
}

export function Heading(props: HeadingProps) {
    return <StyledHeading color={props.color}>{props.children}</StyledHeading>;
}

type StyledHeadingProps = Omit<HeadingProps, 'children'>;

const StyledHeading = styled.h1<StyledHeadingProps>`
    ${props => getHeadingColor(props.color || 'white')};
`;

function getHeadingColor(color: TextColors): any {
    switch (color) {
        case 'black': {
            return css`
                color: ${props => props.theme.text.colors.black};
            `;
        }

        case 'white': {
            return css`
                color: ${props => props.theme.text.colors.white};
            `;
        }

        default:
            return getHeadingColor('white');
    }
}
