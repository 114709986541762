import styled from 'styled-components';

export const ModalContentContainer = styled.div`
	width: calc(100% - 100px);
	height: calc(100% - 100px);
	position: relative;
	margin: 50px;
	background-color: ${({ theme }) => theme.colors.white}
	
	& .leaflet-container {
		height: 100%;
	}
`;
