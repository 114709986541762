import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Router, Route, Switch } from 'react-router-dom';

import { customerRouting } from './constants/routes';
import { AppWithMenu, OrganizationMenu } from './components';
import { theme } from '../../config/theme';
import { store } from '../../store/store';
import { Login } from '../../views/Login';
import history from './tools/history';
import { AppLayout } from './styled';
import { organizationRouting } from './constants/organizationRouters';

export const App: React.FC = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Router history={history}>
                    <AppLayout>
                        <Switch>
                            <Route path="/login" exact component={Login} />
                            // @ts-ignore
                            <AppWithMenu>
                                <Switch>
                                    {customerRouting.map(route => (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    ))}
                                    <OrganizationMenu>
                                        {organizationRouting.map(route => (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                exact={route.exact}
                                                component={route.component}
                                            />
                                        ))}
                                    </OrganizationMenu>
                                </Switch>
                            </AppWithMenu>
                        </Switch>
                    </AppLayout>
                </Router>
            </ThemeProvider>
        </Provider>
    );
};
