import React from 'react';
import { Formik, FormikProps } from 'formik';

import { useOrganizationFormState } from '../../../hooks/useOrganizationFormState';
import { LicenseDataScheme } from './constants';
import { FormValues } from './types';
import { Fields } from './Fields';

interface FormProps {
    nextStepHandle: () => void;
    prevStepHandle: () => void;
}

export function Form(props: FormProps) {
    const [state, setData] = useOrganizationFormState();

    const onSubmit = (formValue: FormValues) => {
        props.nextStepHandle();
        setData({ license: formValue });
    };

    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={LicenseDataScheme}
            enableReinitialize={true}
            initialValues={{
                userCounts: state.license.userCounts,
                dateFrom: state.license.dateFrom,
                dateTo: state.license.dateTo,
                licenseDuration: state.license.licenseDuration,
                objectsCounts: state.license.objectsCounts,
                modules: state.license.modules,
                licenseType: state.license.licenseType,
                smsPackage: state.license.smsPackage,
            }}
        >
            {(otherProps: FormikProps<FormValues>) => (
                <Fields prevStep={props.prevStepHandle} {...otherProps} />
            )}
        </Formik>
    );
}
