import { Parameter, ParameterType } from '../types/ParameterTypes';

export enum ParametersActionTypes {
    FetchParameters = '[Parameters] fetch parameters',
    FetchParametersSuccess = '[Parameters] fetch parameters success',
    FetchParametersFailed = '[Parameters] fetch parameters failed',

    AddParameter = '[Parameters] add parameter',
    AddParameterSuccess = '[Parameters] add parameter success',

    UpdateForm = '[Parameters] update form',
    ResetForm = '[Parameters] reset form',

    SetParameterType = '[Parameters] set parameter type',
}

interface FetchParameters {
    type: typeof ParametersActionTypes.FetchParameters;
}

interface SetParameterType {
    type: typeof ParametersActionTypes.SetParameterType;
    parameterType: ParameterType;
}

interface FetchParametersSuccess {
    type: typeof ParametersActionTypes.FetchParametersSuccess;
    parameters: Parameter[];
}

interface FetchParametersFailed {
    type: typeof ParametersActionTypes.FetchParametersFailed;
}

interface AddParameter {
    type: typeof ParametersActionTypes.AddParameter;
    data: Parameter;
}

interface AddParameterSuccess {
    type: typeof ParametersActionTypes.AddParameterSuccess;
}

interface UpdateForm {
    type: typeof ParametersActionTypes.UpdateForm;
    data: Parameter;
}

interface ResetForm {
    type: typeof ParametersActionTypes.ResetForm;
    data: Parameter;
}

export type ParametersUnion =
    | FetchParameters
    | FetchParametersSuccess
    | FetchParametersFailed
    | SetParameterType
    | AddParameter
    | AddParameterSuccess
    | UpdateForm
    | ResetForm;
