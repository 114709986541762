import { ParameterTypes } from '../types/ParameterTypes';
import { formInitValues as formInitTimeValues } from '../components/TopPanel/forms/Time/constants/formInitValues';
import { formInitValues as formInitOrderValues } from '../components/TopPanel/forms/Order/constants/formInitValues';
import { formInitValues as formInitBinaryValues } from '../components/TopPanel/forms/Binary/constants/formInitValues';
import { formInitValues as formInitCalculatedValues } from '../components/TopPanel/forms/Calculated/constants/formInitValues';
import { formInitValues as formInitFigureValues } from '../components/TopPanel/forms/Figure/constants/formInitValues';

export function getInitDataByType(type: ParameterTypes) {
    switch (type) {
        case ParameterTypes.Binary:
            return formInitBinaryValues;
        case ParameterTypes.Calculated:
            return formInitCalculatedValues;
        case ParameterTypes.Figure:
            return formInitFigureValues;
        case ParameterTypes.Order:
            return formInitOrderValues;
        case ParameterTypes.Time:
            return formInitTimeValues;
        default:
            return formInitFigureValues;
    }
}
