import { AppState } from '../reducer';
import { Profile } from '../types';

function getProfileData(appState: AppState): Profile {
    return appState.profile;
}

export function getProfileName(appState: AppState): string {
    return getProfileData(appState).name;
}

export function getProfileSurname(appState: AppState): string {
    return getProfileData(appState).surname;
}

export function getProfileType(appState: AppState): number {
    return getProfileData(appState).userType;
}

export function getProfileOrganizationId(appState: AppState): number {
    return getProfileData(appState).organizationId;
}

export function getProfileBlockedState(appState: AppState): boolean {
    return getProfileData(appState).isBlocked;
}

export function getProfileEmail(appState: AppState): string {
    return getProfileData(appState).email;
}

export function isGlobalAdminSelector(appState: AppState): boolean {
    return getProfileData(appState).isGlobalAdmin;
}
