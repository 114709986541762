import React from 'react';
import {
    Alarms,
    Appearances,
    Settings,
    Variables,
    EngineSymbol,
    MapParameters,
    SummaryAddTemplate,
} from '../components';

export const addTemplateSteps = [
    {
        title: 'Ustawienia',
        // @ts-ignore
        component: <Settings />,
    },
    {
        title: 'Konfiguracja zmiennych',
        // @ts-ignore
        component: <Variables />,
    },
    {
        title: 'Lista alarmów',
        // @ts-ignore
        component: <Alarms />,
    },
    {
        title: 'Symbol zębatki',
        // @ts-ignore
        component: <EngineSymbol />,
    },
    {
        title: 'Parametry obiektu na mapie',
        // @ts-ignore
        component: <MapParameters />,
    },
    {
        title: 'Wygląd interfejsu',
        // @ts-ignore
        component: <Appearances />,
    },
    {
        title: 'Podsumowanie',
        // @ts-ignore
        component: <SummaryAddTemplate />,
    },
];
