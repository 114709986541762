export enum AlarmPriorities {
    High = 'wysoki',
    Medium = 'średni',
    Low = 'niski',
}

export type AlarmPrioritiesType =
    | AlarmPriorities.High
    | AlarmPriorities.Medium
    | AlarmPriorities.Low;
