import React from 'react';
import { FormikProps } from 'formik';

import { BottomNavBar } from '../../../../../../../components/ProgressStepper/components';
import {
    FormikAsyncSelect,
    FormikCheckBox,
} from '../../../../../../../tools/FormikFields';
import { VerticalDivider } from '../../../../../formStyled';
import { WideField, SelectBox, Flex } from '../../../../../../../styled/';
import { ObjectType } from '../../../../../../../types';
import { BasicData } from '../../../../../Objects/types';
import { DividerContainer } from './styled';
import { Template } from '../../../../../Objects/types/Template';
import { getTemplatesAsyncSelect } from '../../../../actions/templates';
interface FieldsProps extends FormikProps<BasicData> {
    setTemplate: (data: string) => void;
    isObjectEdit: boolean;
}

export function Fields(props: FieldsProps) {
    function setTemplate(option: Template) {
        props.setTemplate(option.metadata as string);
        props.setFieldValue('template', option);
    }
    return (
        <form onSubmit={props.handleSubmit}>
            <WideField
                name="name"
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.name && props.touched.name}
                label="Nazwa"
                variant="outlined"
            />
            <WideField
                name="shortName"
                value={props.values.shortName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.shortName && props.touched.shortName}
                label="Oznaczenie"
                variant="outlined"
            />
            <WideField
                name="cameraUrl"
                value={props.values.cameraUrl}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                label="Link do kamery"
                variant="outlined"
            />
            <SelectBox>
                <FormikAsyncSelect
                    name="template"
                    isDisabled={props.isObjectEdit}
                    cacheOptions
                    loadOptions={getTemplatesAsyncSelect}
                    getOptionLabel={(opt: ObjectType) => opt.name}
                    getOptionValue={(opt: ObjectType) => opt.id}
                    defaultOptions
                    value={props.values.template}
                    error={props.errors.template && props.touched.template}
                    onChange={setTemplate}
                    onBlur={props.handleBlur}
                    placeholder="Szablon"
                />
            </SelectBox>
            <Flex>
                <WideField
                    name="ipAddress"
                    disabled={props.isObjectEdit}
                    value={props.values.ipAddress}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.ipAddress && props.touched.ipAddress}
                    label="IP"
                    variant="outlined"
                />
                <DividerContainer>-</DividerContainer>
                <WideField
                    name="ipAddressFix"
                    disabled={props.isObjectEdit}
                    value={props.values.ipAddressFix}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                        !props.isObjectEdit &&
                        props.errors.ipAddressFix &&
                        props.touched.ipAddressFix
                    }
                    label="Identyfikator"
                    variant="outlined"
                />
            </Flex>
            <Flex>
                <WideField
                    name="registerAddressOffset"
                    disabled={props.isObjectEdit}
                    value={props.values.registerAddressOffset}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                        !props.isObjectEdit &&
                        props.errors.registerAddressOffset &&
                        props.touched.registerAddressOffset
                    }
                    label="Offset"
                    variant="outlined"
                />
                {/* <VerticalDivider /> */}
            </Flex>
            <FormikCheckBox
                name="useExistingTable"
                checked={props.values.useExistingTable}
                onChange={() =>
                    props.setFieldValue(
                        'useExistingTable',
                        !props.values.useExistingTable,
                    )
                }
                label="Wykorzystaj istniejącą tabelę"
            />
            <BottomNavBar showBack={false} buttonNextText="Dalej" />
        </form>
    );
}
