import React from 'react';
import { Heading } from '../../../../../../../../../components/DataGrid/types';
import { DataGrid } from '../../../../../../../../../components';
import { Input } from '../Input';
import { FormikProps } from 'formik';
import { FormValues } from './type';

const headings: Heading[] = [
    {
        key: 'name',
        heading: 'NAZWA USŁUGI',
    },
    {
        key: 'price',
        heading: 'CENA CENNIKOWA',
    },
    {
        key: 'count',
        heading: 'LICZBA PAKIETÓW',
    },
    {
        key: 'summary',
        heading: 'SUMA MIESIĘCZNIE',
    },
];

interface Data {
    name: string;
    price: React.ReactNode;
    count: React.ReactNode;
    summary: React.ReactNode;
}

export function Grid(props: FormikProps<FormValues>) {
    const onBlurHandle = async () => {
        await props.setFieldValue(
            'serviceSummary',
            props.values.servicePrice * props.values.serviceCount,
        );
        props.submitForm();
    };

    const data: Data[] = [
        {
            name: 'Pakiet SMS',
            count: (
                <Input
                    name="serviceCount"
                    type="number"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values.serviceCount}
                />
            ),
            price: (
                <Input
                    name="servicePrice"
                    type="number"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values.servicePrice}
                />
            ),
            summary: (
                <Input
                    name="serviceSummary"
                    disabled
                    onChange={props.handleChange}
                    value={props.values.serviceSummary}
                />
            ),
        },
    ];

    return (
        <form onSubmit={props.handleSubmit}>
            <DataGrid
                headings={headings}
                keys={['name', 'price', 'count', 'summary']}
                data={data}
            />
        </form>
    );
}
