import React from 'react';
import { Step } from '../../../../components/ProgressStepper';
import { AddEditGroup } from '../components/AddEditGroup';

export const addGroupSteps: Step[] = [
    {
        title: 'Tworzenie',
        // @ts-ignore
        component: <AddEditGroup />,
    },
];
