import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useObjectForm } from '../../../../../Objects/hooks';
import { StepProps } from '../../../../types/StepProps';
import { Fields } from './Fields';
import { MapParameter } from '../../../../../Objects/types/MapParameter';
import { validateRow } from '../../../../../../../tools/validateFields';

interface FormProps extends StepProps {}

export function Form(props: FormProps) {
    const [state, setData] = useObjectForm();
    const [errors, setErrors] = useState<string[]>([]);

    const onSubmitHandle = (values: MapParameter[]) => {
        const errors = validateRow(values, ['parameter']);
        if (errors.length) {
            setErrors(errors);
            return;
        }
        setData({ ...state, mapParameters: values });
        props.nextStep();
    };

    return (
        <Formik<MapParameter[]>
            enableReinitialize={true}
            initialValues={state.mapParameters}
            onSubmit={onSubmitHandle}
            render={(otherProps: FormikProps<MapParameter[]>) => (
                <Fields
                    {...otherProps}
                    validationErrors={errors}
                    prevStep={props.prevStep}
                    selectOptions={state.parameters}
                />
            )}
        />
    );
}
