import React, { useState } from 'react';
import { Form } from './components';
import { useObjectForm } from '../../../Objects/hooks';
import { generateRandomString } from '../../../../../tools';
import { PhotoGalleryForm } from '../../../Objects/types/PhotoGalleryForm';

interface PhotoGalleryProps {
    prevStep: () => void;
    nextStep: () => void;
}

export function PhotoGallery(props: PhotoGalleryProps) {
    const [formData, updateForm] = useObjectForm();
    const [selectedGalleries, setSelectedGallery] = useState<string[]>([]);

    function checkGallery(galleryName: string) {
        if (selectedGalleries.includes(galleryName)) {
            setSelectedGallery([
                ...selectedGalleries.filter(gallery => gallery !== galleryName),
            ]);
            return;
        }

        setSelectedGallery([...selectedGalleries, galleryName]);
    }

    function addNewGallery(formData: PhotoGalleryForm[]) {
        updateForm({
            photoGalleries: [
                ...formData,
                {
                    tempId: generateRandomString(),
                    galleryName: '',
                    galleryPhotos: [],
                },
            ],
        });
    }

    function removeGalleries() {
        updateForm({
            ...formData,
            photoGalleries: formData.photoGalleries.filter(
                gallery => !selectedGalleries.includes(gallery.tempId),
            ),
        });
        setSelectedGallery([]);
    }

    return (
        <Form
            addNewGallery={addNewGallery}
            removeGalleries={removeGalleries}
            selectedGalleries={selectedGalleries}
            checkGallery={checkGallery}
            nextStep={props.nextStep}
            prevStep={props.prevStep}
            galleries={formData.photoGalleries}
        />
    );
}
