import React from 'react';
import { Form } from './Form/Form';

interface BasicDataProps {
    nextStep: () => void;
}

export function BasicData(props: BasicDataProps) {
    return (
        <div>
            <Form nextStepHandle={props.nextStep} />
        </div>
    );
}
