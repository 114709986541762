import React, { useEffect } from 'react';
import { FormikSelect } from '../../tools/FormikFields';
import { FormikProps } from 'formik';
import { SelectBox } from './styled';
import { County, Community, Voivodeship } from '../../types';
import { Fields } from './constants/fields';
import { useCommunities, useCounties, useVoivodeships } from './hooks';

export function LocalizationFields(props: FormikProps<any>) {
    const [
        communitiesFetching,
        ,
        allCommunities,
        getCommunities,
    ] = useCommunities();

    const [
        voivodeshipsFetching,
        voivodeshipsFetched,
        allVoivodeships,
        getVoivodeships,
    ] = useVoivodeships();

    const [countiesFetching, , allCounties, geCounties] = useCounties();

    useEffect(() => {
        if (
            props.values[Fields.Voivodeship] &&
            props.values[Fields.Community] &&
            !allCounties.length &&
            !allCommunities.length
        ) {
            geCounties(props.values[Fields.Voivodeship]);
        }

        if (
            props.values[Fields.Community] &&
            props.values[Fields.County] &&
            !allCounties.length &&
            !allCommunities.length
        ) {
            getCommunities(props.values[Fields.County]);
        }
    }, [
        allVoivodeships,
        allCommunities,
        allCounties,
        props.values,
        geCounties,
        getCommunities,
    ]);

    async function getCountyHandle(option: County) {
        props.setFieldValue(Fields.Voivodeship, option.id);
        props.setFieldValue(Fields.County, '');
        geCounties(option.id);
    }

    async function getCommunityHandle(option: Community) {
        props.setFieldValue(Fields.County, option.id);
        props.setFieldValue(Fields.Community, '');
        getCommunities(option.id);
    }

    function countyOnFocus() {
        if (props.values[Fields.County]) {
            getCommunities(props.values[Fields.Voivodeship].id);
        }
    }

    function communityOnFocus() {
        if (props.values[Fields.Community]) {
            getCommunities(props.values[Fields.County].id);
        }
    }

    if (!voivodeshipsFetching && !voivodeshipsFetched) {
        getVoivodeships();
    }

    return (
        <>
            <SelectBox>
                <FormikSelect
                    name={Fields.Voivodeship}
                    cacheOptions
                    options={allVoivodeships}
                    getOptionLabel={(opt: Voivodeship) => opt.label}
                    getOptionValue={(opt: Voivodeship) => opt.id}
                    defaultOptions
                    value={
                        props.values[Fields.Voivodeship]
                            ? allVoivodeships.find(
                                  voivodeship =>
                                      voivodeship.id ===
                                      props.values[Fields.Voivodeship],
                              )
                            : null
                    }
                    onChange={getCountyHandle}
                    error={
                        props.errors.voivodeship && props.touched.voivodeship
                    }
                    onBlur={props.handleBlur}
                    placeholder="Województwo"
                />
            </SelectBox>
            <SelectBox>
                <FormikSelect
                    name={Fields.County}
                    isDisabled={
                        (!allCounties.length || countiesFetching) &&
                        !props.values[Fields.County]
                    }
                    options={allCounties}
                    value={
                        props.values[Fields.County]
                            ? allCounties.find(
                                  (foundCounty: County) =>
                                      foundCounty.id ===
                                      props.values[Fields.County],
                              )
                            : null
                    }
                    isLoading={countiesFetching}
                    getOptionLabel={(opt: County) => opt.label}
                    getOptionValue={(opt: County) => opt.id}
                    defaultValue={props.values[Fields.County]}
                    onChange={getCommunityHandle}
                    onFocus={countyOnFocus}
                    error={props.errors.county && props.touched.county}
                    onBlur={props.handleBlur}
                    placeholder="Powiat"
                />
            </SelectBox>
            <SelectBox>
                <FormikSelect
                    name={Fields.Community}
                    isDisabled={
                        (!allCommunities.length || communitiesFetching) &&
                        !props.values[Fields.Community]
                    }
                    options={allCommunities}
                    value={
                        props.values.community && props.values.county
                            ? allCommunities.find(
                                  (foundCommunity: Community) =>
                                      foundCommunity.id ===
                                      props.values.community,
                              )
                            : null
                    }
                    isLoading={communitiesFetching}
                    getOptionLabel={(opt: Community) => opt.label}
                    getOptionValue={(opt: Community) => opt.id}
                    onChange={(option: any) =>
                        props.setFieldValue(Fields.Community, option.id)
                    }
                    defaultValue={props.values[Fields.Community]}
                    onBlur={props.handleBlur}
                    onFocus={communityOnFocus}
                    error={props.errors.community && props.touched.community}
                    placeholder="Gmina"
                />
            </SelectBox>
        </>
    );
}
