import React from 'react';
import { Formik, FormikProps } from 'formik';
import { Fields } from './Fields';
import { UserPermission } from '../../../../../../types/ObjectsWithPermissions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormProps } from './types';
import { useUserData } from '../../../../../../hooks/useUserData';

interface ComponentProps
    extends RouteComponentProps<{ id: string }>,
        FormProps {}

function Component(props: ComponentProps) {
    const { id } = props.match.params;
    const { updateAddUserForm, userFormData } = useUserData(Number(id));

    function submitHandle(value: UserPermission) {
        updateAddUserForm({ ...userFormData, permission: value });
        props.nextStep();
    }

    return (
        <Formik
            onSubmit={submitHandle}
            initialValues={userFormData.permission}
            render={(fieldsProps: FormikProps<UserPermission>) => (
                <Fields
                    {...fieldsProps}
                    prevStep={props.prevStep}
                    nextStep={props.nextStep}
                    organizationId={id}
                />
            )}
        />
    );
}

export const Form = withRouter(Component);
