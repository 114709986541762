import { AppState } from '../../../../reducer';
import { ParametersState } from '../reducer/parametersReducer';
import { Parameter } from '../types/ParameterTypes';

function getParametersState(state: AppState): ParametersState {
    return state.parameters;
}

export function getFormData(state: AppState): Partial<Parameter> {
    return getParametersState(state).formData;
}
