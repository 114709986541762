import React, { useState } from 'react';
import { FormikProps } from 'formik';
import {
    EngineSymbol,
    RowParameter,
} from '../../../../../Objects/types/EngineSymbol';
import { Row } from './components';
import { BottomPanel } from '../../../BottomPanel';
import { BinaryParameter } from '../../../../../Parameters/types/BinaryParameter';
import { generateRandomString } from '../../../../../../../tools';
import { BottomNavBar } from '../../../../../../../components/ProgressStepper/components';
import { getTempIdOrId } from '../../../tools/getTempIdOrId';
import { FormikAsyncSelect } from '../../../../../../../tools/FormikFields';
import { OptionWithIcons } from './components/OptionWithIcons';
import { SelectBox } from '../../../Variables/components/Form/component/Row/styled';
import { useFetchAsyncSelectData } from '../../../../../Parameters/hooks/useFetchAsyncSelectData';
import { HorizontalDivider } from '../../../Settings/components/Form/styled';

interface FieldsProps extends FormikProps<EngineSymbol> {
    selectOptions: BinaryParameter[];
    prevStep: () => void;
    validationErrors: string[];
}

export function Fields(props: FieldsProps) {
    const { fetchObjectIcons, fetchServiceGroups } = useFetchAsyncSelectData();
    const [selectedRows, setRow] = useState<string[]>([]);
    function addNewRow() {
        props.setValues({
            ...props.values,
            parameters: [
                ...props.values.parameters,
                {
                    id: generateRandomString(),
                    parameter: undefined,
                    value: 0,
                },
            ],
        });
    }

    function removeRows() {
        props.setValues({
            ...props.values,
            parameters: props.values.parameters.filter(
                item => !selectedRows.includes(item.id),
            ),
        });
        setRow([]);
    }

    function selectRow(id: string) {
        setRow([...selectedRows, id]);
    }

    function setParameter(item: RowParameter, parameter: BinaryParameter) {
        props.setValues({
            ...props.values,
            parameters: props.values.parameters.map(value => {
                if (value.id === item.id) {
                    return {
                        ...value,
                        parameter,
                    };
                }
                return value;
            }),
        });
    }

    function setSymbol(symbolSelect: any) {
        props.setValues({
            ...props.values,
            symbol: symbolSelect,
        });
    }

    function setServiceGroup(symbolSelect: any) {
        props.setValues({
            ...props.values,
            serviceCategory: symbolSelect,
        });
    }

    function setValue(item: RowParameter, rowValue: number) {
        props.setValues({
            ...props.values,
            parameters: props.values.parameters.map(value => {
                if (value.id === item.id) {
                    return {
                        ...value,
                        value: rowValue,
                    };
                }
                return value;
            }),
        });
    }

    function renderRows() {
        return props.values.parameters.map(item => (
            <Row
                setValue={setValue}
                selectRow={selectRow}
                item={item}
                hasError={props.validationErrors.includes(item.id)}
                setParameter={setParameter}
                isChecked={selectedRows.includes(item.id)}
                key={item.id}
                options={props.selectOptions.filter(
                    option =>
                        !props.values.parameters
                            .filter(param => param.parameter)
                            .map(param => getTempIdOrId(param.parameter))
                            .includes(getTempIdOrId(option)),
                )}
            />
        ));
    }

    return (
        <form onSubmit={props.handleSubmit}>
            {renderRows()}
            <BottomPanel<null>
                actionHandleArg={null}
                primaryButtonActionHandle={addNewRow}
                primaryButtonText="Dodaj"
                secondButtonActionHandle={removeRows}
                secondButtonText="Usuń"
            />
            <HorizontalDivider />
            <SelectBox>
                <FormikAsyncSelect
                    name="symbol"
                    cacheOptions
                    loadOptions={fetchObjectIcons}
                    defaultOptions
                    error={props.errors.symbol && props.touched.symbol}
                    getOptionLabel={(opt: any) => opt.icon}
                    components={{ Option: OptionWithIcons }}
                    value={props.values.symbol}
                    onChange={setSymbol}
                    placeholder="Symbol"
                />
            </SelectBox>
            <SelectBox>
                <FormikAsyncSelect
                    name="serviceCategory"
                    cacheOptions
                    loadOptions={fetchServiceGroups}
                    defaultOptions
                    error={
                        props.errors.serviceCategory &&
                        props.touched.serviceCategory
                    }
                    onChange={setServiceGroup}
                    value={props.values.serviceCategory}
                    placeholder="Kategoria serwisowa"
                />
            </SelectBox>
            <BottomNavBar prevStep={props.prevStep} buttonNextText="Dalej" />
        </form>
    );
}
