import React from 'react';
import { Form } from './Form/Form';
import { ContentContainer } from './styled';

interface StepTwoProps {
    nextStep: () => void;
    prevStep: () => void;
}

export function StepTwo(props: StepTwoProps) {
    return (
        <ContentContainer>
            <Form nextStep={props.nextStep} prevStep={props.prevStep} />
        </ContentContainer>
    );
}
