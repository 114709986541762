import React, { useState } from 'react';
import { Stepper } from './components';
import { Step } from './types';

interface ProgressStepperProps {
    steps: Step[];
    closeDrawer: () => void;
    isObjectEdit?: boolean;
    isAddTemplate?: boolean;
}

export function ProgressStepper(props: ProgressStepperProps) {
    const [currentStep, setCurrentStep] = useState(0);

    const nextStep = () => setCurrentStep(currentStep + 1);
    const prevStep = () => setCurrentStep(currentStep - 1);

    return (
        <Stepper
            closeDrawer={props.closeDrawer}
            nextStep={nextStep}
            prevStep={prevStep}
            activeStep={currentStep}
            steps={props.steps}
            isObjectEdit={props.isObjectEdit}
            isAddTemplate={props.isAddTemplate}
        />
    );
}
