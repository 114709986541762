import React from 'react';
import { DataGrid } from '../../../../../../../components/DataGrid';
import {
    Parameter,
    ParameterTypesNames,
} from '../../../../../Parameters/types/ParameterTypes';
import { headings, headingsKey } from './constants/headings';
import { CELL_WIDTH } from '../../constants/cellWidth';

interface ParametersProps {
    parameter: Parameter[];
}

export function Parameters(props: ParametersProps) {
    return (
        <>
            <h2>Parametry</h2>
            <DataGrid<Parameter>
                cellWidth={CELL_WIDTH}
                headings={headings}
                keys={headingsKey}
                data={props.parameter.map(value => ({
                    ...value,
                    type: ParameterTypesNames[value.type] as any,
                    name: value.name?.label || ('' as any),
                }))}
            />
        </>
    );
}
