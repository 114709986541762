import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducer';
import {
    voivodeships,
    voivodeshipsHasFetched,
    voivodeshipsIsFetching,
} from '../../../selectors/location';
import { Voivodeship } from '../../../types';
import { getVoivodeship } from '../../../actions/location';

type VoivodeshipOutput = [boolean, boolean, Voivodeship[], () => void];

export function useVoivodeships(): VoivodeshipOutput {
    const dispatch = useDispatch();
    const voivodeshipsFetching = useSelector((appState: AppState) =>
        voivodeshipsIsFetching(appState),
    );

    const voivodeshipsFetched = useSelector((appState: AppState) =>
        voivodeshipsHasFetched(appState),
    );

    const allVoivodeships = useSelector((appState: AppState) =>
        voivodeships(appState),
    );

    function getVoivodeships() {
        dispatch(getVoivodeship());
    }

    return [
        voivodeshipsFetching,
        voivodeshipsFetched,
        allVoivodeships,
        getVoivodeships,
    ];
}
