import React from 'react';
import { PointCircle, PointContainer, TextContainer } from './styled';

interface PointProps {
    title: string;
    isLast: boolean;
    isActive: boolean;
    stepNumber: number;
    isApprove: boolean;
}

export function Point(props: PointProps) {
    return (
        <PointContainer isLast={props.isLast}>
            <PointCircle isApprove={props.isApprove} isActive={props.isActive}>
                {props.isApprove ? '' : props.stepNumber}
            </PointCircle>
            <TextContainer
                isApprove={props.isApprove}
                isActive={props.isActive}
            >
                {props.title}
            </TextContainer>
        </PointContainer>
    );
}
