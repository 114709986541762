import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';

import { LocalizationDataScheme } from './constants/validation';
import { initFormValues } from './constants/initFormValues';
import { Localization } from '../../../../../Objects/types';
import { Fields } from './Fields';
import { useObjectForm } from '../../../../../Objects/hooks';

interface FormProps {
    prevStep: () => void;
    nextStep: () => void;
}

export function Form(props: FormProps) {
    const [state, setFormData] = useObjectForm();

    const onSubmitHandle = async (values: Localization) => {
        setFormData({ ...state, localization: values });
        props.nextStep();
    };

    const [modalIsOpen, setModalState] = useState(false);

    const openModalHandle = () => setModalState(true);
    const closeModalHandle = () => setModalState(false);

    return (
        <Formik<Localization>
            validationSchema={LocalizationDataScheme}
            onSubmit={onSubmitHandle}
            enableReinitialize={true}
            initialValues={{ ...initFormValues, ...state.localization }}
            render={(otherProps: FormikProps<Localization>) => (
                <Fields
                    {...otherProps}
                    closeModalHandle={closeModalHandle}
                    modalIsOpen={modalIsOpen}
                    openMap={openModalHandle}
                    prevStep={props.prevStep}
                />
            )}
        />
    );
}
