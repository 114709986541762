// @ts-ignore
import * as Yup from 'yup';

export const LocalizationDataScheme = Yup.object().shape({
    town: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    postCode: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    address: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    county: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    community: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    voivodeship: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    latitude: Yup.number()
        .moreThan(0)
        .required(),
    longitude: Yup.number()
        .moreThan(0)
        .required(),
});
