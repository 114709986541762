import styled from 'styled-components';
import { TableCell } from '@material-ui/core';

export const CellMenu = styled(TableCell)`
    pointer-events: none;
    && {
        text-align: end;
    }

    span.MuiIconButton-label {
        pointer-events: none !important;
    }
`;
