import { AppState } from '../reducer';

function unitsState(state: AppState) {
    return state.units;
}

export function units(state: AppState) {
    return unitsState(state).units;
}

export function unitsIsFetching(state: AppState) {
    return unitsState(state).unitsIsFetching;
}

export function unitsHasFetched(state: AppState) {
    return unitsState(state).unitsHasFetched;
}

