import { useState } from 'react';

interface UseDrawerVisibleOutput {
    drawerState: boolean;
    openDrawer: () => void;
    closeDrawer: () => void;
}

export function useDrawerVisible(): UseDrawerVisibleOutput {
    const [drawerState, setDrawerVisible] = useState(false);

    function openDrawer() {
        setDrawerVisible(true);
    }

    function closeDrawer() {
        setDrawerVisible(false);
    }

    return {
        drawerState,
        openDrawer,
        closeDrawer,
    };
}
