import React from 'react';
import { useDnd } from '../../../../../../../hooks';
import { DragDropContext } from 'react-beautiful-dnd';
import { AppearanceBase } from '../../../../../Objects/types/AppearanceBase';

interface DndAppearanceProps {
    editBoxHandle: (boxId: string) => void;
    config: AppearanceBase;
    updateConfig: (config: AppearanceBase) => void;
}

export function DndAppearance({
    editBoxHandle,
    config,
    updateConfig,
}: DndAppearanceProps) {
    const { onDragEnd, renderColumns } = useDnd(
        config,
        updateConfig,
        editBoxHandle,
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {renderColumns()}
        </DragDropContext>
    );
}
