import { useDispatch, useSelector } from 'react-redux';
import { getFormData } from '../selectors/parameters';
import { AppState } from '../../../../reducer';
import { Parameter } from '../types/ParameterTypes';
import {
    resetParametersForm,
    updateParametersForm,
} from '../actions/parameters';

type UseParameterFormOutPut = [
    Parameter | {},
    (data: Parameter) => void,
    () => void,
];

export function useParameterForm(): UseParameterFormOutPut {
    const dispatch = useDispatch();
    const formData = useSelector((state: AppState) => getFormData(state));

    function updateFormData(data: Parameter) {
        dispatch(updateParametersForm(data));
    }

    function resetFormData() {
        dispatch(resetParametersForm());
    }

    return [formData, updateFormData, resetFormData];
}
