import { request } from '../../../../tools';
import {
    parameterCalculationMethods,
    parameterConfigurationNumerical,
    parameterConfigurationTextual,
    parameterFormatNumber,
    parameterFormatTime,
    parameterPresentationBinary,
    parameterPresentationCalculated,
    parameterReadWrite,
    parameterUnits,
    parameterVariables,
    alertPriorities,
    alertChannels,
    appearanceBoxTypes,
    parameterUnitsByTypeId,
    appearanceParameterSchemas,
    OrganizationObjects,
    objectIcons,
    serviceGroups,
    userTypes,
} from '../../../../api/api';
import { SelectOption } from '../../../../types/SelectOption';
import { useObjectForm } from '../../Objects/hooks';
import { MachineObject } from '../../Objects/types';

interface UseFetchAsyncSelectData {
    fetchNumberFormat: () => void;
    fetchTimeFormat: () => void;
    fetchUnits: (typeId: number | null) => void;
    fetchReadWrite: () => void;
    fetchPresentationBinary: () => void;
    fetchVariables: () => Promise<SelectOption[]>;
    fetchCalculationMethods: () => void;
    fetchPresentationCalculated: () => void;
    fetchTextualConfiguration: () => Promise<SelectOption[]>;
    fetchAlertPriorities: () => void;
    fetchAlertChannels: () => void;
    fetchNumericalConfiguration: () => Promise<SelectOption[]>;
    fetchObjects: (organizationId: number) => void;
    fetchAppearanceBoxTypes: () => void;
    getParametersFromStoreAndApi: () => void;
    fetchParametersSchematics: () => void;
    fetchObjectIcons: () => void;
    fetchServiceGroups: () => void;
    fetchUserTypes: () => void;
}

export function useFetchAsyncSelectData(): UseFetchAsyncSelectData {
    const [formData] = useObjectForm();
    function fetchNumberFormat() {
        return request<SelectOption[]>('GET', parameterFormatNumber);
    }

    function fetchTimeFormat() {
        return request<SelectOption[]>('GET', parameterFormatTime);
    }

    function fetchUserTypes() {
        return request<SelectOption[]>('GET', userTypes);
    }

    function fetchUnits(typeId: number | null = null) {
        if (typeId) {
            return request<SelectOption[]>(
                'GET',
                parameterUnitsByTypeId(typeId),
            );
        }
        return request<SelectOption[]>('GET', parameterUnits);
    }

    function fetchAlertPriorities() {
        return request<SelectOption[]>('GET', alertPriorities);
    }

    function fetchAlertChannels() {
        return request<SelectOption[]>('GET', alertChannels);
    }

    function fetchReadWrite() {
        return request<SelectOption[]>('GET', parameterReadWrite);
    }

    function fetchAppearanceBoxTypes() {
        return request<SelectOption[]>('GET', appearanceBoxTypes);
    }

    function fetchPresentationBinary() {
        return request<SelectOption[]>('GET', parameterPresentationBinary);
    }

    function fetchServiceGroups() {
        return request<SelectOption[]>('GET', serviceGroups);
    }

    function fetchPresentationCalculated() {
        return request<SelectOption[]>('GET', parameterPresentationCalculated);
    }

    async function fetchVariables(): Promise<SelectOption[]> {
        const variables = await request<SelectOption[]>(
            'GET',
            parameterVariables,
        );
        return variables
            .sort(
                (a: SelectOption<string>, b: SelectOption<string>) =>
                    a!.label > b!.label,
            )
            .reverse();
    }

    function fetchCalculationMethods() {
        return request<SelectOption[]>('GET', parameterCalculationMethods);
    }

    function fetchTextualConfiguration(): Promise<SelectOption[]> {
        return request<SelectOption[]>('GET', parameterConfigurationTextual);
    }

    function fetchParametersSchematics() {
        return request<SelectOption[]>('GET', appearanceParameterSchemas);
    }

    async function fetchObjectIcons() {
        return request<SelectOption[]>('GET', objectIcons);
    }

    function fetchNumericalConfiguration(): Promise<SelectOption[]> {
        return request<SelectOption[]>('GET', parameterConfigurationNumerical);
    }

    function getParametersFromStoreAndApi(): Promise<SelectOption[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const responseApi = await fetchVariables();
                const parametersFromStore = formData.parameters
                    .filter(parameter => parameter.tempId)
                    .map(parameter => ({
                        label: parameter.name!.label,
                        value: parameter.tempId,
                    }));
                resolve([...responseApi, ...parametersFromStore]);
            } catch (e) {
                reject(e);
            }
        });
    }

    async function fetchObjects(organizationId: number) {
        const objects: MachineObject[] = await request<MachineObject[]>(
            'GET',
            OrganizationObjects(organizationId),
        );
        return objects.map(object => ({
            value: object.basicData.id,
            label: object.basicData.name,
        }));
    }

    return {
        fetchNumberFormat,
        fetchTimeFormat,
        fetchUnits,
        fetchReadWrite,
        fetchPresentationBinary,
        fetchVariables,
        fetchServiceGroups,
        fetchCalculationMethods,
        fetchPresentationCalculated,
        fetchTextualConfiguration,
        fetchNumericalConfiguration,
        fetchAlertPriorities,
        fetchAlertChannels,
        fetchObjects,
        fetchObjectIcons,
        fetchAppearanceBoxTypes,
        getParametersFromStoreAndApi,
        fetchParametersSchematics,
        fetchUserTypes,
    };
}
