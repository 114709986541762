import styled from 'styled-components';

export const SideMenuContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${props => props.theme.colors.secondary.background};
    box-sizing: border-box;
    padding: 16px 8px;
    width: 56px;
`;
