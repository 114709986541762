import { Group } from '../types/Group';
import { GroupsActionTypes, GroupsUnion } from '../models/actionTypes';
import { initAddGroupValues } from './constants/initAddGroupValues';

interface GroupsState {
    groups: Group[];
    groupsHasFetched: boolean;
    groupsIsFetching: boolean;
    groupIsAdding: boolean;
    groupHasAdded: boolean;
    groupHasEdited: boolean;
    groupEditionIsPending: boolean;
    addGroupForm: Group;
}

const initState: GroupsState = {
    groups: [],
    groupHasAdded: false,
    groupIsAdding: false,
    groupsHasFetched: false,
    groupsIsFetching: false,
    groupHasEdited: false,
    groupEditionIsPending: false,
    addGroupForm: initAddGroupValues,
};

export function groupsReducer(
    state: GroupsState = initState,
    action: GroupsUnion,
): GroupsState {
    switch (action.type) {
        case GroupsActionTypes.AddNewGroup: {
            return {
                ...state,
                groupIsAdding: true,
            };
        }

        case GroupsActionTypes.AddNewGroupSuccess: {
            return {
                ...state,
                groupIsAdding: false,
                groupHasAdded: true,
            };
        }

        case GroupsActionTypes.FetchGroups: {
            return {
                ...state,
                groupsIsFetching: true,
            };
        }

        case GroupsActionTypes.FetchGroupsSuccess: {
            return {
                ...state,
                groupsIsFetching: false,
                groupsHasFetched: true,
                groups: action.groups,
            };
        }

        case GroupsActionTypes.UpdateOrder: {
            return {
                ...state,
                groups: [...action.groups],
            };
        }

        case GroupsActionTypes.FetchGroupsFailed: {
            return {
                ...state,
                groups: [],
                groupsHasFetched: false,
                groupsIsFetching: false,
            };
        }

        case GroupsActionTypes.UpdateEditForm: {
            return {
                ...state,
                addGroupForm: action.group,
            };
        }

        case GroupsActionTypes.EditGroup: {
            return {
                ...state,
                groupEditionIsPending: true,
            };
        }

        case GroupsActionTypes.EditGroupSuccess: {
            return {
                ...state,
                groupEditionIsPending: false,
                groupHasEdited: true,
            };
        }

        case GroupsActionTypes.ResetStore: {
            return {
                ...state,
                groupIsAdding: false,
                groupHasAdded: false,
                addGroupForm: initAddGroupValues,
                groupHasEdited: false,
                groupEditionIsPending: false,
            };
        }

        default:
            return state;
    }
}
