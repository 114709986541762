import React from 'react';
import { OfferFormValues } from '../../types';
import { offerDuration } from '../../constants';
import { TimeTypeLicenseFields } from '../../../../components/TimeTypeLicenseFields';
import { FormikProps } from 'formik';
import { DataGrid } from '../../../../../../../../../../components/DataGrid';
import { Input } from '../../../components/Input';
import { useOrganizationFormState } from '../../../../../hooks/useOrganizationFormState';
import { calculateSummary } from '../../../tools';
import { headings } from './constants/headings';

interface Data {
    monthSummary: number;
    monthSummaryOffer: number;
    offer: React.ReactNode;
}

interface OfferFieldsProps extends FormikProps<OfferFormValues> {
    dateFromKey: keyof OfferFormValues;
    durationKey: keyof OfferFormValues;
    dateToKey: keyof OfferFormValues;
}

export function OfferFields(props: OfferFieldsProps) {
    const [, , keysForSummaryCalculate] = useOrganizationFormState();

    const summaryPrice = calculateSummary(keysForSummaryCalculate);

    async function submitOnBlur() {
        await props.submitForm();
    }

    const data: Data[] = [
        {
            monthSummary: summaryPrice,
            monthSummaryOffer:
                summaryPrice - summaryPrice * (props.values.offerValue / 100),
            offer: (
                <Input
                    type="number"
                    name="offerValue"
                    onBlur={submitOnBlur}
                    onChange={props.handleChange}
                    value={props.values.offerValue}
                />
            ),
        },
    ];

    return (
        <div>
            <TimeTypeLicenseFields<OfferFormValues, number>
                {...props}
                selectOptions={offerDuration}
                dateFromKey={props.dateFromKey}
                dateToKey={props.dateToKey}
                durationKey={props.durationKey}
            />
            <DataGrid<Data>
                data={data}
                headings={headings}
                keys={['monthSummary', 'monthSummaryOffer', 'offer']}
            />
        </div>
    );
}
