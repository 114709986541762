import React from 'react';
import { TableRow } from '@material-ui/core';

interface RowProps {
    children: React.ReactNode;
    onClick?: (args: any) => void;
    item?: any;
}

export function Row(props: RowProps) {
    function onClickHandle() {
        if (props.onClick) {
            props.onClick(props.item);
        }
    }

    return <TableRow onClick={onClickHandle}>{props.children}</TableRow>;
}
