import React from 'react';
import { Formik, FormikProps } from 'formik';

import { OfferFormValues } from './types';
import { Fields } from './Fields';
import { useOrganizationFormState } from '../../../hooks/useOrganizationFormState';

export function OfferFom() {
    const [state, setData] = useOrganizationFormState();

    const onSubmit = (formValue: OfferFormValues) => {
        setData({ ...state, billing: { ...state.billing, ...formValue } });
    };

    return (
        <Formik
            onSubmit={onSubmit}
            enableReinitialize={true}
            initialValues={{
                isOffer: state.billing.isOffer,
                offerDuration: state.billing.offerDuration,
                dateFrom: state.billing.dateFrom,
                dateTo: state.billing.dateTo,
                offerValue: state.billing.offerValue,
            }}
        >
            {(props: FormikProps<OfferFormValues>) => <Fields {...props} />}
        </Formik>
    );
}
