import React from 'react';
import { ContentBox } from '../ApproveAction/styled';
import { Typography } from '@material-ui/core';
import { Flex } from '../../styled/';
import Button from '@material-ui/core/Button';

interface InformationProps {
    heading?: string;
    desc?: string;
    okClick: () => void;
    children?: JSX.Element;
    size?: any;
}

export function InformationModalBody(props: InformationProps) {
    return (
        <>
            {props.heading ? (
                <ContentBox>
                    <Typography variant={props.size || 'h4'}>
                        {props.heading}
                    </Typography>
                </ContentBox>
            ) : null}
            <ContentBox>
                {props.desc ? <Typography>{props.desc}</Typography> : null}
                {props.children}
            </ContentBox>
            <Flex justifyContent="space-between">
                <Button onClick={props.okClick}>OK</Button>
            </Flex>
        </>
    );
}
