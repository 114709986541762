import { Parameter, ParameterType } from '../types/ParameterTypes';
import { ParametersActionTypes } from '../models/actionTypes';

export function updateParametersForm(data: Parameter) {
    return {
        type: ParametersActionTypes.UpdateForm,
        data,
    };
}

export function resetParametersForm() {
    return {
        type: ParametersActionTypes.ResetForm,
    };
}

export function setParameterType(parameterType: ParameterType) {
    return {
        type: ParametersActionTypes.SetParameterType,
        parameterType,
    };
}
