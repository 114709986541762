import React from 'react';
import { Button } from '@material-ui/core';
import { NavigateBefore } from '@material-ui/icons';

interface ButtonBackProps {
    prevStep?: () => void;
}

export function ButtonBack(props: ButtonBackProps) {
    return (
        <Button onClick={props.prevStep}>
            <NavigateBefore />
            Cofnij
        </Button>
    );
}
