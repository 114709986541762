import { Heading } from '../../../../../../components/DataGrid/types';

export const headings: Heading[] = [
    {
        key: 'name',
        heading: 'Imię i nazwisko',
    },
    {
        key: 'email',
        heading: 'E-mail',
    },
    {
        key: 'addDate',
        heading: 'Data utworzenia',
    },
];
