import React from 'react';
import { useParameterForm } from '../../../../hooks/useParameterForm';
import { Formik, FormikProps } from 'formik';
import { CalculatedParameter } from '../../../../types/CalculatedParameter';
import { formInitValues } from './constants/formInitValues';
import { Fields } from './Fields';
import { CalculatedSchema } from './constants/validationScheme';

interface CalculatedProps {
    onSubmitHandle: (data: CalculatedParameter) => void;
    onCancel: () => void;
}

export function Calculated(props: CalculatedProps) {
    const [formData] = useParameterForm();

    return (
        <Formik<CalculatedParameter>
            initialValues={{ ...formInitValues, ...formData }}
            enableReinitialize={true}
            validationSchema={CalculatedSchema}
            onSubmit={props.onSubmitHandle}
            render={(formikProps: FormikProps<CalculatedParameter>) => (
                <Fields onCancel={props.onCancel} {...formikProps} />
            )}
        />
    );
}
