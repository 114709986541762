import React, { useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { Alarm } from '../../../../../Alarms/types/Alarm';
import { AlarmField } from './components/AlarmField/AlarmField';
import { AlarmPriorities } from '../../../../../Alarms/types/AlarmPriorities';
import { ContentContainer, SubFormContainer } from '../../../../../formStyled';
import { default as MaterialDrawer } from '@material-ui/core/Drawer/Drawer';
import { DrawerContentContainer } from '../../../../../../../components/Drawer/styled';
import { AddEditForm } from '../../../../../Alarms/components/TopPanel/forms/AddEditForm';
import { useDrawerVisible } from '../../../../../../../hooks';
import { Subheading } from '../../../../../../../components/Subheading';
import { BottomNavBar } from '../../../../../../../components/ProgressStepper/components';
import { BottomPanel } from '../../../BottomPanel';
import { generateRandomString } from '../../../../../../../tools';
import { FormBottomPanelContainer } from './styled';

interface FieldsProps extends FormikProps<Alarm[]> {
    prevStepHandle: (alarms: Alarm[]) => void;
    alarmsRowErrors: string[];
}

export function Fields(props: FieldsProps) {
    const [checked, setChecked] = useState<string[]>([]);
    const [editedAlarm, setEditedAlarm] = useState<Alarm>();
    const { drawerState, openDrawer, closeDrawer } = useDrawerVisible();
    const formRef = useRef<FormikProps<any>>();

    function prevStepHandle() {
        props.prevStepHandle(props.values);
    }

    function check(tempId: string) {
        setChecked([...checked, tempId]);
    }

    function uncheck(tempId: string) {
        setChecked(checked.filter(id => id !== tempId));
    }

    function editAlarmOpenForm(alarm: Alarm) {
        setEditedAlarm(alarm);
        openDrawer();
    }

    function removeAlarms() {
        const updatedValues = props.values.filter(
            alarm => !checked.includes(alarm.tempId),
        );

        setChecked([]);
        props.setValues(updatedValues);
    }

    function addNewAlarm() {
        const updatedValues = [
            ...props.values,
            {
                tempId: generateRandomString(),
                name: '',
                priority: {
                    value: 2,
                    label: AlarmPriorities.Medium,
                },
                platform: [],
                description: '',
                variableName: undefined,
                value: {
                    value: 0,
                    label: '0',
                },
            },
        ];
        props.setValues(updatedValues);
    }

    function editAlarmHandle(modifiedAlarm: Alarm) {
        props.setValues(
            props.values.map(value => {
                if (value.tempId === modifiedAlarm.tempId) {
                    return {
                        ...value,
                        ...modifiedAlarm,
                    };
                }
                return value;
            }),
        );
        closeDrawer();
    }

    function changeAlarmName(name: string, tempId: string) {
        props.setValues(
            props.values.map(value => {
                if (value.tempId === tempId) {
                    return {
                        ...value,
                        name,
                    };
                }
                return value;
            }),
        );
    }

    function renderAlarms() {
        return props.values.map(alarm => {
            const isChecked = checked.includes(alarm.tempId);
            return (
                <AlarmField
                    key={alarm.tempId}
                    alarm={alarm}
                    hasError={props.alarmsRowErrors.includes(alarm.tempId)}
                    changeAlarmName={changeAlarmName}
                    editHandle={editAlarmOpenForm}
                    checkHandle={isChecked ? uncheck : check}
                    isChecked={isChecked}
                />
            );
        });
    }

    function submitEdit() {
        if (formRef.current) {
            formRef.current.submitForm();
        }
    }

    return (
        <>
            <MaterialDrawer
                anchor="right"
                open={drawerState}
                onClose={closeDrawer}
            >
                <DrawerContentContainer size="normal">
                    <SubFormContainer>
                        <Subheading>Edycja alarmu</Subheading>
                        <AddEditForm
                            ref={formRef}
                            formData={editedAlarm!}
                            updateFormData={editAlarmHandle}
                        />
                        <FormBottomPanelContainer>
                            <BottomPanel<null>
                                actionHandleArg={null}
                                primaryButtonActionHandle={submitEdit}
                                primaryButtonText="Zapisz"
                                secondButtonActionHandle={closeDrawer}
                                secondButtonText="Anuluj"
                            />
                        </FormBottomPanelContainer>
                    </SubFormContainer>
                </DrawerContentContainer>
            </MaterialDrawer>
            <form onSubmit={props.handleSubmit}>
                <ContentContainer>{renderAlarms()}</ContentContainer>
                <BottomPanel<null>
                    actionHandleArg={null}
                    primaryButtonActionHandle={addNewAlarm}
                    primaryButtonText="Dodaj"
                    secondButtonActionHandle={removeAlarms}
                    secondButtonText="Usuń"
                />
                <BottomNavBar
                    prevStep={prevStepHandle}
                    showBack={true}
                    buttonNextText="Dalej"
                />
            </form>
        </>
    );
}
