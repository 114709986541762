import { Heading } from '../../../../../../../../components/DataGrid/types';

export const headingsKeys = [
    'name',
    'priority',
    'platform',
    'value',
    'variableName',
];
export const headings: Heading[] = [
    {
        key: 'name',
        heading: 'Nazwa',
    },
    {
        key: 'priority',
        heading: 'Pryorytet',
    },
    {
        key: 'platform',
        heading: 'Platforma',
    },
    {
        key: 'value',
        heading: 'Wartość',
    },
    {
        key: 'variableName',
        heading: 'Zmienna',
    },
];
