import React from 'react';
import { components, OptionProps } from 'react-select';
import { LabelContainer } from './styled';
import { Flex } from '../../../../../../../../../styled/';

const { Option } = components;
export function OptionWithIcons(props: OptionProps<any>) {
    return (
        <Option {...props}>
            <Flex>
                <img
                    src={require(`@bumerang/icons/${props.data.icon}.svg`)}
                    style={{ width: 22 }}
                    alt={props.data.icon}
                />
                <LabelContainer>{props.data.icon}</LabelContainer>
            </Flex>
        </Option>
    );
}
