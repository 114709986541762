import React from 'react';
import { ContentContainer } from './styled';
import Checkbox from '@material-ui/core/Checkbox';
import { FormikSelect } from '../../../../../../../../../../tools/FormikFields';
import { SelectOption } from '../../../../../../../../../../types/SelectOption';
import { BoxAppearanceVariable } from '../../../../../../../../Objects/types/AppearanceBase';
import { useObjectForm } from '../../../../../../../../Objects/hooks';

interface VariableProps {
    isChecked: boolean;
    onCheck: (rowId: string) => void;
    setParameter: (rowId: string, value: SelectOption) => void;
    parameter: BoxAppearanceVariable;
}

export function Variable(props: VariableProps) {
    const [formData] = useObjectForm();

    function checkHandle() {
        props.onCheck(props.parameter.tempId);
    }

    function setParameter(value: SelectOption) {
        props.setParameter(props.parameter.tempId, value);
    }

    return (
        <ContentContainer>
            <Checkbox
                checked={props.isChecked}
                onChange={checkHandle}
                value="checkedB"
                color="primary"
            />
            <FormikSelect
                cacheOptions
                
                defaultValue={props.parameter.label ? props.parameter : null}
                defaultOptions
                onChange={setParameter}
                options={formData.parameters.map(param => ({
                    label: param.name!.label,
                    value: param.tempId,
                }))}
                placeholder="Parameter"
            />
        </ContentContainer>
    );
}
