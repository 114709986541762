import React from 'react';
import { SchematicField } from '../../../../../../../../Objects/types/AppearanceBase';
import { Field } from './components';
import {
    Parameter,
    ParameterTypes,
} from '../../../../../../../../Parameters/types/ParameterTypes';
import { ContentContainer } from './styled';

interface SchemasProps {
    fields: SchematicField[];
    parameters: Parameter[];
    setParameter: (field: SchematicField, parameter: Parameter) => void;
}

export function Schemas(props: SchemasProps) {
    return (
        <ContentContainer>
            {props.fields.map((field, key) => (
                <Field
                    parameters={props.parameters.filter(
                        parameter =>
                            parameter.type === field.type ||
                            parameter.type === ParameterTypes.Calculated,
                    )}
                    setParameter={props.setParameter}
                    key={key}
                    field={field}
                />
            ))}
        </ContentContainer>
    );
}
