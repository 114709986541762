import React from 'react';
import { FormikProps } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel, FormControlLabel } from '@material-ui/core';

import { PhotoGalleryForm } from '../../../../../Objects/types/PhotoGalleryForm';
import { WideField } from '../../../../../../../styled/';
import { BottomPanel, DragAndDropZone } from '../index';
import {
    ExpansionPanelDetailsWrapper,
    ExpansionPanelSummaryWrapper,
} from './styled';
import { PhotosPanel } from '../PhotosPanel';
import { BottomNavBar } from '../../../../../../../components/ProgressStepper/components';

interface FieldsProps extends FormikProps<PhotoGalleryForm[]> {
    prevStep: () => void;
    removeGalleries: () => void;
    addNewGallery: (formData: PhotoGalleryForm[]) => void;
    checkedList: string[];
    checkHandle: (galleryName: string) => void;
}
export function Fields(props: FieldsProps) {
    function renderGallery() {
        return props.values.map((gallery, index) => {
            function checkHandle() {
                props.checkHandle(gallery.tempId);
            }

            function nameHandle(name: any) {
                const update = props.values.map(editedGallery => {
                    if (editedGallery.tempId === gallery.tempId) {
                        return {
                            ...editedGallery,
                            galleryName: name.target.value,
                        };
                    }
                    return editedGallery;
                });
                props.setValues(update);
            }

            function removePhoto(photo: File) {
                const update = props.values.map(editedGallery => {
                    if (editedGallery.tempId === gallery.tempId) {
                        return {
                            ...editedGallery,
                            galleryPhotos: editedGallery.galleryPhotos.filter(
                                (filteredPhoto: File) =>
                                    filteredPhoto.name !== photo.name,
                            ),
                        };
                    }
                    return editedGallery;
                });
                props.setValues(update);
            }

            function photoHandle(photos: File[]) {
                const update = props.values.map(editedGallery => {
                    if (editedGallery.tempId === gallery.tempId) {
                        return {
                            ...editedGallery,
                            galleryPhotos: [
                                ...editedGallery.galleryPhotos,
                                ...photos,
                            ],
                        };
                    }
                    return editedGallery;
                });
                props.setValues(update);
            }

            return (
                <ExpansionPanel key={index}>
                    <ExpansionPanelSummaryWrapper
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.checkedList.includes(
                                        gallery.tempId,
                                    )}
                                    onChange={checkHandle}
                                    value="checkedB"
                                    color="primary"
                                />
                            }
                            label={
                                gallery.galleryName
                                    ? `Galeria "${gallery.galleryName}"`
                                    : 'Galeria'
                            }
                        />
                    </ExpansionPanelSummaryWrapper>
                    <ExpansionPanelDetailsWrapper>
                        <WideField
                            name="galleryName"
                            value={gallery.galleryName}
                            onChange={nameHandle}
                            onBlur={props.handleBlur}
                            label="Nazwa"
                            variant="outlined"
                        />
                        <DragAndDropZone
                            acceptFiles={['image/jpeg', 'image/png']}
                            changeHandle={photoHandle}
                        />
                        {gallery.galleryPhotos.length ? (
                            <PhotosPanel
                                removeHandler={removePhoto}
                                photos={gallery.galleryPhotos}
                            />
                        ) : null}
                    </ExpansionPanelDetailsWrapper>
                </ExpansionPanel>
            );
        });
    }

    return (
        <>
            <form onSubmit={props.handleSubmit}>{renderGallery()}</form>
            <BottomPanel<PhotoGalleryForm[]>
                actionHandleArg={props.values}
                primaryButtonActionHandle={props.addNewGallery}
                primaryButtonText="Dodaj"
                secondButtonText="Usuń"
                secondButtonActionHandle={props.removeGalleries}
            />
            <BottomNavBar
                prevStep={props.prevStep}
                showBack={true}
                nextStep={props.handleSubmit}
                buttonNextText="Dalej"
            />
        </>
    );
}
