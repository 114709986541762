import React from 'react';
import { ContentContainer } from '../styled';
import { Heading } from '../../../components/Heading';
import { TopPanel } from './components';
import { useDrawerVisible } from '../../../hooks';

export function Alarms() {
    const { drawerState, openDrawer, closeDrawer } = useDrawerVisible();

    return (
        <ContentContainer>
            <Heading color="black">Alarmy</Heading>
            <TopPanel
                closeAddAlarm={closeDrawer}
                openAddAlarm={openDrawer}
                isOpen={drawerState}
            />
        </ContentContainer>
    );
}
