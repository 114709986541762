import React from 'react';
import { DeepMenu } from '../../../components/DeepMenu';
import { menuItems } from './constants/menuItems';

export function withManageMenu<TP = any>(
    WrappedComponent: React.ComponentType | React.FC,
) {
    return class extends React.PureComponent {
        render() {
            return (
                <DeepMenu
                    header="Zarządzaj"
                    menuItems={menuItems}
                    headerLinkPath="/manage"
                >
                    <WrappedComponent {...this.props} />
                </DeepMenu>
            );
        }
    };
}
