import { ObjectsActionTypes } from '../../views/Organization/Objects/models/actionTypes';
import { addObjectDraftToStorage } from '../../tools/addObjectDraftToStorage';

export const cacheAddObjectSteps = (store: any) => (next: any) => (
    action: any,
) => {
    if (action.type === ObjectsActionTypes.UpdateAddObjectFormData) {
        addObjectDraftToStorage(
            {
                ...store.getState().objects.addObjectForm,
                ...action.formValue,
                draftId: action.draftId,
            },
            action.draftId,
        );
    }

    next(action);
};
