import React from 'react';
import { Heading } from '../../../index';
import { Step } from '../..';
import { StepperContainer, PointsContainer, HeadingContainer } from './styled';
import { Content, Point } from '..';

interface StepperProps {
    steps: Step[];
    activeStep: number;
    nextStep: () => void;
    prevStep: () => void;
    closeDrawer: () => void;
    isObjectEdit?: boolean;
    isAddTemplate?: boolean;
}

export function Stepper(props: StepperProps) {
    const renderPoints = () =>
        props.steps.map((step, index, arr) => (
            <Point
                key={index}
                isActive={index === props.activeStep}
                isLast={index === arr.length - 1}
                title={step.title}
                stepNumber={index + 1}
                isApprove={index < props.activeStep}
            />
        ));

    const componentProps = {
        nextStep: props.nextStep,
        prevStep: props.prevStep,
        closeDrawer: props.closeDrawer,
        isObjectEdit: props.isObjectEdit || false,
        isAddTemplate: props.isAddTemplate || false,
    };

    return (
        <StepperContainer>
            <PointsContainer>{renderPoints()}</PointsContainer>
            <Content>
                <HeadingContainer>
                    <Heading color="black">
                        {props.steps[props.activeStep].title}
                    </Heading>
                </HeadingContainer>
                {React.cloneElement(
                    // @ts-ignore
                    props.steps[props.activeStep].component,
                    componentProps,
                )}
            </Content>
        </StepperContainer>
    );
}
