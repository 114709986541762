import React from 'react';
import { ContentContainer, IconContainer, ImgWrapped } from './styled';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

interface PhotoProps {
    photo: File;
    removeHandler: (file: File) => void;
}

export function Photo(props: PhotoProps) {
    function onClickHandle() {
        props.removeHandler(props.photo);
    }

    return (
        <ContentContainer>
            <ImgWrapped src={URL.createObjectURL(props.photo)} />
            <IconContainer>
                <RemoveCircleIcon onClick={onClickHandle} />
            </IconContainer>
        </ContentContainer>
    );
}
