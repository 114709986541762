import { ParameterTypes } from '../../../../../types/ParameterTypes';
import { OrderParameter } from '../../../../../types/OrderParameter';

export const formInitValues: OrderParameter = {
    name: undefined,
    address: '',
    orderId: 0,
    fullDescription: '',
    shortDescription: '',
    type: ParameterTypes.Order,
};
