import React from 'react';
import { Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { BottomPanel } from './styled';
import { ButtonBack } from './components';

interface BottomNavBarProps {
    prevStep?: () => void;
    nextStep?: () => void;
    showBack?: boolean;
    showNext?: boolean;
    buttonNextText?: string;
}

BottomNavBar.defaultProps = {
    prevStep: () => {},
    nextStep: () => {},
    showBack: true,
    showNext: true,
    buttonNextText: 'Dalej',
};

export function BottomNavBar(props: BottomNavBarProps) {
    return (
        <BottomPanel>
            {props.showBack ? <ButtonBack prevStep={props.prevStep} /> : null}
            <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={props.nextStep}
            >
                {props.buttonNextText}
                <NavigateNextIcon />
            </Button>
        </BottomPanel>
    );
}
