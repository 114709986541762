import React from 'react';
import { Subheading } from '../../../../../components/Subheading';
import { SubHeadingContainer } from '../../styled';

export function SubHeading() {
    return (
        <SubHeadingContainer>
            <Subheading>Edycja parametru</Subheading>
        </SubHeadingContainer>
    );
}
