import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { SearchField, SearchResult } from './components';
import { Organization } from '../../../../types';
import Divider from '@material-ui/core/Divider';
import { AppState } from '../../../../reducer';
import { ContentContainer } from './styled';
import { getOrganizations } from '../../../../views/Manage/actions/organizations';
import { organizations } from '../../../../views/Manage/selectors/organizations';

interface NavSearchContentProps {
    closeDrawerHandle: () => void;
}

export function NavSearchContent(props: NavSearchContentProps) {
    const fetchedOrganizations = useSelector((state: AppState) =>
        organizations(state),
    );
    const dispatch = useDispatch();
    const [preparedOrganizations, setPreparedOrganizations] = useState<
        Organization[]
    >([]);

    function searchHandle(searchedString: string) {
        setPreparedOrganizations(
            fetchedOrganizations.filter(org =>
                org.name.includes(searchedString),
            ),
        );
    }

    useEffect(() => {
        if (!fetchedOrganizations.length) {
            dispatch(getOrganizations());
        }
    }, [dispatch, fetchedOrganizations.length]);

    return (
        <ContentContainer>
            <SearchField searchHandle={searchHandle} />
            <Divider />
            <SearchResult
                foundElements={preparedOrganizations}
                closeDrawerHandle={props.closeDrawerHandle}
            />
        </ContentContainer>
    );
}
