import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducer';
import {
    fetchedGroups,
    groupsHasFetched,
    groupsIsFetching,
} from '../selectors/groups';
import { Group } from '../types/Group';
import { useEffect } from 'react';
import { changeOrder, getGroups } from '../actions/groups';

interface UseGroupsOutput {
    groups: Group[];
    groupsIsFetchingState: boolean;
    groupsHasFetchedState: boolean;
    updateOrder: (groups: Group[]) => void;
}

export function useGroups(organizationId: number): UseGroupsOutput {
    const dispatch = useDispatch();
    const groups = useSelector((state: AppState) => fetchedGroups(state));
    const groupsIsFetchingState = useSelector((state: AppState) =>
        groupsIsFetching(state),
    );
    const groupsHasFetchedState = useSelector((state: AppState) =>
        groupsHasFetched(state),
    );

    function updateOrder(groups: Group[]) {
        dispatch(changeOrder(groups, organizationId));
    }

    useEffect(() => {
        if (!groupsHasFetchedState && !groupsIsFetchingState) {
            dispatch(getGroups(organizationId));
        }
    }, [
        dispatch,
        groupsHasFetchedState,
        groupsIsFetchingState,
        organizationId,
    ]);

    return {
        groups,
        updateOrder,
        groupsIsFetchingState,
        groupsHasFetchedState,
    };
}
