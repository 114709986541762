import React from 'react';
import { Formik, FormikProps } from 'formik';
import { FormValues } from './type';
import { Grid } from './Grid';
import { useOrganizationFormState } from '../../../../hooks/useOrganizationFormState';

export function AdditionalServices() {
    const [state, setState] = useOrganizationFormState();

    const onSubmitHandle = (value: any) => {
        setState({
            ...state,
            billing: { ...state.billing, additionalServices: value },
        });
    };

    return (
        <Formik
            initialValues={{
                serviceCount: state.billing.additionalServices.serviceCount,
                servicePrice: state.billing.additionalServices.servicePrice,
                serviceSummary: state.billing.additionalServices.serviceSummary,
            }}
            onSubmit={onSubmitHandle}
        >
            {(props: FormikProps<FormValues>) => <Grid {...props} />}
        </Formik>
    );
}
