import React from 'react';
import { FormikProps } from 'formik';
import { OrderParameter } from '../../../../types/OrderParameter';
import {
    ContentContainer,
    InlineFieldContainer,
    InlineFieldDivider,
    StyledForm,
} from '../../../../../formStyled';
import { WideField } from '../../../../../../../styled/';
import { FormikSelect } from '../../../../../../../tools/FormikFields';
import { PARAMETER_TYPES } from '../selectData/selectData';
import { useSelectParameterForm } from '../../../../hooks/useSelectParameterForm';
import { FormContainer } from '../../../../styled';
import { SubmitPanel, SubHeading } from '../../../';
import { ParameterTypesNames } from '../../../../types/ParameterTypes';

interface FieldsProps extends FormikProps<OrderParameter> {
    onCancel: () => void;
}

export function Fields(props: FieldsProps) {
    const setSelectType = useSelectParameterForm<OrderParameter>(props);
    return (
        <FormContainer>
            <SubHeading />
            <StyledForm onSubmit={props.handleSubmit}>
                <ContentContainer>
                    <WideField
                        name="name"
                        value={props.values.name?.label || ''}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.name && props.touched.name}
                        label="Nazwa"
                        variant="outlined"
                        disabled
                    />
                    <InlineFieldContainer>
                        <FormikSelect
                            name="type"
                            cacheOptions
                            options={PARAMETER_TYPES}
                            defaultValue={{
                                label: ParameterTypesNames[props.values.type],
                                value: props.values.type,
                            }}
                            error={props.errors.type && props.touched.type}
                            onChange={setSelectType}
                            onBlur={props.handleBlur}
                            placeholder="Typ parametru"
                        />
                    </InlineFieldContainer>
                    <InlineFieldContainer>
                        <WideField
                            name="address"
                            value={props.values.address}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={
                                props.errors.address && props.touched.address
                            }
                            label="Adres"
                            variant="outlined"
                        />
                        <InlineFieldDivider />
                        <WideField
                            type="number"
                            name="orderId"
                            value={props.values.orderId}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={
                                props.errors.orderId && props.touched.orderId
                            }
                            label="ID rozkazu"
                            variant="outlined"
                        />
                    </InlineFieldContainer>
                    <WideField
                        name="shortDescription"
                        value={props.values.shortDescription}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        multiline
                        rows={3}
                        error={
                            props.errors.shortDescription &&
                            props.touched.shortDescription
                        }
                        label="Krótki opis (do 19 znaków)"
                        variant="outlined"
                    />
                    <WideField
                        name="fullDescription"
                        value={props.values.fullDescription}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        multiline
                        rows={5}
                        error={
                            props.errors.fullDescription &&
                            props.touched.fullDescription
                        }
                        label="Pełny opis"
                        variant="outlined"
                    />
                </ContentContainer>
            </StyledForm>
            <SubmitPanel
                onCancel={props.onCancel}
                submitForm={props.submitForm}
            />
        </FormContainer>
    );
}
