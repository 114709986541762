import { AppState } from '../../../../reducer';
import { initialStateByOrganizationId } from '../reducer/objects';

function organizationObjectsState(state: AppState) {
    return state.objects;
}

function organizationObjectsByIdState(state: AppState, organizationId: number) {
    return (
        organizationObjectsState(state)[organizationId] ||
        initialStateByOrganizationId
    );
}

export function organizationObjects(state: AppState, organizationId: number) {
    return organizationObjectsByIdState(state, organizationId).objects;
}

export function organizationObjectsFetched(
    state: AppState,
    organizationId: number,
) {
    return organizationObjectsByIdState(state, organizationId).objectHasFetched;
}

export function organizationObjectsFetching(
    state: AppState,
    organizationId: number,
) {
    return organizationObjectsByIdState(state, organizationId).objectFetching;
}

export function organizationObjectsFormData(state: AppState) {
    return organizationObjectsState(state).addObjectForm;
}

export function getRemovedParameters(state: AppState) {
    return organizationObjectsState(state).removedParameters;
}

export function objectTypes(state: AppState) {
    return organizationObjectsState(state).objectTypes;
}

export function objectTypesIsFetching(state: AppState) {
    return organizationObjectsState(state).objectTypesIsFetching;
}

export function objectTypesFetched(state: AppState) {
    return organizationObjectsState(state).objectTypesHasFetched;
}

export function objectParameters(state: AppState) {
    return organizationObjectsState(state).objectParameters;
}

export function objectParametersIsFetching(state: AppState) {
    return organizationObjectsState(state).objectParametersIsFetching;
}

export function objectParametersFetched(state: AppState) {
    return organizationObjectsState(state).objectParametersHasFetched;
}

export function objectSettings(state: AppState) {
    return organizationObjectsState(state).objectSettings;
}

export function objectSettingsIsFetching(state: AppState) {
    return organizationObjectsState(state).objectSettingsIsFetching;
}

export function objectSettingsFetched(state: AppState) {
    return organizationObjectsState(state).objectSettingsHasFetched;
}

export function addObjectIsPending(state: AppState) {
    return organizationObjectsState(state).addObjectIsPending;
}

export function addObjectError(state: AppState) {
    return organizationObjectsState(state).addObjectError;
}

export function addObjectSuccess(state: AppState) {
    return organizationObjectsState(state).addObjectIsSuccess;
}

export function updateObjectIsPending(state: AppState) {
    return organizationObjectsState(state).updateObjectIsPending;
}

export function updateObjectError(state: AppState) {
    return organizationObjectsState(state).updateObjectError;
}

export function updateObjectSuccess(state: AppState) {
    return organizationObjectsState(state).updateObjectIsSuccess;
}
