import { AppState } from '../../../../reducer';
import { initState, UsersState, UsersStateByOrganizationId } from '../reducer';

function usersState(appState: AppState): UsersStateByOrganizationId {
    return appState.users;
}

function organizationDetailsById(
    state: AppState,
    organizationId: number,
): UsersState {
    return usersState(state)[organizationId] || initState;
}

export function getAddUserFormData(state: AppState, orgId: number) {
    return organizationDetailsById(state, orgId).addUserForm;
}

export function organizationUsers(state: AppState, organizationId: number) {
    return organizationDetailsById(state, organizationId).users;
}

export function addFormIsOpened(state: AppState, organizationId: number) {
    return organizationDetailsById(state, organizationId).addFormIsOpen;
}

export function editFormIsOpened(state: AppState, organizationId: number) {
    return organizationDetailsById(state, organizationId).editFormIsOpen;
}

export function editUserError(state: AppState, organizationId: number) {
    return organizationDetailsById(state, organizationId).userEditError;
}

export function addUserError(state: AppState, organizationId: number) {
    return organizationDetailsById(state, organizationId).userAddError;
}

export function userHasAdded(state: AppState, organizationId: number) {
    return organizationDetailsById(state, organizationId).userHasAdded;
}

export function userHasEdited(state: AppState, organizationId: number) {
    return organizationDetailsById(state, organizationId).userHasUpdate;
}

export function usersIsFetching(state: AppState, organizationId: number) {
    return organizationDetailsById(state, organizationId).usersIsFetching;
}

export function usersFetched(state: AppState, organizationId: number) {
    return organizationDetailsById(state, organizationId).usersHasFetched;
}
