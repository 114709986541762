import React from 'react';
import { DataGrid } from '../../../../../../../components/DataGrid';
import { PhotoGalleryForm } from '../../../../../Objects/types/PhotoGalleryForm';
import { headings, headingsKeys } from './constants/headings';
import { CELL_WIDTH } from '../../constants/cellWidth';

interface GalleriesProps {
    galleries: PhotoGalleryForm[];
}

export function Galleries(props: GalleriesProps) {
    return (
        <>
            <h2>Galerii</h2>
            <DataGrid<PhotoGalleryForm>
                cellWidth={CELL_WIDTH}
                headings={headings}
                keys={headingsKeys}
                data={props.galleries.map(gallery => ({
                    ...gallery,
                    galleryPhotos: gallery.galleryPhotos.length as any,
                }))}
            />
        </>
    );
}
