import { AppearanceBase } from '../../types/AppearanceBase';
import { BoxSizes } from '../../../Templates/components/Appearances/types/BoxSizes';

export const initParametersAppearance: AppearanceBase = {
    box: {
        'box-1': {
            id: 'box-1',
            size: BoxSizes.Medium,
            name: 'Schemat obiektu',
            parameters: [],
            description: '',
            type: undefined,
        },
        'box-2': {
            id: 'box-2',
            size: BoxSizes.Small,
            name: 'Parametry',
            parameters: [],
            description: '',
            type: undefined,
        },
        'box-3': {
            id: 'box-3',
            size: BoxSizes.Small,
            name: 'Pompa P2',
            parameters: [],
            description: '',
            type: undefined,
        },
        'box-4': {
            id: 'box-4',
            size: BoxSizes.Small,
            name: 'Pompa P1',
            parameters: [],
            description: '',
            type: undefined,
        },
    },
    columns: {
        'column-1': {
            id: 'column-1',
            boxIds: ['box-1'],
        },
        'column-2': {
            id: 'column-2',
            boxIds: ['box-2', 'box-3'],
        },
        'column-3': {
            id: 'column-3',
            boxIds: ['box-4'],
        },
    },
    columnOrder: ['column-1', 'column-2', 'column-3'],
};
