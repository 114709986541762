import { Localization } from '../../../../../../Objects/types';

export const initFormValues: Localization = {
	town: '',
	postCode: '',
	address: '',
	county: '',
	community: '',
	voivodeship: '',
	latitude: 0,
	longitude: 0,
};
