import { Group } from '../types/Group';

export enum GroupsActionTypes {
    FetchGroups = '[Groups] fetch groups',
    FetchGroupsSuccess = '[Groups] fetch groups success',
    FetchGroupsFailed = '[Groups] fetch groups failed',

    AddNewGroup = '[Group] add new group',
    AddNewGroupSuccess = '[Group] add new group success',
    UpdateEditForm = '[Group] update edit form',

    EditGroup = '[Group] edit group',
    EditGroupSuccess = '[Group] edit group success',

    ResetStore = '[Group] reset store',
    UpdateOrder = '[Group] update order',
}

export interface UpdateEditForm {
    type: typeof GroupsActionTypes.UpdateEditForm;
    group: Group;
}

export interface EditGroup {
    type: typeof GroupsActionTypes.EditGroup;
}

export interface EditGroupSuccess {
    type: typeof GroupsActionTypes.EditGroupSuccess;
}

export interface UpdateOrder {
    type: typeof GroupsActionTypes.UpdateOrder;
    groups: Group[];
}

export interface ResetStore {
    type: typeof GroupsActionTypes.ResetStore;
}

export interface FetchGroups {
    type: typeof GroupsActionTypes.FetchGroups;
}

export interface FetchGroupsSuccess {
    type: typeof GroupsActionTypes.FetchGroupsSuccess;
    groups: Group[];
}

export interface FetchGroupsFailed {
    type: typeof GroupsActionTypes.FetchGroupsFailed;
}

export interface AddNewGroup {
    type: typeof GroupsActionTypes.AddNewGroup;
}

export interface AddNewGroupSuccess {
    type: typeof GroupsActionTypes.AddNewGroupSuccess;
}

export type GroupsUnion =
    | FetchGroups
    | FetchGroupsSuccess
    | FetchGroupsFailed
    | AddNewGroup
    | ResetStore
    | UpdateOrder
    | EditGroup
    | UpdateEditForm
    | EditGroupSuccess
    | AddNewGroupSuccess;
