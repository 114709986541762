import { ActionTypes } from '../models/actionTypes';
import { User } from '../types';

export function setAddUserFormData(
    organizationId: number,
    formData: Partial<User>,
) {
    return {
        type: ActionTypes.SetAddUserFormData,
        organizationId,
        formData,
    };
}

export function resetAddUserFormData(organizationId: number) {
    return {
        type: ActionTypes.ResetAddUserFormData,
        organizationId,
    };
}

export function openEditUser(organizationId: number, formData: Partial<User>) {
    return {
        type: ActionTypes.OpenEditForm,
        organizationId,
        formData,
    };
}

export function openAddUser(organizationId: number) {
    return {
        type: ActionTypes.OpenAddForm,
        organizationId,
    };
}

export function closeForm(organizationId: number) {
    return {
        type: ActionTypes.CloseAddEditForm,
        organizationId,
    };
}
