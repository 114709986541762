import React from 'react';
import { ContentContainer } from '../styled';
import { Grid, TopPanel } from './components';
import { Heading } from '../../../components';
import { RouteComponentProps } from 'react-router';

interface UsersProps extends RouteComponentProps<{ id: string }> {}

export function Users(props: UsersProps) {
    const { id } = props.match.params;

    return (
        <ContentContainer>
            <Heading color="black">Użytkownicy</Heading>
            <TopPanel organizationId={Number(id)} />
            <Grid
                history={props.history}
                location={props.location}
                match={props.match}
            />
        </ContentContainer>
    );
}
