import React from 'react';
import { ObjectDraft } from '../../../../types/ObjectDraft';
import { Item } from './components/Item';
import { Typography } from '@material-ui/core';
import { ContentContainer } from './styled';

interface DraftsProps {
    drafts: ObjectDraft[];
    openDraft: (id: string) => void;
    removeDraft: (id: string) => void;
}

export function Drafts(props: DraftsProps) {
    return (
        <ContentContainer>
            <Typography variant="h5">Wersji Robocze</Typography>
            <ul>
                {props.drafts.map((draft, index) => (
                    <Item
                        key={index}
                        draft={draft}
                        openDraft={props.openDraft}
                        removeDraft={props.removeDraft}
                    />
                ))}
            </ul>
        </ContentContainer>
    );
}
