import { useDispatch, useSelector } from 'react-redux';
import { addTemplate, resetStore } from '../actions/templates';
import { MachineObject } from '../../Objects/types';
import { AppState } from '../../../../reducer';
import {
    addingTemplateError,
    addingTemplateIsPending,
    templateHasAdded,
} from '../selectors/templates';

interface UseAddTemplateOutput {
    add: (formData: Partial<MachineObject>) => void;
    resetAddTemplateStore: () => void;
    addingError: string;
    isPending: boolean;
    hasAdded: boolean;
}

export function useAddTemplate(): UseAddTemplateOutput {
    const dispatch = useDispatch();

    const isPending = useSelector((state: AppState) =>
        addingTemplateIsPending(state),
    );

    const hasAdded = useSelector((state: AppState) => templateHasAdded(state));

    const addingError = useSelector((state: AppState) =>
        addingTemplateError(state),
    );

    function add(formData: Partial<MachineObject>) {
        dispatch(addTemplate(formData));
    }

    function resetAddTemplateStore() {
        dispatch(resetStore());
    }

    return {
        add,
        resetAddTemplateStore,
        addingError,
        isPending,
        hasAdded,
    };
}
