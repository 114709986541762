import React from 'react';
import { ItemContainer } from './styled';

interface MenuItemProps {
    icon: React.ReactNode;
    clickHandle?: () => void;
}

export function MenuItem(props: MenuItemProps) {
    return (
        <ItemContainer onClick={props.clickHandle}>{props.icon}</ItemContainer>
    );
}
