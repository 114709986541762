import React from 'react';
import { FormikProps } from 'formik';
import { FormValues } from './types';
import { Input } from '../Input';
import { DataGrid } from '../../../../../../../../../components/DataGrid';
import { calculate } from './tools';
import { calculateClientPrice } from '../tools';
import { headings } from './constants/headings';

interface Data {
    module: string;
    unitPrice: React.ReactNode;
    clientPrice: React.ReactNode;
    allowance: React.ReactNode;
    monthSummary: React.ReactNode;
}

export function Grid(props: FormikProps<FormValues>) {
    const onBlurHandle = async ({ target }: any) => {
        const rowId = target.name.split('-')[1];
        const result = calculate(props.values, rowId);
        const unitPriceKey: keyof Partial<
            FormValues
        > = `unitPrice-${rowId}` as keyof Partial<FormValues>;
        const allowanceKey: keyof Partial<
            FormValues
        > = `allowance-${rowId}` as keyof Partial<FormValues>;
        const clientPriceKey: keyof Partial<
            FormValues
        > = `clientPrice-${rowId}` as keyof Partial<FormValues>;
        const summaryPriceKey: keyof Partial<
            FormValues
        > = `summaryPrice-${rowId}` as keyof Partial<FormValues>;

        const clientPriceResult = calculateClientPrice(
            props.values[unitPriceKey],
            props.values[allowanceKey],
        );
        await props.setFieldValue(target.name, Number(target.value));
        await props.setFieldValue(clientPriceKey, Number(clientPriceResult));
        await props.setFieldValue(summaryPriceKey, Number(result));
        await props.submitForm();
    };

    const data: Data[] = [
        {
            module: 'Dahboard, Obiekty, Zdarzenia',
            unitPrice: (
                <Input
                    type="number"
                    name="unitPrice-1"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['unitPrice-1']}
                />
            ),
            clientPrice: (
                <Input
                    type="number"
                    disabled
                    name="clientPrice-1"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['clientPrice-1']}
                />
            ),
            allowance: (
                <Input
                    type="number"
                    name="allowance-1"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['allowance-1']}
                />
            ),
            monthSummary: (
                <Input
                    type="number"
                    disabled
                    name="summaryPrice-1"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['summaryPrice-1']}
                />
            ),
        },
        {
            module: 'Ustawienia',
            unitPrice: (
                <Input
                    type="number"
                    name="unitPrice-2"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['unitPrice-2']}
                />
            ),
            clientPrice: (
                <Input
                    type="number"
                    disabled
                    name="clientPrice-2"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['clientPrice-2']}
                />
            ),
            allowance: (
                <Input
                    type="number"
                    name="allowance-2"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['allowance-2']}
                />
            ),
            monthSummary: (
                <Input
                    type="number"
                    disabled
                    name="summaryPrice-2"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['summaryPrice-2']}
                />
            ),
        },
        {
            module: 'Mapa',
            unitPrice: (
                <Input
                    type="number"
                    name="unitPrice-3"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['unitPrice-3']}
                />
            ),
            clientPrice: (
                <Input
                    type="number"
                    disabled
                    name="clientPrice-3"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['clientPrice-3']}
                />
            ),
            allowance: (
                <Input
                    type="number"
                    name="allowance-3"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['allowance-3']}
                />
            ),
            monthSummary: (
                <Input
                    type="number"
                    disabled
                    name="summaryPrice-3"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['summaryPrice-3']}
                />
            ),
        },
        {
            module: 'Analizy',
            unitPrice: (
                <Input
                    type="number"
                    name="unitPrice-4"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['unitPrice-4']}
                />
            ),
            clientPrice: (
                <Input
                    type="number"
                    disabled
                    name="clientPrice-4"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['clientPrice-4']}
                />
            ),
            allowance: (
                <Input
                    type="number"
                    name="allowance-4"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['allowance-4']}
                />
            ),
            monthSummary: (
                <Input
                    type="number"
                    disabled
                    name="summaryPrice-4"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['summaryPrice-4']}
                />
            ),
        },
        {
            module: 'Alarmy',
            unitPrice: (
                <Input
                    type="number"
                    name="unitPrice-5"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['unitPrice-5']}
                />
            ),
            clientPrice: (
                <Input
                    type="number"
                    disabled
                    name="clientPrice-5"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['clientPrice-5']}
                />
            ),
            allowance: (
                <Input
                    type="number"
                    name="allowance-5"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['allowance-5']}
                />
            ),
            monthSummary: (
                <Input
                    type="number"
                    disabled
                    name="summaryPrice-5"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['summaryPrice-5']}
                />
            ),
        },
        {
            module: 'Pogoda',
            unitPrice: (
                <Input
                    type="number"
                    name="unitPrice-6"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['unitPrice-6']}
                />
            ),
            clientPrice: (
                <Input
                    type="number"
                    disabled
                    name="clientPrice-6"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['clientPrice-6']}
                />
            ),
            allowance: (
                <Input
                    type="number"
                    name="allowance-6"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['allowance-6']}
                />
            ),
            monthSummary: (
                <Input
                    type="number"
                    disabled
                    name="summaryPrice-6"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['summaryPrice-6']}
                />
            ),
        },
        {
            module: 'Serwis',
            unitPrice: (
                <Input
                    type="number"
                    name="unitPrice-7"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['unitPrice-7']}
                />
            ),
            clientPrice: (
                <Input
                    type="number"
                    disabled
                    name="clientPrice-7"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['clientPrice-7']}
                />
            ),
            allowance: (
                <Input
                    type="number"
                    name="allowance-7"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['allowance-7']}
                />
            ),
            monthSummary: (
                <Input
                    type="number"
                    disabled
                    name="summaryPrice-7"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['summaryPrice-7']}
                />
            ),
        },
    ];

    return (
        <form onSubmit={props.handleSubmit}>
            <DataGrid
                headings={headings}
                data={data}
                keys={[
                    'module',
                    'unitPrice',
                    'clientPrice',
                    'allowance',
                    'monthSummary',
                ]}
            />
        </form>
    );
}
