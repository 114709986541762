import { User } from '../types';
import {
    closeForm,
    openAddUser,
    openEditUser,
    resetAddUserFormData,
} from '../actions/addEditform';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducer';
import { addFormIsOpened, editFormIsOpened } from '../selectors/users';

type UseAddEditForm = [
    boolean,
    boolean,
    (formData: Partial<User>) => void,
    () => void,
    () => void,
    () => void,
];

export function useAddEditForm(organizationId: number): UseAddEditForm {
    const dispatch = useDispatch();

    const addFormIsOpenedState = useSelector((state: AppState) =>
        addFormIsOpened(state, organizationId),
    );
    const editFormIsOpenedState = useSelector((state: AppState) =>
        editFormIsOpened(state, organizationId),
    );

    function clearFormData() {
        dispatch(resetAddUserFormData(organizationId));
    }

    function openEditForm(formData: Partial<User>) {
        dispatch(openEditUser(organizationId, formData));
    }

    function openAddForm() {
        dispatch(openAddUser(organizationId));
    }

    function close() {
        dispatch(closeForm(organizationId));
    }

    return [
        addFormIsOpenedState,
        editFormIsOpenedState,
        openEditForm,
        openAddForm,
        close,
        clearFormData,
    ];
}
