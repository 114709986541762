import React from 'react';
import { FormikProps } from 'formik';
import formatDate from 'react-day-picker/moment';

import { FormikSelect } from '../../../../../../../../tools/FormikFields';
import { DatePicker } from '../../../../../../../../components';
import { SelectOptions } from '../../../../../../../../types';
import { DatePickerBox, SelectBox } from '../../styled';
import { Flex } from '../../../../../../../../styled/';
import { DividerBox } from '../../../styled';

interface TimeTypeLicenseFieldsProps<T, ST> extends FormikProps<T> {
    dateFromKey: keyof T & string;
    dateToKey: keyof T & string;
    durationKey: keyof T & string;
    selectOptions: SelectOptions<ST>[];
}

export function TimeTypeLicenseFields<T, ST>(
    props: TimeTypeLicenseFieldsProps<T, ST>,
) {
    const dateFromValue: T[keyof T] & string = props.values[
        props.dateFromKey as keyof T
    ] as T[keyof T] & string;
    const dateToValue: T[keyof T] & string = props.values[
        props.dateToKey as keyof T & string
    ] as T[keyof T] & string;
    const durationValue: T[keyof T] & number = props.values[
        props.durationKey as keyof T
    ] as T[keyof T] & number;

    const calculateAndSetDateTo = (
        monthCount: number,
        dateFrom: T[keyof T] & string,
    ) => {
        const date = new Date(
            dateFrom
                .split('.')
                .reverse()
                .join('.'),
        );
        const dateTo = formatDate.formatDate(
            new Date(date.setMonth(date.getMonth() + monthCount)),
            'L',
            'pl',
        );

        props.setFieldValue(props.dateToKey, dateTo);
    };

    const setDuration = (option: SelectOptions<number>) => {
        calculateAndSetDateTo(option.value, dateFromValue);
        props.setFieldValue(props.durationKey, option.value);
    };

    const setDateFrom = (day: Date) => {
        const selectedDay = formatDate.formatDate(day, 'L', 'pl');
        calculateAndSetDateTo(durationValue, selectedDay);
        props.setFieldValue(props.dateFromKey, selectedDay);
    };

    const setDateTo = (day: Date) => {
        const selectedDay = formatDate.formatDate(day, 'L', 'pl');
        props.setFieldValue(props.dateToKey, selectedDay);
    };

    return (
        <>
            <SelectBox>
                <FormikSelect
                    name={props.durationKey}
                    value={
                        durationValue
                            ? props.selectOptions.filter(
                                  (option: any) =>
                                      option.value === durationValue,
                              )
                            : ''
                    }
                    options={props.selectOptions}
                    onChange={setDuration}
                    onBlur={props.handleBlur}
                    label="duration"
                />
            </SelectBox>
            <Flex flexDirection="row">
                <DatePickerBox>
                    <DatePicker
                        placeholder="Ważna od"
                        value={dateFromValue}
                        onDayChange={setDateFrom}
                    />
                </DatePickerBox>
                <DividerBox />
                <DatePickerBox>
                    <DatePicker
                        placeholder="Ważna do"
                        value={dateToValue}
                        onDayChange={setDateTo}
                    />
                </DatePickerBox>
            </Flex>
        </>
    );
}
