import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducer';
import { getRemovedParameters } from '../selectors/objects';

interface UseRemovedParametersOutput {
    parameters: string[];
    setRemovedParameters: (parameters: string[]) => void;
}

export function useRemovedParameters(): UseRemovedParametersOutput {
    const dispatch = useDispatch();
    const parameters = useSelector((state: AppState) =>
        getRemovedParameters(state),
    );
    function setRemovedParameters(parameters: string[]) {
        dispatch(setRemovedParameters(parameters));
    }

    return {
        parameters,
        setRemovedParameters,
    };
}
