import React from 'react';
import { ContentBox } from './styled';
import { Typography } from '@material-ui/core';
import { Flex } from '../../styled/';
import Button from '@material-ui/core/Button';

interface ApproveActionProps {
    heading?: string;
    desc?: string;
    approveOnClick: () => void;
    cancelOnClick: () => void;
    children?: JSX.Element;
}

export function ApproveAction(props: ApproveActionProps) {
    return (
        <>
            {props.heading ? (
                <ContentBox>
                    <Typography variant="h4">{props.heading}</Typography>
                </ContentBox>
            ) : null}
            <ContentBox>
                {props.desc ? <Typography>{props.desc}</Typography> : null}
                {props.children}
            </ContentBox>
            <Flex justifyContent="space-between">
                <Button
                    color="primary"
                    onClick={props.approveOnClick}
                    variant="contained"
                >
                    Potwierdź
                </Button>
                <Button onClick={props.cancelOnClick}>Anuluj</Button>
            </Flex>
        </>
    );
}
