import React from 'react';
import { CalculationMethodVariable } from '../../../../../../../../types/CalculationMethod';
import { FormikSelect } from '../../../../../../../../../../../tools/FormikFields';
import { SelectOption } from '../../../../../../../../../../../types/SelectOption';
import { ContentContainer } from './styled';
import { Label } from '../../../../../../../../../Users/components/TopPanel/components/Steps/StepFourth/styled';

interface VariableProps {
    variable: CalculationMethodVariable;
    onChangeHandle: (fieldName: string, option: SelectOption<number>) => void;
    selectData: SelectOption<string>[];
}

export function Variable(props: VariableProps) {
    function onChangeHandle(event: SelectOption) {
        props.onChangeHandle(props.variable.label, event);
    }

    return (
        <ContentContainer>
            <Label>{props.variable.label}</Label>
            <FormikSelect
                cacheOptions
                
                options={props.selectData}
                value={props.variable.value}
                defaultOptions
                onChange={onChangeHandle}
                placeholder="Konguracyjna zmienna"
            />
        </ContentContainer>
    );
}
