import React from 'react';
import moment from 'moment';

import { BottomNavBar } from '../../../../../../../components/ProgressStepper/components';
import { useOrganizationFormState } from '../../hooks/useOrganizationFormState';
import { AdditionalServices, ModulesTable, UserTable } from './components';
import { PayMethodForm } from './PayMethodForm/PayMethodForm';
import { OfferFom } from './OfferFom/OfferFom';
import { calculateSummary } from './tools';

interface BillingProps {
    prevStep: () => void;
    nextStep: () => void;
}

export function Billing(props: BillingProps) {
    const [state, setState, calValue] = useOrganizationFormState();
    const summaryPrice = calculateSummary(calValue);

    const monthDifferent = moment(
        state.license.dateTo.split('.').reverse(),
    ).diff(moment(state.license.dateFrom.split('.').reverse()), 'months', true);

    const wholePeriodPrice = summaryPrice * monthDifferent;

    const onNextStepHandle = () => {
        setState({
            summary: {
                monthly: {
                    price: summaryPrice,
                },
                annually: {
                    price: summaryPrice * 12,
                },
                wholePeriod: {
                    price: wholePeriodPrice,
                },
            },
        });
        props.nextStep();
    };

    return (
        <div>
            <h2>Metoda płatności</h2>
            <PayMethodForm />
            <h2>Użytkownicy</h2>
            <UserTable />
            <h2>Moduły</h2>
            <ModulesTable />
            <h2>Usługi dodatkowe</h2>
            <AdditionalServices />
            <h2>Promocja</h2>
            <OfferFom />
            <br />
            <BottomNavBar
                nextStep={onNextStepHandle}
                prevStep={props.prevStep}
                showNext={true}
                buttonNextText="Dalej"
            />
            <br />
        </div>
    );
}
