export function calculate(values: any, rowId: number) {
    const unitPriceKey = `unitPrice-${rowId}`;
    const allowanceKey = `allowance-${rowId}`;
    const licenseCountKey = `licenseCount-${rowId}`;

    return (
        (values[unitPriceKey] -
            values[unitPriceKey] * (values[allowanceKey] / 100)) *
        values[licenseCountKey]
    );
}
