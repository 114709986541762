import React, { useEffect } from 'react';

import { useOrganizationsState } from '../hooks/useOrganizationsState';
import { withManageMenu } from '../hocs/withManageMenu';
import { DataGrid } from '../../../components/DataGrid';
import { TopPanel } from './components/TopPanel';
import { Organization } from '../../../types';
import { ContentContainer } from './styled';
import { tableHeaders, tableKeys } from './constants';
import { Loader } from '../../../components/Loader';
import { LoaderContainer } from '../../../styled/LoaderContainer';
import { useGlobalAdmin } from '../../../hooks/useGlobalAdmin';

function Component() {
    const [, , allOrganizations, fetchOrganizations] = useOrganizationsState();
    const { isGlobalAdmin, redirectToOrganization } = useGlobalAdmin();

    useEffect(() => {
        if (!isGlobalAdmin) {
            redirectToOrganization();
        } else {
            fetchOrganizations();
        }
        // eslint-disable-next-line
    }, [isGlobalAdmin]);

    if (!allOrganizations.length) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );
    }

    const preparedOrganizations = allOrganizations.map(org => ({
        ...org,
        menuComponents: [
            {
                name: 'Otwórz szczególy',
                linkPath: `/organization/${org.id}/details`,
            },
        ],
    }));

    return (
        <ContentContainer>
            <TopPanel />
            <DataGrid<Organization>
                showMenu={true}
                headings={tableHeaders}
                data={preparedOrganizations}
                keys={tableKeys}
            />
        </ContentContainer>
    );
}

export const Organizations = withManageMenu(Component);
