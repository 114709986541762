import { Heading } from '../../../../../../../../../../components/DataGrid/types';

export const headings: Heading[] = [
    {
        key: 'module',
        heading: 'MODUŁY',
    },
    {
        key: 'unitPrice',
        heading: 'CENA JEDNOSTKOWA',
    },
    {
        key: 'clientPrice',
        heading: 'CENA KLIENTA',
    },
    {
        key: 'allowance',
        heading: 'RABAT %',
    },
    {
        key: 'monthSummary',
        heading: 'SUMA MIESIĘCZNIE',
    },
];
