import { Alarm } from '../types/Alarm';
import { AlarmsActionTypes } from '../model/actionTypes';

export function updateAlarmForm(formData: Alarm) {
    return {
        type: AlarmsActionTypes.SetForm,
        formData,
    };
}

export function resetAlarmForm() {
    return {
        type: AlarmsActionTypes.ResetForm,
    };
}
