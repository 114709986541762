import React from 'react';
import { DataGrid } from '../../../../../../../components/DataGrid';
import { headingKeys, headings } from './constants/headings';
import {
    BasicData as IBasicData,
    Localization,
} from '../../../../../Objects/types';
import { CELL_WIDTH } from '../../constants/cellWidth';

interface BasicDataProps {
    basicData: IBasicData;
    localization: Localization;
}

interface BasicDataSummary extends IBasicData {
    address: string;
    coordinates: string;
}

export function BasicData(props: BasicDataProps) {
    return (
        <>
            <h2>Dane podstawowe</h2>
            <DataGrid<BasicDataSummary>
                headings={headings}
                keys={headingKeys}
                cellWidth={CELL_WIDTH}
                data={[
                    {
                        ...props.basicData,
                        address: [
                            props.localization.address,
                            props.localization.postCode,
                            props.localization.town,
                        ].join(', '),
                        coordinates: [
                            props.localization.latitude,
                            props.localization.longitude,
                        ].join('@'),
                    },
                ]}
            />
        </>
    );
}
