export const tableHeaders = [
    {
        key: 'name',
        heading: 'Nazwa',
    },
    {
        key: 'nameShort',
        heading: 'Nazwa skrócona',
    },
    {
        key: 'postCode',
        heading: 'kod pocztowy',
    },
    {
        key: 'town',
        heading: 'Miasto',
    },
    {
        key: 'show',
        heading: 'Otwórz',
    },
];
