import { ParameterTypes, ParameterTypesNames } from '../../../../types/ParameterTypes';

export const PARAMETER_TYPES = [
    {
        label: ParameterTypesNames.figure,
        value: ParameterTypes.Figure,
    },
    {
        label: ParameterTypesNames.order,
        value: ParameterTypes.Order,
    },
    {
        label: ParameterTypesNames.calculated,
        value: ParameterTypes.Calculated,
    },
    {
        label: ParameterTypesNames.binary,
        value: ParameterTypes.Binary,
    },
    {
        label: ParameterTypesNames.time,
        value: ParameterTypes.Time,
    },
];
