import { Heading } from '../../../../components/DataGrid/types';

export const gridKeys = ['name', 'created_at', 'objectsCount'];

export const headings: Heading[] = [
    {
        key: 'name',
        heading: 'Nazwa',
    },
    {
        key: 'created_at',
        heading: 'Data stworzenie',
    },
    {
        key: 'objectsCount',
        heading: 'Ilość wykorzystań',
    },
];
