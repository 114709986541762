import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
    borderRadius: '7px',

    colors: {
        primary: {
            background: '#2c2c2c',
            border: '#999999',
        },
        secondary: {
            background: '#333333',
        },
        third: {
            background: '#d0d0d0',
            border: '#b3b3b3',
        },
        white: '#fff',
        green: '#b1e6b7',
        blue: 'blue',
        black: 'black',
    },
    text: {
        colors: {
            white: '#fff',
            black: '#000',
            grey: '#999999',
        },
    },
};
