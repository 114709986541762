import React from 'react';
import { Formik, FormikProps } from 'formik';

import { useOrganizationFormState } from '../../../hooks/useOrganizationFormState';
import { FormValues } from './types';
import { Fields } from './Fields';

interface PayMethodFormProps {}

export function PayMethodForm(props: PayMethodFormProps) {
    const onSubmitHandle = () => {};

    const [state] = useOrganizationFormState();

    return (
        <Formik
            onSubmit={onSubmitHandle}
            enableReinitialize={true}
            initialValues={{ paymentMethod: state.billing.paymentMethod }}
        >
            {(props: FormikProps<FormValues>) => <Fields {...props} />}
        </Formik>
    );
}
