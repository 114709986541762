import React, { useState } from 'react';
import { Row } from './components';
import { ObjectAtGroup } from '../../../../../../types/Group';
import { generateRandomString } from '../../../../../../../../../tools';
import { BottomPanel } from '../../../../../../../Templates/components/BottomPanel';

interface RowProps {
    objects: ObjectAtGroup[];
    setRows: (objects: ObjectAtGroup[]) => void;
    organizationId: number;
}

export function Rows(props: RowProps) {
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    function addNewRow() {
        props.setRows([
            ...props.objects,
            {
                tempId: generateRandomString(),
                value: 0,
                label: '',
            },
        ]);
    }

    function removeRow() {
        props.setRows(
            props.objects.filter(
                object => !selectedRows.includes(object.tempId),
            ),
        );
        setSelectedRows([]);
    }

    function setRowObject(object: ObjectAtGroup, option: any) {
        props.setRows(
            props.objects.map(objectFiltered => {
                if (objectFiltered.tempId === object.tempId) {
                    return {
                        ...objectFiltered,
                        ...option,
                    };
                }
                return objectFiltered;
            }),
        );
    }

    function selectRow(object: ObjectAtGroup) {
        if (selectedRows.includes(object.tempId)) {
            setSelectedRows(selectedRows.filter(row => row !== object.tempId));
            return;
        }
        setSelectedRows([...selectedRows, object.tempId]);
    }

    function renderRows() {
        if (!props.objects) {
            return null;
        }
        return props.objects.map((object, index) => (
            <Row
                organizationId={props.organizationId}
                setObject={setRowObject}
                selectRow={selectRow}
                isChecked={selectedRows.includes(object.tempId)}
                object={object}
                key={index}
            />
        ));
    }

    return (
        <>
            {renderRows()}
            <BottomPanel<null>
                actionHandleArg={null}
                primaryButtonActionHandle={addNewRow}
                primaryButtonText="Dodaj"
                secondButtonActionHandle={removeRow}
                secondButtonText="Usuń"
            />
        </>
    );
}
