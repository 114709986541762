import { FigureParameter } from '../../../../../types/FigureParameter';
import { ParameterTypes } from '../../../../../types/ParameterTypes';
import { isOnlyForReadingDefaultValue } from '../../../../../constants/isOnlyForReadingDefaultValue';

export const formInitValues: FigureParameter = {
    name: undefined,
    format: '',
    address: '',
    minRAW: 0,
    maxRAW: 0,
    minShowing: 0,
    maxShowing: 0,
    fullDescription: '',
    shortDescription: '',
    type: ParameterTypes.Figure,
    isAvailableForChart: false,
    unitRAW: undefined,
    unitShowing: undefined,
    countAfterAt: undefined,
    isOnlyForReading: isOnlyForReadingDefaultValue,
    isDefaultShowingAtCharts: false,
};
