import { useDispatch, useSelector } from 'react-redux';
import { getTemplates, removeTemplateById } from '../actions/templates';
import {
    fetchedTemplates,
    templatesHasFetched,
    templatesIsFetching,
} from '../selectors/templates';
import { AppState } from '../../../../reducer';
import { Template } from '../types/Template';

interface UseTemplatesProps {
    fetchTemplates: () => void;
    removeTemplate: (id: number) => void;
    isFetching: boolean;
    hasFetched: boolean;
    templates: Template[];
}

export function useTemplates(): UseTemplatesProps {
    const dispatch = useDispatch();
    const isFetching = useSelector((state: AppState) =>
        templatesIsFetching(state),
    );
    const hasFetched = useSelector((state: AppState) =>
        templatesHasFetched(state),
    );
    const templates = useSelector((state: AppState) => fetchedTemplates(state));

    function fetchTemplates() {
        dispatch(getTemplates());
    }

    async function removeTemplate(id: number) {
        await removeTemplateById(id);
        dispatch(getTemplates());
    }

    return {
        fetchTemplates,
        removeTemplate,
        isFetching,
        hasFetched,
        templates,
    };
}
