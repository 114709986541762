import styled, { css } from 'styled-components';
import { PointCircleProps } from '../types';

export const PointCircle = styled.div<PointCircleProps>`
    width: 15px;
    height: 15px;
    border-radius: 15px;
    text-align: center;
    line-height: 16px;
    font-size: 9px;
    ${props => getCircleColor(props.isActive, props.isApprove)};
    color: ${props =>
        props.isActive
            ? props.theme.colors.black
            : props.theme.colors.primary.border};
`;

function getCircleColor(isActive: boolean, isApprove: boolean) {
    if (isApprove) {
        return css`
            background-color: ${props => props.theme.colors.green};
        `;
    }

    if (isActive) {
        return css`
            border: 1px solid ${props => props.theme.colors.green};
        `;
    }

    return css`
        border: 1px solid ${props => props.theme.colors.primary.border};
    `;
}
