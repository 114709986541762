import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { ContentContainer } from './styled';

export function Loader() {
    return (
        <ContentContainer>
            <CircularProgress />
        </ContentContainer>
    );
}
