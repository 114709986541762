import React from 'react';
import { default as MaterialDrawer } from '@material-ui/core/Drawer';
import { useSelectedForm } from '../../hooks/useSelectedForm';
import { Parameter } from '../../types/ParameterTypes';
import { DrawerContentContainer } from '../../../../../components/Drawer/styled';

interface ParameterDrawerProps {
    isOpen: boolean;
    openAddParameter: () => void;
    closeAddParameter: () => void;
    children?: React.ReactNode;
    onSubmitHandle: (parameter: Parameter) => void;
}

export function ParameterDrawer(props: ParameterDrawerProps) {
    const Form = useSelectedForm();

    return (
        <MaterialDrawer
            anchor="right"
            open={props.isOpen}
            onClose={props.closeAddParameter}
        >
            <DrawerContentContainer size="normal">
                <Form
                    onCancel={props.closeAddParameter}
                    onSubmitHandle={props.onSubmitHandle}
                />
            </DrawerContentContainer>
            {props.children}
        </MaterialDrawer>
    );
}
