import { ProfileActionTypes } from '../constants/actionTypes/pofile';
import { Profile } from '../types';

export function setProfileData(data: Profile) {
    return {
        type: ProfileActionTypes.SetProfileData,
        data,
    };
}

export function removeProfileData() {
    return {
        type: ProfileActionTypes.RemoveProfileData,
    };
}
