import React from 'react';
import { Parameter } from '../../../../../../../Parameters/types/ParameterTypes';
import { MapParameter } from '../../../../../../../Objects/types/MapParameter';
import Checkbox from '@material-ui/core/Checkbox';
import { SelectBox } from '../../../../../Variables/components/Form/component/Row/styled';
import { FormikSelect } from '../../../../../../../../../tools/FormikFields';
import { BinaryParameter } from '../../../../../../../Parameters/types/BinaryParameter';
import { getTempIdOrId } from '../../../../../tools/getTempIdOrId';
import { RowWithError } from '../../../../../../../Objects/components/TopPanel/styled';

interface RowProps {
    parameters: Parameter[];
    item: MapParameter;
    isChecked: boolean;
    selectRow: (id: string) => void;
    setParameter: (item: MapParameter, parameter: Parameter) => void;
    hasError: boolean;
}

export function Row(props: RowProps) {
    function onCheckHandle() {
        props.selectRow(props.item.id);
    }

    function setOption(option: Parameter) {
        props.setParameter(props.item, option);
    }

    return (
        <RowWithError hasError={props.hasError}>
            <Checkbox
                checked={props.isChecked}
                onChange={onCheckHandle}
                value="checkedB"
                color="primary"
            />
            <SelectBox>
                <FormikSelect
                    name="parameter"
                    cacheOptions
                    options={props.parameters}
                    value={props.item.parameter}
                    getOptionLabel={(opt: BinaryParameter) => opt.name!.label}
                    getOptionValue={(opt: BinaryParameter) =>
                        getTempIdOrId(opt)
                    }
                    onChange={setOption}
                    placeholder="Parameter"
                />
            </SelectBox>
        </RowWithError>
    );
}
