import React from 'react';
import {
    Binary,
    Calculated,
    Figure,
    Order,
    Time,
} from '../components/TopPanel/forms';
import { useSelector } from 'react-redux';
import { getFormData } from '../selectors/parameters';
import { AppState } from '../../../../reducer';
import { Parameter, ParameterTypes } from '../types/ParameterTypes';

export function useSelectedForm(): React.ElementType {
    const { type }: Partial<Parameter> = useSelector((state: AppState) =>
        getFormData(state),
    );

    switch (type) {
        case ParameterTypes.Binary:
            return Binary;
        case ParameterTypes.Calculated:
            return Calculated;
        case ParameterTypes.Figure:
            return Figure;
        case ParameterTypes.Order:
            return Order;
        case ParameterTypes.Time:
            return Time;
        default:
            return Figure;
    }
}
