import React from 'react';
import { Group } from '../../types/Group';
import { TableCell } from '@material-ui/core';
import { GroupLabel } from './styled/GroupLabel';
import { RowMenu } from '../../../../../components/DataGrid/components/RowMenu';
import { IMenuItem } from '../../../../../components/DataGrid/components/RowMenu/RowMenu';

interface GridBodyItemProps {
    group: Group;
    menuComponents: IMenuItem[];
}

export function GridBodyItem(props: GridBodyItemProps) {
    return (
        <>
            <TableCell>
                <GroupLabel>{props.group.label}</GroupLabel>
                <ul>
                    {props.group.objects.map((object, index) => (
                        <li key={index}>{object.label}</li>
                    ))}
                </ul>
            </TableCell>
            <TableCell>{props.group.createdAt}</TableCell>
            <TableCell>{props.group.createdBy}</TableCell>
            <RowMenu menuComponents={props.menuComponents} />
        </>
    );
}
