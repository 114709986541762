import React, { useContext } from 'react';
import { BottomNavBar } from '../../../../../components/ProgressStepper/components';
import { SettingsData } from '../Summary/components/SettingsData';
import { Parameters } from '../Summary/components/Parameters';
import { Alarms } from '../Summary/components/Alarms';
import { StepProps } from '../../types/StepProps';
import { Form } from '../Summary/Form';
import { useObjectForm } from '../../../Objects/hooks';
import { NotificationHandlersContext } from '../../../../../containers/App/components/AppWithMenu/AppWithMenu';
import { useAddTemplate } from '../../hooks/useAddTemplate';
import { useTemplates } from '../../hooks/useTemplates';

interface SummaryAddTemplateProps extends StepProps {
    closeDrawer: () => void;
}

export function SummaryAddTemplate(props: SummaryAddTemplateProps) {
    const [state, , resetForm] = useObjectForm();
    const notifyHandlers = useContext(NotificationHandlersContext);
    const {
        add,
        addingError,
        hasAdded,
        resetAddTemplateStore,
    } = useAddTemplate();
    const { fetchTemplates } = useTemplates();

    function addHandle() {
        if (!state.summary.templateName) {
            notifyHandlers.showError('Należy wprowadzić nazwe nowego szablonu');
            return;
        }
        add({
            settings: state.settings,
            parameters: state.parameters,
            parametersAppearance: state.parametersAppearance,
            statusAppearance: state.statusAppearance,
            engineSymbol: state.engineSymbol,
            mapParameters: state.mapParameters,
            alarms: state.alarms,
            summary: state.summary,
        });
    }

    if (hasAdded) {
        props.closeDrawer();
        resetForm();
        resetAddTemplateStore();
        fetchTemplates();
        notifyHandlers.showSuccess('Szablon został dodany');
    }

    if (addingError) {
        notifyHandlers.showError(addingError);
    }

    return (
        <>
            <Form />
            <SettingsData settings={state.settings} />
            <Parameters parameter={state.parameters} />
            <Alarms alarms={state.alarms} />
            <BottomNavBar
                prevStep={props.prevStep}
                nextStep={addHandle}
                showBack={true}
                buttonNextText="Dodaj"
            />
        </>
    );
}
