import React from 'react';
import { FormikProps } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { OfferFormValues } from './types';
import { OfferFields } from './components';

export function Fields(props: FormikProps<OfferFormValues>) {
    const offerChangeBox = () => {
        props.setFieldValue('isOffer', !props.values.isOffer);
    };

    return (
        <form onSubmit={props.handleSubmit}>
            <FormControlLabel
                control={
                    <Checkbox
                        name="isOffer"
                        checked={props.values.isOffer}
                        onChange={offerChangeBox}
                        value="checkedB"
                        color="primary"
                    />
                }
                label="Promocja"
            />
            {props.values.isOffer ? (
                <OfferFields
                    {...props}
                    durationKey="offerDuration"
                    dateFromKey="dateFrom"
                    dateToKey="dateTo"
                />
            ) : null}
        </form>
    );
}
