import { AppState } from '../reducer';
import { LocationState } from '../reducer/location';

function getLocationState(appState: AppState): LocationState {
    return appState.location;
}

export function countiesIsFetching(appState: AppState) {
    return getLocationState(appState).countyIsFetching;
}

export function countiesHasFetched(appState: AppState) {
    return getLocationState(appState).countyHasFetched;
}

export function counties(appState: AppState) {
    return getLocationState(appState).counties;
}

export function communitiesIsFetching(appState: AppState) {
    return getLocationState(appState).communityIsFetching;
}

export function communitiesHasFetched(appState: AppState) {
    return getLocationState(appState).communityHasFetched;
}

export function communities(appState: AppState) {
    return getLocationState(appState).communities;
}

export function voivodeships(appState: AppState) {
    return getLocationState(appState).voivodeships;
}

export function voivodeshipsIsFetching(appState: AppState) {
    return getLocationState(appState).voivodeshipIsFetching;
}

export function voivodeshipsHasFetched(appState: AppState) {
    return getLocationState(appState).voivodeshipHasFetched;
}
