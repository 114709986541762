import React, { useState } from 'react';
import { Map as Leaflet, Marker, Popup, TileLayer } from 'react-leaflet';

interface MapProps {
    onClickHandle: (lat: number, lng: number) => void;
}

export function Map(props: MapProps) {
    const localization: [number, number] = [51.8948782, 19.7105589];
    const [marker, setMarker] = useState(localization);

    const onClickHandle = ({
        latlng,
    }: {
        latlng: { lat: number; lng: number };
    }) => {
        setMarker([latlng.lat, latlng.lng]);
        props.onClickHandle(latlng.lat, latlng.lng);
    };

    return (
        <Leaflet onClick={onClickHandle} center={localization} zoom={6}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={marker}>
                <Popup>
                    Zaznaczyłeś:
                    <div>
                        <b>Latitude: </b>
                        {marker[0]}
                    </div>
                    <div>
                        <b>Longitudes: </b>
                        {marker[1]}
                    </div>
                </Popup>
            </Marker>
        </Leaflet>
    );
}
