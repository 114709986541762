import { Template } from '../types/Template';

export enum TemplatesActionTypes {
    FetchTemplates = '[Templates] fetch templates',
    FetchTemplatesSuccess = '[Templates] fetch templates success',
    FetchTemplatesFailed = '[Templates] fetch templates failed',

    AddTemplate = '[Templates] add template',
    AddTemplateSuccess = '[Templates] add template success',
    AddTemplateFailed = '[Templates] add template failed',
    ResetAddTemplateState = '[Templates] reset add template state',
}

export interface ResetAddTemplateState {
    type: typeof TemplatesActionTypes.ResetAddTemplateState;
}

export interface FetchTemplates {
    type: typeof TemplatesActionTypes.FetchTemplates;
}

export interface FetchTemplatesSuccess {
    type: typeof TemplatesActionTypes.FetchTemplatesSuccess;
    templates: Template[];
}

export interface FetchTemplatesFailed {
    type: typeof TemplatesActionTypes.FetchTemplatesFailed;
}

export interface AddTemplate {
    type: typeof TemplatesActionTypes.AddTemplate;
}

export interface AddTemplateSuccess {
    type: typeof TemplatesActionTypes.AddTemplateSuccess;
}

export interface AddTemplateFailed {
    type: typeof TemplatesActionTypes.AddTemplateFailed;
    error: string;
}

export type TemplatesUnion =
    | FetchTemplates
    | FetchTemplatesSuccess
    | FetchTemplatesFailed
    | ResetAddTemplateState
    | AddTemplate
    | AddTemplateSuccess
    | AddTemplateFailed;
