import { DropResult } from 'react-beautiful-dnd';
import { Column } from '../views/Organization/Templates/components/Appearances/components/Column';
import React from 'react';
import {
    AppearanceBase,
    BoxAppearance as IBox,
} from '../views/Organization/Objects/types/AppearanceBase';

interface UseDndOutput {
    onDragEnd: (result: DropResult) => void;
    renderColumns: () => JSX.Element[];
}

export function useDnd(
    config: AppearanceBase,
    updateConfig: (newState: AppearanceBase) => void,
    editBox: (boxId: string) => void,
): UseDndOutput {
    function onDragEnd(result: DropResult) {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const start = config.columns[source.droppableId];
        const finish = config.columns[destination.droppableId];

        if (start === finish) {
            const column = config.columns[source.droppableId];
            const newBox = Array.from(column.boxIds);
            newBox.splice(source.index, 1);
            newBox.splice(destination.index, 0, draggableId);
            const updatedColumn = {
                ...column,
                boxIds: newBox,
            };

            const newState = {
                ...config,
                columns: {
                    ...config.columns,
                    [updatedColumn.id]: updatedColumn,
                },
            };

            updateConfig(newState);
            return;
        }

        const startBoxIds = Array.from(start.boxIds);
        startBoxIds.splice(source.index, 1);
        const newStart = {
            ...start,
            boxIds: startBoxIds,
        };

        const finishBoxIds = Array.from(finish.boxIds);
        finishBoxIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            boxIds: finishBoxIds,
        };

        const newState = {
            ...config,
            columns: {
                ...config.columns,
                [newFinish.id]: newFinish,
                [newStart.id]: newStart,
            },
        };

        updateConfig(newState);
    }

    function renderColumns() {
        return config.columnOrder.map(column => {
            const boxes: IBox[] = [];
            const foundColumn = config.columns[column];
            foundColumn.boxIds.forEach(boxId => boxes.push(config.box[boxId]));
            return (
                <Column
                    editHandle={editBox}
                    boxes={boxes}
                    key={column}
                    column={foundColumn}
                />
            );
        });
    }

    return {
        onDragEnd,
        renderColumns,
    };
}
