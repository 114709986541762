import React from 'react';
import { Formik, FormikProps } from 'formik';
import { Fields } from './Fields';
import { PhotoGalleryForm } from '../../../../../Objects/types/PhotoGalleryForm';
import { useObjectForm } from '../../../../../Objects/hooks';

interface FormProps {
    prevStep: () => void;
    nextStep: () => void;
    galleries: PhotoGalleryForm[];
    selectedGalleries: string[];
    checkGallery: (galleryName: string) => void;
    removeGalleries: () => void;
    addNewGallery: (formData: PhotoGalleryForm[]) => void;
}

export function Form(props: FormProps) {
    const [state, setData] = useObjectForm();

    function onSubmitHandle(photoGalleries: PhotoGalleryForm[]) {
        setData({ ...state, photoGalleries });
        props.nextStep();
    }

    return (
        <Formik
            initialValues={props.galleries}
            enableReinitialize={true}
            onSubmit={onSubmitHandle}
            render={(otherProps: FormikProps<PhotoGalleryForm[]>) => (
                <Fields
                    addNewGallery={props.addNewGallery}
                    removeGalleries={props.removeGalleries}
                    prevStep={props.prevStep}
                    {...otherProps}
                    checkedList={props.selectedGalleries}
                    checkHandle={props.checkGallery}
                />
            )}
        />
    );
}
