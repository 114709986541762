import formatDate from 'react-day-picker/moment';

import { modules } from '../../Organizations/components/TopPanel/steps/License/Form/constants';
import { FormState } from '../../Organizations/types';

const date = new Date();

export const addFormInitState: FormState = {
    billing: {
        isOffer: false,
        offerValue: 0,
        offerDuration: 6,
        dateFrom: formatDate.formatDate(new Date(), 'L', 'pl'),
        dateTo: formatDate.formatDate(
            new Date(date.setMonth(date.getMonth() + 6)),
            'L',
            'pl',
        ),
        paymentMethod: 'monthly',
        users: {
            'unitPrice-1': 50,
            'clientPrice-1': 50,
            'allowance-1': 0,
            'licenseCount-1': 0,
            'monthSummary-1': 0,

            'unitPrice-2': 50,
            'clientPrice-2': 50,
            'allowance-2': 0,
            'licenseCount-2': 0,
            'monthSummary-2': 0,
        },
        modules: {
            'unitPrice-1': 99,
            'clientPrice-1': 99,
            'allowance-1': 0,
            'summaryPrice-1': 99,

            'unitPrice-2': 0,
            'clientPrice-2': 0,
            'allowance-2': 0,
            'summaryPrice-2': 0,

            'unitPrice-3': 49,
            'clientPrice-3': 49,
            'allowance-3': 0,
            'summaryPrice-3': 49,

            'unitPrice-4': 79,
            'clientPrice-4': 79,
            'allowance-4': 0,
            'summaryPrice-4': 79,

            'unitPrice-5': 39,
            'clientPrice-5': 39,
            'allowance-5': 0,
            'summaryPrice-5': 39,

            'unitPrice-6': 39,
            'clientPrice-6': 39,
            'allowance-6': 0,
            'summaryPrice-6': 39,

            'unitPrice-7': 89,
            'clientPrice-7': 89,
            'allowance-7': 0,
            'summaryPrice-7': 89,
        },
        additionalServices: {
            serviceCount: 1,
            servicePrice: 100,
            serviceSummary: 100,
        },
    },
    license: {
        userCounts: 1,
        objectsCounts: 1,
        modules: modules.filter(module => module.isFixed).map(mod => mod.value),
        licenseType: 1,
        licenseDuration: 6,
        dateFrom: formatDate.formatDate(new Date(), 'L', 'pl'),
        dateTo: formatDate.formatDate(
            new Date(date.setMonth(date.getMonth() + 6)),
            'L',
            'pl',
        ),
        smsPackage: 0,
    },
    basic: {
        name: '',
        shortName: '',
        address: '',
        addressTwo: '',
        postCode: '',
        province: '',
        town: '',
        country: 'pl',
        nip: '',
    },
    majorContact: {
        name: '',
        surname: '',
        email: '',
        phoneNumber: '',
    },
    accountantContact: {
        name: '',
        surname: '',
        email: '',
        phoneNumber: '',
    },
    technicalContact: {
        name: '',
        surname: '',
        email: '',
        phoneNumber: '',
    },
    summary: {
        monthly: {
            price: 0,
        },
        annually: {
            price: 0,
        },
        wholePeriod: {
            price: 0,
        },
    },
};
