import { ParameterTypes } from '../../../../../types/ParameterTypes';
import { BinaryParameter } from '../../../../../types/BinaryParameter';
import { isOnlyForReadingDefaultValue } from '../../../../../constants/isOnlyForReadingDefaultValue';
import { BIT_NUMBER_DATA } from './bitNumberSelectData';

export const formInitValues: BinaryParameter = {
    name: undefined,
    address: '',
    bitNumber: BIT_NUMBER_DATA[0],
    fullDescription: '',
    shortDescription: '',
    type: ParameterTypes.Binary,
    isAvailableForChart: false,
    isDefaultShowingAtCharts: false,
    presentationMode: undefined,
    isOnlyForReading: isOnlyForReadingDefaultValue,
};
