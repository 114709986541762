import React from 'react';
import { Form } from './Form/Form';

interface LicenseProps {
    prevStep: () => void;
    nextStep: () => void;
}

export function License(props: LicenseProps) {
    return (
        <div>
            <Form
                nextStepHandle={props.nextStep}
                prevStepHandle={props.prevStep}
            />
        </div>
    );
}
