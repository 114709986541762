import React, { useState } from 'react';
import { default as MaterialDrawer } from '@material-ui/core/Drawer';

import { DrawerContentContainer, ProgressStepperContainer } from './styled';
import { ApproveAction, ModalBody, ProgressStepper, Step } from '..';
import { DrawerSizes } from './types';
import { Modal } from '@material-ui/core';

interface DrawerProps {
    children?: React.ReactNode;
    hasProgressStepper: boolean;
    drawerIsVisible: boolean;
    setDrawerHide: () => void;
    data?: Step[] | any;
    size?: DrawerSizes;
    isObjectEdit?: boolean;
    isAddTemplate?: boolean;
    approveClose: boolean;
}

export function Drawer(props: DrawerProps) {
    const [modalIsVisible, setModalIsVisible] = useState();
    const closeDrawer = () => props.setDrawerHide();

    const renderProgress = () => (
        <ProgressStepperContainer>
            <ProgressStepper
                isObjectEdit={props.isObjectEdit}
                isAddTemplate={props.isAddTemplate}
                closeDrawer={closeDrawer}
                steps={props.data}
            />
        </ProgressStepperContainer>
    );

    function openApproveModal() {
        setModalIsVisible(true);
    }

    function closeApproveModal() {
        setModalIsVisible(false);
    }

    function approveHandle() {
        closeDrawer();
        setModalIsVisible(false);
    }

    return (
        <>
            <MaterialDrawer
                anchor="right"
                open={props.drawerIsVisible}
                onClose={props.approveClose ? openApproveModal : closeDrawer}
            >
                <DrawerContentContainer size={props.size || 'normal'}>
                    {props.hasProgressStepper
                        ? renderProgress()
                        : props.children}
                </DrawerContentContainer>
            </MaterialDrawer>
            {props.approveClose ? (
                <Modal open={modalIsVisible} onClose={closeApproveModal}>
                    <ModalBody closeModalOnCLick={closeApproveModal}>
                        <ApproveAction
                            desc="Czy napewno chcesz zamknąć?"
                            approveOnClick={approveHandle}
                            cancelOnClick={closeApproveModal}
                        />
                    </ModalBody>
                </Modal>
            ) : null}
        </>
    );
}
