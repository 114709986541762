import { SelectOptions } from '../../../../../../../../../types';

export const modules: SelectOptions<number>[] = [
    {
        label: 'Dashboard',
        value: 1,
        isFixed: true,
    },
    {
        label: 'Obiekty',
        value: 2,
        isFixed: true,
    },
    {
        label: 'Zdarzenia',
        value: 3,
        isFixed: true,
    },
    {
        label: 'Ustawienia',
        value: 4,
        isFixed: true,
    },
    {
        label: 'Analizy',
        value: 5,
        isFixed: false,
    },
    {
        label: 'Alarmy',
        value: 6,
        isFixed: false,
    },
    {
        label: 'Pogoda',
        value: 7,
        isFixed: false,
    },
    {
        label: 'Serwis',
        value: 8,
        isFixed: false,
    },
];

export const licenseType: SelectOptions<number>[] = [
    {
        label: 'Czasowa',
        value: 1,
    },
    {
        label: 'Nieograniczona',
        value: 2,
    },
];

export const licenseDuration: SelectOptions<number>[] = [
    {
        label: '6 m-c',
        value: 6,
    },
    {
        label: '12 m-c',
        value: 12,
    },
    {
        label: '18 m-c',
        value: 18,
    },
    {
        label: '24 m-c',
        value: 24,
    },
    {
        label: '36 m-c',
        value: 36,
    },
    {
        label: '48 m-c',
        value: 48,
    },
    {
        label: '72 m-c',
        value: 72,
    },
];
