import styled from 'styled-components';

export const ContentContainer = styled.div`
    width: 100%;
    text-align: center;
    padding: 50px 0;
    color: ${props => props.theme.text.colors.grey};

    span {
        font-style: italic;
    }
`;
