import React from 'react';

import { ClickableBox, SelectBox } from './styled';
import Checkbox from '@material-ui/core/Checkbox';
import {
    FormikCreteableSelect,
    FormikSelect,
    FormikTextField,
} from '../../../../../../../../../tools/FormikFields';
import { PARAMETER_TYPES } from '../../../../../../../Parameters/components/TopPanel/forms/selectData/selectData';
import EditIcon from '@material-ui/icons/Edit';
import { Parameter, ParameterTypesNames } from '../../../../../../../Parameters/types/ParameterTypes';
import { getTempIdOrId } from '../../../../../tools/getTempIdOrId';
import { getInitDataByType } from '../../../../../../../Parameters/tools/getInitDataByType';
import { RowWithError } from '../../../../../../../Objects/components/TopPanel/styled';
import { SelectOption } from '../../../../../../../../../types/SelectOption';

interface RowProps {
    parameter: Parameter;
    onRowCheck: (parameter: Parameter) => void;
    isChecked: boolean;
    setFormValue: (values: any) => any;
    formValues: Parameter[];
    variables: SelectOption<string>[];
    editVariable: (variable: Parameter) => void;
    hasError: boolean;
}

export function Row(props: RowProps) {
    function onCheckHandle() {
        props.onRowCheck(props.parameter);
    }

    function setSelectType({ value }: any) {
        props.setFormValue(
            props.formValues.map(filteredValue => {
                if (
                    getTempIdOrId(filteredValue) ===
                    getTempIdOrId(props.parameter)
                ) {
                    return {
                        ...getInitDataByType(value),
                        type: value,
                        name: filteredValue.name,
                        id: filteredValue.id,
                        tempId: filteredValue.tempId,
                        fullDescription: filteredValue.fullDescription,
                        shortDescription: filteredValue.shortDescription,
                        address: filteredValue.address,
                    };
                }

                return filteredValue;
            }),
        );
    }

    function setSelectName(option: any) {
        props.setFormValue(
            props.formValues.map(filteredValue => {
                if (
                    getTempIdOrId(filteredValue) ===
                        getTempIdOrId(props.parameter) &&
                    option.hasOwnProperty('__isNew__')
                ) {
                    return {
                        ...filteredValue,
                        name: option,
                        address: option.hasOwnProperty('__isNew__')
                            ? ''
                            : option.defaultRegister,
                    };
                }

                if (
                    getTempIdOrId(filteredValue) ===
                    getTempIdOrId(props.parameter)
                ) {
                    return {
                        ...option.meta,
                    };
                }

                return filteredValue;
            }),
        );
    }

    function setAddress(value: any) {
        props.setFormValue(
            props.formValues.map(filteredValue => {
                if (
                    getTempIdOrId(filteredValue) ===
                    getTempIdOrId(props.parameter)
                ) {
                    return {
                        ...filteredValue,
                        address: value.target.value,
                    };
                }

                return filteredValue;
            }),
        );
    }

    function editRow() {
        props.editVariable(props.parameter);
    }

    return (
        <RowWithError hasError={props.hasError}>
            <ClickableBox>
                <Checkbox
                    checked={props.isChecked}
                    onChange={onCheckHandle}
                    value="checkedB"
                    color="primary"
                />
            </ClickableBox>
            <SelectBox>
                <FormikSelect
                    cacheOptions
                    value={{
                        value: props.parameter.type,
                        label: ParameterTypesNames[props.parameter.type],
                    }}
                    options={PARAMETER_TYPES}
                    onChange={setSelectType}
                    placeholder="Typ parametru"
                />
            </SelectBox>
            <SelectBox>
                <FormikCreteableSelect
                    options={props.variables}
                    defaultOptions
                    value={props.parameter.name}
                    onChange={setSelectName}
                    placeholder="Nazwa zmiennej"
                />
            </SelectBox>
            <SelectBox>
                <FormikTextField
                    variant="outlined"
                    value={props.parameter.address}
                    onChange={setAddress}
                    placeholder="Adres"
                />
            </SelectBox>
            <ClickableBox onClick={editRow}>
                <EditIcon />
            </ClickableBox>
        </RowWithError>
    );
}
