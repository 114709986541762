import { Alarm } from '../types/Alarm';
import { AlarmsActionTypes, AlarmsUnion } from '../model/actionTypes';

export interface AlarmsReducerState {
    alarms: Alarm[];
    alarmsHasFetched: boolean;
    alarmsFetching: boolean;
    formData: Partial<Alarm>;
    addAlarmsPending: boolean;
}

const initState: AlarmsReducerState = {
    alarms: [],
    alarmsHasFetched: false,
    alarmsFetching: false,
    formData: {},
    addAlarmsPending: false,
};

export function alarmReducer(
    state: AlarmsReducerState = initState,
    action: AlarmsUnion,
): AlarmsReducerState {
    switch (action.type) {
        case AlarmsActionTypes.FetchAlarms: {
            return {
                ...state,
                alarmsFetching: true,
            };
        }

        case AlarmsActionTypes.FetchAlarmsSuccess: {
            return {
                ...state,
                alarmsFetching: false,
                alarms: action.alarms,
                alarmsHasFetched: true,
            };
        }

        case AlarmsActionTypes.FetchAlarmsFailed: {
            return {
                ...state,
                alarmsHasFetched: false,
                alarmsFetching: false,
            };
        }

        case AlarmsActionTypes.ResetForm: {
            return {
                ...state,
                formData: {},
            };
        }

        case AlarmsActionTypes.SetForm: {
            return {
                ...state,
                formData: action.formData,
            };
        }

        default:
            return state;
    }
}
