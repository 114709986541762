import { UnitsActionTypes } from '../constants/actionTypes/units';
import { Unit } from '../types';
import { request } from '../tools';
import { parameterUnits } from '../api/api';
import { Dispatch } from 'redux';

export function getUnits() {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UnitsActionTypes.FetchUnits,
        });

        try {
            const units: Unit[] = await request<Unit[]>('GET', parameterUnits);
            dispatch(getUnitsSuccess(units));
        } catch (error) {}
    };
}

function getUnitsSuccess(units: Unit[]) {
    return {
        type: UnitsActionTypes.FetchUnitsSuccess,
        units,
    };
}
