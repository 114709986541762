import React from 'react';
import { Formik, FormikProps } from 'formik';
import { FormValues } from './types';
import { Fields } from './Fields';
import { useOrganizationFormState } from '../../../hooks/useOrganizationFormState';
import { BasicDataScheme } from './constants';

interface FormProps {
    nextStepHandle: () => void;
}

export function Form(props: FormProps) {
    const [state, setData] = useOrganizationFormState();

    const onSubmit = (formData: FormValues) => {
        setData({ basic: formData });
        props.nextStepHandle();
    };

    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={BasicDataScheme}
            initialValues={{
                name: state.basic.name,
                shortName: state.basic.shortName,
                address: state.basic.address,
                addressTwo: state.basic.addressTwo,
                postCode: state.basic.postCode,
                town: state.basic.town,
                country: state.basic.country,
                nip: state.basic.nip,
                province: state.basic.province,
            }}
        >
            {(otherProps: FormikProps<FormValues>) => (
                <Fields {...otherProps} />
            )}
        </Formik>
    );
}
