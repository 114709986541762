import React from 'react';
import { Form } from './components';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface AddEditGroupProps extends RouteComponentProps<{ id: string }> {
    closeDrawer: () => void;
    isEdit?: boolean;
}

function Component(props: AddEditGroupProps) {
    const { id } = props.match.params;
    return (
        <Form
            isEdit={props.isEdit || false}
            organizationId={Number(id)}
            closeDrawer={props.closeDrawer}
        />
    );
}

export const AddEditGroup = withRouter(Component);
