import React, { useState } from 'react';
import { Input } from '../../../../../Input';
import { SearchFieldContainer } from './styled';

interface SearchFieldProps {
    searchHandle: (searchedString: string) => void;
}

export function SearchField(props: SearchFieldProps) {
    const [searchValue, setSearchValue] = useState('');
    const onChangeHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
        if (value.target.value.length > 2) {
            const { value: fieldValue } = value.target;
            setTimeout(() => {
                props.searchHandle(fieldValue);
            }, 500);
        }

        setSearchValue(value.target.value);
    };

    return (
        <SearchFieldContainer>
            <Input
                value={searchValue}
                onChange={onChangeHandler}
                id="customer-search"
                name="customer-search"
                label="Wyszukaj klienta"
            />
        </SearchFieldContainer>
    );
}
