import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { useUserData } from '../../../../../../hooks/useUserData';
import { Drawer } from '../../../../../../../../../components';
import { steps } from '../../constants/config';
import { User } from '../../../../../../types';
import { Fields } from './Fields';
import { AddUserWithPermissions } from '../../../../../../types/AddUserWithPermissions';
import * as Yup from 'yup';
import { useAddEditForm } from '../../../../../../hooks/useAddEditForm';

interface FormProps extends RouteComponentProps<{ id: string }> {
    nextStepHandle: () => void;
}

function FormComponent(props: FormProps) {
    const [addOrganizationIsVisible, setAddOrganizationIsVisible] = useState(
        false,
    );
    const openAddOrganization = () => setAddOrganizationIsVisible(true);
    const { id } = props.match.params;
    const organizationId = Number(id);
    const [, editFormIsOpenedState] = useAddEditForm(organizationId);
    const { updateAddUserForm, userFormData } = useUserData(organizationId);

    const validationSchema = Yup.object().shape({
        password: editFormIsOpenedState
            ? Yup.string()
                  .label('Password')
                  .min(8, 'Minimum 8 znaków')
            : Yup.string()
                  .label('Password')
                  .required()
                  .min(8, 'Minimum 8 znaków'),
    });

    function onSubmit(values: Partial<User>) {
        updateAddUserForm(values);
        props.nextStepHandle();
    }

    function closeDrawer() {
        setAddOrganizationIsVisible(false);
    }

    return (
        <>
            <Formik
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                render={(props: FormikProps<AddUserWithPermissions>) => (
                    <Fields
                        {...props}
                        openAddOrganization={openAddOrganization}
                    />
                )}
                initialValues={{
                    ...userFormData,
                    organizationId,
                }}
            />
            <Drawer
                approveClose={false}
                data={steps}
                hasProgressStepper={true}
                drawerIsVisible={addOrganizationIsVisible}
                setDrawerHide={closeDrawer}
            />
        </>
    );
}

export const Form = withRouter(FormComponent);
