import React, { useImperativeHandle } from 'react';
import { FormikProps } from 'formik';

import { PhoneInput } from '../../../../../../../../components';
import { onSubmitFormik } from './types/OnSubmitFormik';
import { FormContainer } from './styled';
import { WideField } from '../../styled';
import { FormValues } from './types';

function Component(
    props: FormikProps<FormValues>,
    ref: React.Ref<onSubmitFormik>,
) {
    useImperativeHandle(ref, () => ({
        onSubmit: props.submitForm,
    }));

    function setPhoneNumber(value: string) {
        props.setFieldValue('phoneNumber', value);
    }

    return (
        <FormContainer onSubmit={props.handleSubmit}>
            <WideField
                name="name"
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.name && props.touched.name}
                label="Imię"
                variant="outlined"
            />
            <WideField
                name="surname"
                value={props.values.surname}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.surname && props.touched.surname}
                label="Nazwisko"
                variant="outlined"
            />
            <WideField
                name="email"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.email && props.touched.email}
                label="E-mail"
                variant="outlined"
            />
            <PhoneInput
                inputExtraProps={{
                    name: 'phoneNumber',
                    required: true,
                    autoFocus: true,
                }}
                defaultCountry="pl"
                value={props.values.phoneNumber}
                onChange={setPhoneNumber}
            />
        </FormContainer>
    );
}

export const Fields = React.forwardRef(Component);
