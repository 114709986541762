import React from 'react';

import { DataGrid } from '../../../../../../../components/DataGrid';
import { Alarm } from '../../../../../Alarms/types/Alarm';
import { headings, headingsKeys } from './constants/headings';
import { CELL_WIDTH } from '../../constants/cellWidth';

interface AlarmsProps {
    alarms: Alarm[];
}

export function Alarms(props: AlarmsProps) {
    return (
        <>
            <h2>Alarmy</h2>
            <DataGrid<Alarm>
                cellWidth={CELL_WIDTH}
                data={props.alarms.map(value => ({
                    ...value,
                    priority: value.priority?.label || ('' as any),
                    platform: value.platform
                        .map(platform => platform!.label)
                        .join(', ') as any,
                    value: value.value!.label as any,
                    variableName: value.variableName!.label as any,
                }))}
                keys={headingsKeys}
                headings={headings}
            />
        </>
    );
}
