import { createStore, applyMiddleware } from 'redux';
import reducer from '../reducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { cacheAddObjectSteps } from './middleware/cacheAddObjectSteps';

export const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(thunk, cacheAddObjectSteps)),
);
