import React from 'react';
import { Variable } from './components';
import { ContentContainer } from './styled';
import { CalculationMethodVariable } from '../../../../../../types/CalculationMethod';
import { useObjectForm } from '../../../../../../../Objects/hooks';
import { getTempIdOrId } from '../../../../../../../Templates/components/tools/getTempIdOrId';
import { SelectOption } from '../../../../../../../../../types/SelectOption';

interface VariablesProps {
    configConstants: CalculationMethodVariable[];
    variables: CalculationMethodVariable[];
    updateConfigData: (data: CalculationMethodVariable[]) => void;
    updateVariablesData: (data: CalculationMethodVariable[]) => void;
}

export function Variables(props: VariablesProps) {
    const [formData] = useObjectForm();
    const variableParameters = formData.parameters.map(param => ({
        label: param.name?.label || '',
        value: getTempIdOrId(param),
    }));
    const configsConstants = formData.settings.numeric.map(numeric => ({
        label: numeric.name?.label || '',
        value: numeric.tempId,
    }));

    function onChangeVariableField(
        fieldName: string,
        fieldValue: SelectOption<number>,
    ) {
        const updatedValues = props.variables.map(variable => {
            if (variable.label === fieldName) {
                return {
                    ...variable,
                    value: fieldValue,
                };
            }
            return variable;
        });

        props.updateVariablesData(updatedValues);
    }

    function onChangeConfigConstantsField(
        fieldName: string,
        fieldValue: SelectOption<number>,
    ) {
        const updatedValues = props.configConstants.map(variable => {
            if (variable.label === fieldName) {
                return {
                    ...variable,
                    value: fieldValue,
                };
            }
            return variable;
        });

        props.updateConfigData(updatedValues);
    }

    function renderVariables() {
        return props.variables.map(
            (variable: CalculationMethodVariable, key: number) => {
                return (
                    <Variable
                        // @ts-ignore
                        selectData={variableParameters}
                        onChangeHandle={onChangeVariableField}
                        key={key}
                        variable={variable}
                    />
                );
            },
        );
    }

    function renderConfigConstants() {
        return props.configConstants.map(
            (variable: CalculationMethodVariable, key: number) => {
                return (
                    <Variable
                        // @ts-ignore
                        selectData={configsConstants}
                        onChangeHandle={onChangeConfigConstantsField}
                        key={key}
                        variable={variable}
                    />
                );
            },
        );
    }

    return (
        <ContentContainer>
            {renderVariables()}
            {renderConfigConstants()}
        </ContentContainer>
    );
}
