import React from 'react';
import { ObjectDraft } from '../../../../../../types/ObjectDraft';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

interface ItemProps {
    draft: ObjectDraft;
    openDraft: (id: string) => void;
    removeDraft: (id: string) => void;
}

export function Item(props: ItemProps) {
    const date = new Date(props.draft.time);
    function clickHandle() {
        props.openDraft(props.draft.id);
    }

    function removeHandle() {
        props.removeDraft(props.draft.id);
    }

    return (
        <li
            style={{
                cursor: 'pointer',
                marginBottom: 20,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <div onClick={clickHandle}>
                <Typography color="textSecondary">Wersja z dnia:</Typography>
                {`${date.toLocaleString('pl-PL')}`}
                <Typography style={{ fontSize: 12 }} color="textSecondary">
                    {props.draft.id}
                </Typography>
            </div>
            <div onClick={removeHandle} style={{ marginLeft: 40 }}>
                <DeleteIcon />
            </div>
        </li>
    );
}
