import { ActionTypes, AuthorizationUnion } from '../models/actionTypes';

export interface LoginState {
    authorizationIsSuccess: boolean;
    authorizationErrorMessage: string;
    authorizationPending: boolean;
}

const initialState: LoginState = {
    authorizationErrorMessage: '',
    authorizationIsSuccess: false,
    authorizationPending: false,
};

export function loginReducer(
    state: LoginState = initialState,
    action: AuthorizationUnion,
): LoginState {
    switch (action.type) {
        case ActionTypes.Authorization: {
            return {
                ...state,
                authorizationPending: true,
            };
        }

        case ActionTypes.AuthorizationSuccess: {
            return {
                ...state,
                authorizationPending: false,
                authorizationIsSuccess: true,
            };
        }

        case ActionTypes.AuthorizationFailed: {
            return {
                ...state,
                authorizationPending: false,
                authorizationIsSuccess: false,
                authorizationErrorMessage: action.errorMessage,
            };
        }

        default:
            return state;
    }
}
