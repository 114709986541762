import React from 'react';
import { DeepMenu, Loader } from '../../../../components';
import { menuItems, menuItemsRzeszow } from './constants/menuItems';
import { useOrganizationsState } from '../../../../views/Manage/hooks/useOrganizationsState';
import { LoaderContainer } from '../../../../styled/LoaderContainer';

interface OrganizationMenuProps {
    children: React.ReactNode;
}

export function OrganizationMenu(props: OrganizationMenuProps) {
    const objectId = Number(window.location.pathname.split('/')[2]);
    const [
        organizationsIsFetching,
        organizationsHasFetched,
        organizations,
        getOrganizations,
    ] = useOrganizationsState();

    if (!organizations.length || organizationsIsFetching) {
        getOrgs();
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );
    }

    function getOrgs() {
        if (!organizationsIsFetching && !organizationsHasFetched) {
            getOrganizations();
        }
    }

    const organizationName: string = organizations.filter(
        org => org.id === objectId,
    )[0].name;
    return (
        <DeepMenu
            header={organizationName}
            headerLinkPath={`/organization/${objectId}/details`}
            menuItems={
                process.env.REACT_APP_RZESZOW ? menuItemsRzeszow : menuItems
            }
        >
            {props.children}
        </DeepMenu>
    );
}
