import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, MenuItem } from '@material-ui/core';

import { MenuItemTextContainer, CellMenu } from '../../styled';
import { MenuButtonItem } from './components/MenuButtonItem';

export interface RowMenuProps {
    menuComponents: IMenuItem[];
}

export interface IMenuItem {
    name: string;
    clickHandle: (args?: any) => void;
    linkPath?: string;
    args?: any;
}

export function RowMenu(props: RowMenuProps) {
    const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
    function setAnchor(event: React.MouseEvent<any>) {
        event.stopPropagation();
        setAnchorMenu(event.currentTarget);
    }
    function closeMenu() {
        setAnchorMenu(null);
    }

    return (
        <CellMenu>
            <IconButton style={{ pointerEvents: 'auto' }} onClick={setAnchor}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
                PaperProps={{
                    style: {
                        maxHeight: 200,
                        minWidth: 100,
                    },
                }}
            >
                {props.menuComponents.map(
                    (menuItem: IMenuItem, index: number) => {
                        if (menuItem.linkPath) {
                            return (
                                <MenuItem key={index}>
                                    <Link to={menuItem.linkPath}>
                                        <MenuItemTextContainer>
                                            {menuItem.name}
                                        </MenuItemTextContainer>
                                    </Link>
                                </MenuItem>
                            );
                        }

                        return (
                            <MenuButtonItem
                                key={index}
                                clickHandle={menuItem.clickHandle}
                                name={menuItem.name}
                                args={menuItem.args}
                            />
                        );
                    },
                )}
            </Menu>
        </CellMenu>
    );
}
