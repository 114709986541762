import React, { useContext, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { NotificationHandlersContext } from '../../../../../../../../containers/App/components/AppWithMenu/AppWithMenu';
import { BottomNavBar } from '../../../../../../../../components';
import { useUserData } from '../../../../../hooks/useUserData';
import { DataBox, Label } from './styled';
import { useAddEditForm } from '../../../../../hooks/useAddEditForm';

interface StepFourthProps extends RouteComponentProps<{ id: string }> {
    prevStep: () => {};
    closeDrawer: () => {};
}

export function StepFourthComponent(props: StepFourthProps) {
    const { id } = props.match.params;
    const organizationId = Number(id);
    const {
        addNewUser,
        getUsersAction,
        userFormData,
        updateUser,
        errorAddUser,
        errorEditUser,
        userHasEditedState,
        userHasAddedState,
        resetErrorsHandle,
    } = useUserData(organizationId);
    const [addFormIsOpenedState, editFormIsOpenedState] = useAddEditForm(
        organizationId,
    );
    const notifyHandlers = useContext(NotificationHandlersContext);

    const buttonLabel = addFormIsOpenedState
        ? 'Dodaj'
        : editFormIsOpenedState
        ? 'Edytuj'
        : '';

    async function addUserHandle() {
        if (addFormIsOpenedState) {
            await addNewUser();
        }

        if (editFormIsOpenedState) {
            await updateUser();
        }
    }

    useEffect(() => {
        if (userHasAddedState) {
            getUsersAction();
            props.closeDrawer();
            notifyHandlers.showSuccess('Użytkownik został dodany');
        }

        if (userHasEditedState) {
            notifyHandlers.showSuccess('Użytkownik został zaaktualizowany');
            getUsersAction();
            props.closeDrawer();
        }

        if (errorAddUser) {
            notifyHandlers.showError(errorAddUser);
            resetErrorsHandle();
        }

        if (errorEditUser) {
            notifyHandlers.showError(errorEditUser);
            resetErrorsHandle();
        }
    }, [
        errorAddUser,
        errorEditUser,
        userHasAddedState,
        userHasEditedState,
        userFormData,
        getUsersAction,
        props,
        notifyHandlers,
        resetErrorsHandle,
    ]);

    return (
        <div>
            <div>
                <DataBox>
                    <Label>Imię mazwisko:</Label>
                    <span>
                        {userFormData.name} {userFormData.surname}
                    </span>
                </DataBox>
                <DataBox>
                    <Label>Numer telefonu:</Label>
                    <span>{userFormData.phoneMobile}</span>
                </DataBox>
                <DataBox>
                    <Label>Email:</Label>
                    <span>{userFormData.email}</span>
                </DataBox>
            </div>
            <BottomNavBar
                nextStep={addUserHandle}
                buttonNextText={buttonLabel}
                prevStep={props.prevStep}
            />
        </div>
    );
}

export const StepFourth = withRouter(StepFourthComponent);
