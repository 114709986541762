import { Profile } from '../../types';

export enum ProfileActionTypes {
    SetProfileData = '[Profile] set profile data',
    RemoveProfileData = '[Profile] remove profile data',
}

interface SetProfileData {
    type: typeof ProfileActionTypes.SetProfileData;
    data: Profile;
}

interface RemoveProfileData {
    type: typeof ProfileActionTypes.RemoveProfileData;
}

export type ProfileUnion = SetProfileData | RemoveProfileData;
