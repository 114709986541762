import { Dispatch } from 'redux';

import {
    AddUser,
    DeleteUser,
    EditUser,
    OrganizationUsers,
} from '../../../../api/api';
import { ActionTypes } from '../models/actionTypes';
import { request } from '../../../../tools';
import { User } from '../types';
import { AddUserWithPermissions } from '../types/AddUserWithPermissions';

export function getUsers(organizationId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionTypes.GetUsers,
            organizationId,
        });

        try {
            const users: User[] = await request<User[]>(
                'GET',
                OrganizationUsers(organizationId),
            );

            dispatch(getUsersSuccess(users, organizationId));
        } catch (err) {
            dispatch(getUsersFailed(JSON.stringify(err), organizationId));
        }
    };
}

export function resetErrors(organizationId: number) {
    return {
        type: ActionTypes.ResetErrors,
        organizationId,
    };
}

function getUsersSuccess(users: User[], organizationId: number) {
    return {
        type: ActionTypes.GetUsersSuccess,
        users,
        organizationId,
    };
}

function getUsersFailed(errorMessage: string, organizationId: number) {
    return {
        type: ActionTypes.GetUserFailed,
        errorMessage,
        organizationId,
    };
}

export function addUser(user: AddUserWithPermissions) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionTypes.AddUser,
            organizationId: user.organizationId,
        });

        try {
            await request(
                'POST',
                AddUser(user.organizationId),
                JSON.stringify({
                    user: {
                        ...user,
                        password_confirmation: user.password,
                        isBlocked: false,
                        userType: 1,
                    },
                }),
            );
            dispatch({
                type: ActionTypes.AddUserSuccess,
                organizationId: user.organizationId,
            });
        } catch (err) {
            const error = await err;
            dispatch(addUserFailed(user.organizationId, error));
        }
    };
}

export function addUserFailed(organizationId: number, errorMessage: string) {
    return {
        type: ActionTypes.AddUserFailed,
        organizationId,
        errorMessage,
    };
}

export function editUser(user: User) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionTypes.AddUser,
            organizationId: user.organizationId,
        });

        try {
            await request(
                'PUT',
                EditUser(user),
                JSON.stringify({
                    user: {
                        ...user,
                        password_confirmation: user.password,
                        isBlocked: false,
                        userType: 1,
                    },
                }),
            );
            dispatch({
                type: ActionTypes.EditUserSuccess,
                organizationId: user.organizationId,
            });
        } catch (err) {
            dispatch(editUserFailed(user.organizationId, JSON.stringify(err)));
        }
    };
}

export function editUserFailed(organizationId: number, errorMessage: string) {
    return {
        type: ActionTypes.EditUserFailed,
        organizationId,
        errorMessage,
    };
}

export async function deleteUser(user: User) {
    try {
        await request('DELETE', DeleteUser(user));
        // dispatch({
        //     type: ActionTypes.DeleteUserSuccess,
        //     organizationId: user.organizationId,
        // });
    } catch (err) {
        // dispatch(deleteUserFailed(user.organizationId, JSON.stringify(err)));
    }
    // return async (dispatch: Dispatch) => {
    //     dispatch({
    //         type: ActionTypes.DeleteUser,
    //         organizationId: user.organizationId,
    //     });

    // };
}

export function deleteUserFailed(organizationId: number, errorMessage: string) {
    return {
        type: ActionTypes.DeleteUserFailed,
        organizationId,
        errorMessage,
    };
}
