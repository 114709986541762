import { MachineObject } from '../../../Objects/types';
import { ParameterTypes } from '../../../Parameters/types/ParameterTypes';

export function searchUsedParameters(state: MachineObject, parameter: string) {
    return [
        'alarms',
        'statusAppearance',
        'parametersAppearance',
        'engineSymbol',
        'parameters',
    ].reduce(
        (previousValue, currentValue: string) => {
            const foundUses = [];
            if (
                currentValue !== 'parameters' &&
                // @ts-ignore
                JSON.stringify(state[currentValue]).search(parameter) > 0
            ) {
                // @ts-ignore
                foundUses.push(parseToPlaceName(currentValue));
            }

            if (currentValue === 'parameters') {
                const binary = state.parameters.filter(
                    param =>
                        param.type === ParameterTypes.Binary &&
                        param.tempId !== parameter,
                );
                const calculated = state.parameters.filter(
                    param =>
                        param.type === ParameterTypes.Calculated &&
                        param.tempId !== parameter,
                );

                if (JSON.stringify(binary).search(parameter) > 0) {
                    // @ts-ignore
                    foundUses.push('Parametry - binarne');
                }

                if (JSON.stringify(calculated).search(parameter) > 0) {
                    // @ts-ignore
                    foundUses.push('Parametry - obliczane');
                }
            }
            if (foundUses.length > 0) {
                // previousValue[parameter] = foundUses;
                return {
                    ...previousValue,
                    [parameter]: foundUses,
                };
            }
            return previousValue;
        },
        // { [parameter]: [] },
        {},
    );
}

function parseToPlaceName(title: string) {
    switch (title) {
        case 'alarms': {
            return 'Alarmy';
        }
        case 'statusAppearance': {
            return 'Wygląd statusu';
        }
        case 'parametersAppearance': {
            return 'Wygląd parametrów';
        }
        case 'engineSymbol': {
            return 'Symbol zębatki';
        }
    }
}
