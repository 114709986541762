import React from 'react';
import { StepProps } from '../../types/StepProps';
import { Form } from './components';

interface EngineSymbolProps extends StepProps {
    closeDrawer: () => void;
}

export function EngineSymbol(props: EngineSymbolProps) {
    return <Form {...props} />;
}
