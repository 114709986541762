// @ts-ignore
import * as Yup from 'yup';

export const ContactDataScheme = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    surname: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    email: Yup.string()
        .email('błędny email')
        .required('Required'),
});
