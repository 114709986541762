import { useDispatch, useSelector } from 'react-redux';
import { getUnits } from '../actions/units';
import { AppState } from '../reducer';
import { units, unitsHasFetched, unitsIsFetching } from '../selectors';
import { Unit } from '../types';
import { useEffect } from 'react';

interface UseUnits {
    fetchedUnits: Unit[];
    fetchedUnitsByTypeId: (typeId?: number) => Unit[];
    unitsFetching: boolean;
    unitsFetched: boolean;
}

export function useUnits(): UseUnits {
    const dispatch = useDispatch();
    const fetchedUnits = useSelector((state: AppState) => units(state));
    const unitsFetching = useSelector((state: AppState) =>
        unitsIsFetching(state),
    );
    const unitsFetched = useSelector((state: AppState) =>
        unitsHasFetched(state),
    );

    const fetchedUnitsByTypeId = (typeId?: number) => {
        if (!typeId) {
            return fetchedUnits;
        }
        return fetchedUnits.filter(unit => unit.unitTypeId === typeId);
    };

    useEffect(() => {
        if (!fetchedUnits.length && !unitsFetching) {
            dispatch(getUnits());
        }
    }, [dispatch, fetchedUnits, unitsFetching]);

    return {
        fetchedUnits,
        unitsFetching,
        unitsFetched,
        fetchedUnitsByTypeId,
    };
}
