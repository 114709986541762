import { HTMLAttributes } from 'react';
import styled from 'styled-components';

type JustifyContent =
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit'
    | 'space-evenly';
type AlignContent =
    | 'stretch'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
type FlexDirection =
    | 'row'
    | 'row-reverse'
    | 'column'
    | 'column-reverse'
    | 'initial'
    | 'inherit';
type AlignSelf =
    | 'auto'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'stretch';
type AlignItems = 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';

interface FlexProps extends HTMLAttributes<HTMLDivElement> {
    flexDirection?: FlexDirection;
    flexShrink?: number;
    alignSelf?: AlignSelf;
    order?: number;
    flexBasis?: string;
    alignContent?: AlignContent;
    flexWrap?: FlexWrap;
    flexGrow?: number;
    justifyContent?: JustifyContent;
    alignItems?: AlignItems;
}

const Flex = styled.div<FlexProps>`
    display: flex;
    flex-direction: ${props => props.flexDirection};
    flex-shrink: ${props => props.flexShrink};
    align-self: ${props => props.alignSelf};
    order: ${props => props.order};
    flex-basis: ${props => props.flexBasis};
    align-content: ${props => props.alignContent};
    flex-wrap: ${props => props.flexWrap};
    flex-grow: ${props => props.flexGrow};
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems};
`;

Flex.defaultProps = {
    flexDirection: 'row',
    flexShrink: 1,
    alignSelf: 'auto',
    order: 0,
    flexBasis: 'auto',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    flexGrow: 0,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
};

export { Flex };
