import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducer';
import { organizationObjectsFormData } from '../selectors/objects';
import { MachineObject } from '../types';
import {
    resetErrorsObjectForm,
    resetObjectForm,
    updateObjectForm,
} from '../actions/objects';
import { generateRandomString } from '../../../../tools';

type UseObjectFormOutput = [
    MachineObject,
    (formData: Partial<MachineObject>) => void,
    () => void,
    () => void,
];

export function useObjectForm(): UseObjectFormOutput {
    const formData = useSelector((state: AppState) =>
        organizationObjectsFormData(state),
    );
    const dispatch = useDispatch();

    function updateForm(formData: Partial<MachineObject>) {
        dispatch(
            updateObjectForm(
                formData,
                formData.draftId || generateRandomString(),
            ),
        );
    }

    function resetForm() {
        dispatch(resetObjectForm());
    }

    function resetErrorsForm() {
        dispatch(resetErrorsObjectForm());
    }

    return [formData, updateForm, resetForm, resetErrorsForm];
}
