import React from 'react';
import { Formik } from 'formik';
import {
    FormContentContainer,
    ContentContainer,
    ErrorContainer,
} from './styled';
import { LoginSchema } from '../../models/ValidationScheme';
import { Fields } from './Fields';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../actions/login';
import { AppState } from '../../../../reducer';
import { authorizationErrorMessage } from '../../selectors/login';

export function FormContainer() {
    const dispatch = useDispatch();
    const loginError = useSelector((state: AppState) =>
        authorizationErrorMessage(state),
    );

    const onSubmitHandle = (values: any) => {
        dispatch(login(values.email, values.password));
    };

    return (
        <ContentContainer>
            <FormContentContainer>
                <h2>Autoryzacja</h2>
                <Formik
                    validationSchema={LoginSchema}
                    onSubmit={onSubmitHandle}
                    initialValues={{ email: '', password: '' }}
                >
                    {props => <Fields {...props} />}
                </Formik>
                <ErrorContainer>
                    <p>{loginError}</p>
                </ErrorContainer>
            </FormContentContainer>
        </ContentContainer>
    );
}
