export enum AlarmPlatform {
    InApp = 'inApp',
    Email = 'eMail',
    Sms = 'sms',
}

export type AlarmPlatformType =
    | AlarmPlatform.Email
    | AlarmPlatform.InApp
    | AlarmPlatform.Sms;
