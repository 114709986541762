import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducer';
import {
    updateObjectError,
    updateObjectIsPending,
    updateObjectSuccess,
} from '../selectors/objects';
import { UpdateMachineObject } from '../types';
import { updateObject } from '../actions/objects';

type UseUpdateObject = [
    boolean,
    boolean,
    string,
    (formState: UpdateMachineObject) => void,
];
export function useUpdateObject(): UseUpdateObject {
    const dispatch = useDispatch();
    const isPending = useSelector((state: AppState) =>
        updateObjectIsPending(state),
    );
    const error = useSelector((state: AppState) => updateObjectError(state));
    const success = useSelector((state: AppState) =>
        updateObjectSuccess(state),
    );

    function update(formState: UpdateMachineObject) {
        dispatch(updateObject(formState));
    }

    return [isPending, success, error, update];
}
