import { Heading } from '../../../../../../../../components/DataGrid/types';

export const headingKeys = ['name', 'shortName', 'ipAddress', 'address'];

export const headings: Heading[] = [
    {
        key: 'name',
        heading: 'Nazwa',
    },
    {
        key: 'shortName',
        heading: 'Nazwa skrócona',
    },
    {
        key: 'ipAddress',
        heading: 'IP',
    },
    {
        key: 'address',
        heading: 'Adres',
    },
];
