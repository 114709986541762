import React from 'react';
import { MenuList } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { MenuItem } from './types';
import {
    ContentContainer,
    HeaderContainer,
    StyledLink,
    StyledListItemIcon,
    StyledMenuItem,
} from './styled';

interface DeepMenuProps {
    header: string;
    menuItems: MenuItem[];
    headerLinkPath: string;
}

export function Menu(props: DeepMenuProps) {
    const renderItems = () =>
        props.menuItems.map((item, index) => (
            <StyledMenuItem key={index}>
                <StyledLink to={`${item.url}`} />
                <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                <span>{item.title}</span>
            </StyledMenuItem>
        ));

    return (
        <ContentContainer>
            <HeaderContainer>
                <Link to={props.headerLinkPath}>
                    <h2>{props.header}</h2>
                </Link>
            </HeaderContainer>
            <MenuList>{renderItems()}</MenuList>
        </ContentContainer>
    );
}
