import React from 'react';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, Theme } from '@material-ui/core/styles';

export interface WithNotificationProps {
    showSuccessMessage: (text: string) => void;
    showFailedMessage: (text: string) => void;
}

export const withNotifications = <P extends object>(
    Component: React.ComponentType<P & WithNotificationProps>,
) =>
    class WithNotifications extends React.Component<P> {
        state = {
            failedMessage: '',
            successMessage: '',
            failedIsVisible: false,
            successIsVisible: false,
        };

        openSuccessfullyMessage = (text: string) => {
            if (!this.state.successIsVisible) {
                this.setState({ successMessage: text, successIsVisible: true });
            }
        };

        openFailedMessage = (text: string) => {
            if (!this.state.failedIsVisible) {
                this.setState({ failedMessage: text, failedIsVisible: true });
            }
        };

        closeSuccessfullyMessage = () => {
            if (this.state.successIsVisible) {
                this.setState({ successMessage: '', successIsVisible: false });
            }
        };

        closeFailedMessage = () => {
            if (this.state.failedIsVisible) {
                this.setState({ failedMessage: '', failedIsVisible: false });
            }
        };

        render() {
            const { ...props } = this.props;
            return (
                <>
                    <Component
                        showFailedMessage={this.openFailedMessage}
                        showSuccessMessage={this.openSuccessfullyMessage}
                        {...(props as P)}
                    />
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={this.state.successIsVisible}
                        autoHideDuration={6000}
                        onClose={this.closeSuccessfullyMessage}
                    >
                        <MySnackbarContentWrapper
                            onClose={this.closeSuccessfullyMessage}
                            variant="success"
                            message={this.state.successMessage}
                        />
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={this.state.failedIsVisible}
                        autoHideDuration={6000}
                        onClose={this.closeFailedMessage}
                    >
                        <MySnackbarContentWrapper
                            onClose={this.closeFailedMessage}
                            variant="error"
                            message={this.state.failedMessage}
                        />
                    </Snackbar>
                </>
            );
        }
    };

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles1 = makeStyles((theme: Theme) => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export interface Props {
    className?: string;
    message?: string;
    onClose?: () => void;
    variant: keyof typeof variantIcon;
}

function MySnackbarContentWrapper(props: Props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    <span
                        dangerouslySetInnerHTML={{ __html: message as string }}
                    />
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}
