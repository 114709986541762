import React from 'react';
import { FormikProps } from 'formik';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

import { WideField, Flex } from '../../../../../../../styled/';
import { Localization } from '../../../../../Objects/types';
import { BottomNavBar } from '../../../../../../../components/ProgressStepper/components';
import { ModalContentContainer } from './styled';
import { Map } from '../../../../../../../components/Map';
import { LocalizationFields } from '../../../../../../../containers/LocalizationFields';

interface FieldsProps extends FormikProps<Localization> {
    openMap: () => void;
    prevStep: () => void;
    closeModalHandle: () => void;
    modalIsOpen: boolean;
}

export function Fields(props: FieldsProps) {
    const setCoordinatesHandle = (lat: number, lng: number) => {
        props.setFieldValue('latitude', lat);
        props.setFieldValue('longitude', lng);
    };

    return (
        <>
            <Modal open={props.modalIsOpen} onClose={props.closeModalHandle}>
                <ModalContentContainer>
                    <Map onClickHandle={setCoordinatesHandle} />
                </ModalContentContainer>
            </Modal>
            <form onSubmit={props.handleSubmit}>
                <LocalizationFields {...props} />
                <WideField
                    name="town"
                    value={props.values.town}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.town && props.touched.town}
                    label="Miejscowość"
                    variant="outlined"
                />
                <WideField
                    name="postCode"
                    value={props.values.postCode}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.postCode && props.touched.postCode}
                    label="Kod pocztowy"
                    variant="outlined"
                />
                <WideField
                    name="address"
                    value={props.values.address}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.address && props.touched.address}
                    label="Adres"
                    variant="outlined"
                />
                <Flex>
                    <WideField
                        name="latitude"
                        value={props.values.latitude}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.latitude && props.touched.latitude}
                        label="Szerokość GEO."
                        variant="outlined"
                        type="number"
                    />
                    <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
                    <WideField
                        name="longitude"
                        value={props.values.longitude}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={
                            props.errors.longitude && props.touched.longitude
                        }
                        label="Długość GEO."
                        variant="outlined"
                        type="number"
                    />
                </Flex>
                <Button type="button" onClick={props.openMap}>
                    Zaznacz na mapie
                </Button>
                <BottomNavBar
                    prevStep={props.prevStep}
                    showBack={true}
                    buttonNextText="Dalej"
                />
            </form>
        </>
    );
}
