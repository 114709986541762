import React from 'react';
import { Form } from './components';

interface VariablesProps {
    prevStep: () => void;
    nextStep: () => void;
}

export function Variables(props: VariablesProps) {
    return (
        <Form nextStepHandle={props.nextStep} prevStepHandle={props.prevStep} />
    );
}
