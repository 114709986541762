import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

import { ContentContainer, IconContainer } from './styled';
import { Drawer } from '../../../../../components';
import { steps } from './constants/steps';

export function TopPanel() {
    const [addOrganizationIsVisible, setAddOrganizationIsVisible] = useState(
        false,
    );
    const openAddOrganization = () => setAddOrganizationIsVisible(true);

    function closeDrawer() {
        setAddOrganizationIsVisible(false);
    }

    return (
        <ContentContainer>
            <Drawer
                approveClose={true}
                data={steps}
                hasProgressStepper={true}
                drawerIsVisible={addOrganizationIsVisible}
                setDrawerHide={closeDrawer}
            />
            <Button onClick={openAddOrganization} variant="contained">
                <IconContainer />
                Dodaj nowego
            </Button>
        </ContentContainer>
    );
}
