import React, { useImperativeHandle, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { BoxAppearance } from '../../../../../../Objects/types/AppearanceBase';
import { Fields } from './Fields';
import { Parameter } from '../../../../../../Parameters/types/ParameterTypes';

interface FormProps {
    formData: BoxAppearance;
    updateFormData: (appearance: BoxAppearance) => void;
    closeDrawer: () => void;
    parameters: Parameter[];
}

function Component(props: FormProps, ref: any) {
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    let formikRefProps: FormikProps<BoxAppearance>;

    function selectRow(rowId: string) {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter(row => row !== rowId));
            return;
        }
        setSelectedRows([...selectedRows, rowId]);
    }

    function removeRows() {
        formikRefProps.setFieldValue(
            'parameters',
            formikRefProps.values.parameters.filter(
                param => !selectedRows.includes(param.tempId),
            ),
        );
        selectedRows.forEach(row => {
            setSelectedRows(
                selectedRows.filter(selectedRow => selectedRow !== row),
            );
        });
    }

    useImperativeHandle(ref, () => ({
        ...formikRefProps,
    }));

    function onSubmit(data: BoxAppearance) {
        props.updateFormData(data);
        props.closeDrawer();
    }

    return (
        <Formik<BoxAppearance>
            initialValues={props.formData}
            enableReinitialize={true}
            onSubmit={onSubmit}
            render={(otherProps: FormikProps<BoxAppearance>) => {
                formikRefProps = otherProps;
                return (
                    <Fields
                        parameters={props.parameters}
                        removeHandle={removeRows}
                        onCheckHandle={selectRow}
                        selectedRows={selectedRows}
                        {...otherProps}
                    />
                );
            }}
        />
    );
}

export const Form = React.forwardRef(Component);
