import React from 'react';
import { FormikProps } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';

import { Settings, TextualSettings } from '../../../../../../../Objects/types';
import { SelectBox } from '../../../../../Variables/components/Form/component/Row/styled';
import {
    FormikCreteableSelect,
    FormikTextField,
} from '../../../../../../../../../tools/FormikFields';
import { getTempIdOrId } from '../../../../../tools/getTempIdOrId';
import { RowWithError } from '../../../../../../../Objects/components/TopPanel/styled';
import { SelectOption } from '../../../../../../../../../types/SelectOption';

interface TextualRowProps {
    setting: TextualSettings;
    isChecked: boolean;
    onCheck: (setting: TextualSettings) => void;
    formikProps: FormikProps<Settings>;
    hasError: boolean;
    isEdit: boolean;
    textual: SelectOption[];
}

export function TextualRow(props: TextualRowProps) {
    function onCheckHandle() {
        props.onCheck(props.setting);
    }

    function setName(option: any) {
        props.formikProps.setValues({
            ...props.formikProps.values,
            textual: props.formikProps.values.textual.map(textual => {
                if (getTempIdOrId(textual) === getTempIdOrId(props.setting)) {
                    return {
                        ...textual,
                        name: option,
                    };
                }
                return textual;
            }),
        });
    }

    function setDescriptionEdit(event: any) {
        props.formikProps.setValues({
            ...props.formikProps.values,
            textual: props.formikProps.values.textual.map(textual => {
                if (textual.name?.label === props.setting.name?.label) {
                    return {
                        ...textual,
                        description: event.target.value,
                    };
                }
                return textual;
            }),
        });
    }

    function setDescription(event: any) {
        props.formikProps.setValues({
            ...props.formikProps.values,
            textual: props.formikProps.values.textual.map(textual => {
                if (getTempIdOrId(textual) === getTempIdOrId(props.setting)) {
                    return {
                        ...textual,
                        description: event.target.value,
                    };
                }
                return textual;
            }),
        });
    }

    return (
        <RowWithError hasError={props.hasError}>
            {props.isEdit ? null : (
                <Checkbox
                    checked={props.isChecked}
                    onChange={onCheckHandle}
                    value="checkedB"
                    color="primary"
                />
            )}
            <SelectBox>
                <FormikCreteableSelect
                    name="objectType"
                    cacheOptions
                    isClearable
                    isDisabled={props.isEdit}
                    options={props.textual}
                    value={props.setting.name}
                    defaultOptions
                    onChange={setName}
                    placeholder="Nazwa"
                />
            </SelectBox>
            <SelectBox>
                <FormikTextField
                    name="address"
                    variant="outlined"
                    placeholder="Opis"
                    value={props.setting.description}
                    onChange={
                        props.isEdit ? setDescriptionEdit : setDescription
                    }
                />
            </SelectBox>
        </RowWithError>
    );
}
