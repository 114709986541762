import React from 'react';
import { ContentContainer } from './styled';
import { File } from './components';

interface DocumentsBottomPanelProps {
    files: File[];
    removeDocument: (file: File) => void;
}

export function DocumentsBottomPanel(props: DocumentsBottomPanelProps) {
    return (
        <ContentContainer>
            {props.files.map((file, index) => (
                <File
                    key={index}
                    file={file}
                    removeDocument={props.removeDocument}
                />
            ))}
        </ContentContainer>
    );
}
