import { TemplatesActionTypes } from '../models/actionTypes';
import { Dispatch } from 'redux';
import { request } from '../../../../tools';
import { Templates } from '../../../../api/api';
import { MachineObject } from '../../Objects/types';

export function getTemplates() {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: TemplatesActionTypes.FetchTemplates,
        });

        try {
            const templates = await request('GET', Templates);
            dispatch({
                type: TemplatesActionTypes.FetchTemplatesSuccess,
                templates,
            });
        } catch (e) {}
    };
}

export function getTemplatesAsyncSelect() {
    return request('GET', Templates);
}

export function addTemplate(formState: Partial<MachineObject>) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: TemplatesActionTypes.AddTemplate,
        });

        try {
            await request('POST', Templates, JSON.stringify(formState));
            dispatch({
                type: TemplatesActionTypes.AddTemplateSuccess,
            });
        } catch (error) {
            dispatch({
                type: TemplatesActionTypes.AddTemplateFailed,
                error: JSON.stringify(error),
            });
        }
    };
}

export function resetStore() {
    return {
        type: TemplatesActionTypes.ResetAddTemplateState,
    };
}

export function removeTemplateById(id: number) {
    return request('DELETE', `${Templates}/${id}`);
}
