import React from 'react';
import { ContentContainer } from './styled';
import { PanelLogo, FormContainer } from './components';

export function Login() {
    return (
        <ContentContainer>
            <PanelLogo />
            <FormContainer />
        </ContentContainer>
    );
}
