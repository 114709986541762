import React from 'react';
// @ts-ignore
import ReactPhoneInput from 'react-phone-input-2';

import { PhoneNumberInputWrapper } from './styled';

export function PhoneInput(props: any) {
    return (
        <PhoneNumberInputWrapper>
            <ReactPhoneInput {...props} />
        </PhoneNumberInputWrapper>
    );
}
