import { AppState } from '../../../../reducer';
import { Group } from '../types/Group';

function getGroupsState(state: AppState) {
    return state.groups;
}

export function fetchedGroups(state: AppState): Group[] {
    return getGroupsState(state).groups;
}

export function groupsIsFetching(state: AppState): boolean {
    return getGroupsState(state).groupsIsFetching;
}

export function groupsHasFetched(state: AppState): boolean {
    return getGroupsState(state).groupsHasFetched;
}

export function groupAddingPending(state: AppState): boolean {
    return getGroupsState(state).groupIsAdding;
}

export function groupEditionPending(state: AppState): boolean {
    return getGroupsState(state).groupEditionIsPending;
}

export function groupHasEdited(state: AppState): boolean {
    return getGroupsState(state).groupHasEdited;
}

export function groupHasAdded(state: AppState): boolean {
    return getGroupsState(state).groupHasAdded;
}

export function addGroupForm(state: AppState): Group {
    return getGroupsState(state).addGroupForm;
}
