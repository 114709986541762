/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { getProfileOrganizationId, isGlobalAdminSelector } from '../selectors';
import history from '../containers/App/tools/history';
import { useEffect } from 'react';
import { getItemFromStorage } from '../tools';
import { StorageKeys } from '../constants/storageKeys';
import { setProfileData } from '../actions/profile';

interface UseGlobalAdminOutput {
    isGlobalAdmin: boolean;
    orgId: number;
    redirectToOrganization: () => void;
    checkAndRedirect: () => void;
    rehydrateData: () => void;
}

export function useGlobalAdmin(): UseGlobalAdminOutput {
    const dispatch = useDispatch();
    const isGlobalAdmin = useSelector((state: any) =>
        isGlobalAdminSelector(state),
    );
    const orgId = useSelector((state: any) => getProfileOrganizationId(state));
    function redirectToOrganization() {
        history.push(`/organization/${orgId}/details`);
    }

    function checkAndRedirect() {
        if (
            !isGlobalAdmin &&
            orgId &&
            window.location.pathname.split('/')[1] !== 'organization'
        ) {
            redirectToOrganization();
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function rehydrateData() {
        const dataFromCache = await getItemFromStorage(StorageKeys.ProfileData);
        if (dataFromCache) {
            dispatch(setProfileData(JSON.parse(dataFromCache)));
        }
    }

    useEffect(() => {
        if (!isGlobalAdmin && !orgId) {
            rehydrateData();
        }
    }, []);

    return {
        isGlobalAdmin,
        orgId,
        redirectToOrganization,
        checkAndRedirect,
        rehydrateData,
    };
}
