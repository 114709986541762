import { User } from '../types';

export enum ActionTypes {
    GetUsers = '[Organization / Users] fetch users',
    GetUsersSuccess = '[Organization / Users] fetch users success',
    GetUserFailed = '[Organization / Users] fetch users failed',

    ResetErrors = '[Organization / Users] reset errors',

    SetAddUserFormData = '[Organization / Users] set user form data',
    ResetAddUserFormData = '[Organization / Users] reset user form data',

    AddUser = '[Organization / Users] add new user',
    AddUserSuccess = '[Organization / Users] add new user success',
    AddUserFailed = '[Organization / Users] add new user failed',

    EditUser = '[Organization / Users] edit new user',
    EditUserSuccess = '[Organization / Users] edit new user success',
    EditUserFailed = '[Organization / Users] edit new user failed',

    DeleteUser = '[Organization / Users] delete new user',
    DeleteUserSuccess = '[Organization / Users] delete new user success',
    DeleteUserFailed = '[Organization / Users] delete new user failed',

    OpenEditForm = '[Organization / Users] open edit form',
    OpenAddForm = '[Organization / Users] open add form',

    CloseAddEditForm = '[Organization / Users] close add or edit form',
}

interface OpenEditForm {
    type: typeof ActionTypes.OpenEditForm;
    formData: User;
    organizationId: number;
}

interface ResetErrors {
    type: typeof ActionTypes.ResetErrors;
    organizationId: number;
}

interface OpenAddForm {
    type: typeof ActionTypes.OpenAddForm;
    organizationId: number;
}

interface CloseAddEditForm {
    type: typeof ActionTypes.CloseAddEditForm;
    organizationId: number;
}

interface GetUsers {
    type: typeof ActionTypes.GetUsers;
    organizationId: number;
}

interface GetUsersSuccess {
    type: typeof ActionTypes.GetUsersSuccess;
    users: User[];
    organizationId: number;
}

interface GetUsersFailed {
    type: typeof ActionTypes.GetUserFailed;
    errorMessage: string;
    organizationId: number;
}

interface SetAddNewUserFormData {
    type: typeof ActionTypes.SetAddUserFormData;
    formData: User;
    organizationId: number;
}

interface AddUser {
    type: typeof ActionTypes.AddUser;
    organizationId: number;
}

interface AddUserSuccess {
    type: typeof ActionTypes.AddUserSuccess;
    organizationId: number;
}

interface AddUserFailed {
    type: typeof ActionTypes.AddUserFailed;
    errorMessage: string;
    organizationId: number;
}

interface EditUser {
    type: typeof ActionTypes.EditUser;
    organizationId: number;
}

interface EditUserSuccess {
    type: typeof ActionTypes.EditUserSuccess;
    organizationId: number;
}

interface EditUserFailed {
    type: typeof ActionTypes.EditUserFailed;
    errorMessage: string;
    organizationId: number;
}

interface DeleteUser {
    type: typeof ActionTypes.DeleteUser;
    organizationId: number;
}

interface DeleteUserSuccess {
    type: typeof ActionTypes.DeleteUserSuccess;
    organizationId: number;
}

interface DeleteUserFailed {
    type: typeof ActionTypes.DeleteUserFailed;
    errorMessage: string;
    organizationId: number;
}

interface ResetAddUserFormData {
    type: typeof ActionTypes.ResetAddUserFormData;
    organizationId: number;
}

export type UsersActionsUnion =
    | GetUsers
    | GetUsersSuccess
    | ResetErrors
    | EditUser
    | EditUserSuccess
    | EditUserFailed
    | DeleteUser
    | DeleteUserSuccess
    | DeleteUserFailed
    | GetUsersFailed
    | SetAddNewUserFormData
    | AddUser
    | OpenEditForm
    | OpenAddForm
    | CloseAddEditForm
    | ResetAddUserFormData
    | AddUserSuccess
    | AddUserFailed;
