import React from 'react';
import { ContentContainer } from './styled';

interface ContentProps {
    children: React.ReactNode;
}

export function Content(props: ContentProps) {
    return <ContentContainer>{props.children}</ContentContainer>;
}
