import React from 'react';
import { FormikProps } from 'formik';
import { BinaryParameter } from '../../../../types/BinaryParameter';
import {
    ContentContainer,
    InlineFieldContainer,
    InlineFieldDivider,
    StyledForm,
    VerticalDivider,
} from '../../../../../formStyled';
import { SelectBox, WideField } from '../../../../../../../styled/';
import {
    FormikAsyncSelect,
    FormikCheckBox,
    FormikSelect,
} from '../../../../../../../tools/FormikFields';
import { PARAMETER_TYPES } from '../selectData/selectData';
import { useSelectParameterForm } from '../../../../hooks/useSelectParameterForm';
import { SubHeading } from '../../../SubHeading';
import { FormContainer } from '../../../../styled';
import { SubmitPanel } from '../../../SubmitPanel';
import { useFetchAsyncSelectData } from '../../../../hooks/useFetchAsyncSelectData';
import { useObjectForm } from '../../../../../Objects/hooks';
import {
    ParameterTypes,
    ParameterTypesNames,
} from '../../../../types/ParameterTypes';
import { BIT_NUMBER_DATA } from './constants/bitNumberSelectData';

interface FieldsProps extends FormikProps<BinaryParameter> {
    onCancel: () => void;
}

export function Fields(props: FieldsProps) {
    const setSelectType = useSelectParameterForm<BinaryParameter>(props);
    const {
        fetchPresentationBinary,
        fetchReadWrite,
    } = useFetchAsyncSelectData();
    const [formData] = useObjectForm();
    const orderedParameters = formData.parameters
        .filter(param => param.type === ParameterTypes.Order)
        .map(param => ({
            label: param.name?.label || '',
            value: param.tempId,
        }));

    return (
        <FormContainer>
            <SubHeading />
            <StyledForm onSubmit={props.handleSubmit}>
                <ContentContainer>
                    <WideField
                        name="name"
                        value={props.values.name?.label || ''}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.name && props.touched.name}
                        label="Nazwa"
                        variant="outlined"
                        disabled
                    />
                    <SelectBox>
                        <FormikSelect
                            name="type"
                            cacheOptions
                            options={PARAMETER_TYPES}
                            defaultValue={{
                                value: props.values.type,
                                label: ParameterTypesNames[props.values.type],
                            }}
                            error={props.errors.type && props.touched.type}
                            onChange={setSelectType}
                            onBlur={props.handleBlur}
                            placeholder="Typ parametru"
                        />
                    </SelectBox>
                    <InlineFieldContainer>
                        <WideField
                            name="address"
                            value={props.values.address}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={
                                props.errors.address && props.touched.address
                            }
                            label="Adres/Nazwa zmiennej"
                            variant="outlined"
                        />
                        <InlineFieldDivider />
                        <FormikSelect
                            name="bitNumber"
                            cacheOptions
                            options={BIT_NUMBER_DATA}
                            defaultValue={props.values.bitNumber}
                            error={props.errors.type && props.touched.type}
                            onChange={(option: any) =>
                                props.setFieldValue('bitNumber', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Numer bitu"
                        />
                    </InlineFieldContainer>
                    <WideField
                        name="shortDescription"
                        value={props.values.shortDescription}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        multiline
                        rows={3}
                        error={
                            props.errors.shortDescription &&
                            props.touched.shortDescription
                        }
                        label="Krótki opis (do 19 znaków)"
                        variant="outlined"
                    />
                    <WideField
                        name="fullDescription"
                        value={props.values.fullDescription}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        multiline
                        rows={5}
                        error={
                            props.errors.fullDescription &&
                            props.touched.fullDescription
                        }
                        label="Pełny opis"
                        variant="outlined"
                    />
                </ContentContainer>
                <VerticalDivider />
                <ContentContainer>
                    <SelectBox>
                        <FormikAsyncSelect
                            name="isOnlyForReading"
                            cacheOptions
                            loadOptions={fetchReadWrite}
                            defaultOptions
                            value={props.values.isOnlyForReading}
                            error={
                                props.errors.isOnlyForReading &&
                                props.touched.isOnlyForReading
                            }
                            onChange={(option: any) =>
                                props.setFieldValue('isOnlyForReading', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Zmienna tylko do odczytu"
                        />
                    </SelectBox>
                    <SelectBox>
                        <FormikAsyncSelect
                            name="presentationMode"
                            cacheOptions
                            loadOptions={fetchPresentationBinary}
                            defaultOptions
                            value={props.values.presentationMode}
                            error={
                                props.errors.presentationMode &&
                                props.touched.presentationMode
                            }
                            onChange={(option: any) =>
                                props.setFieldValue('presentationMode', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Sposób prezentacji"
                        />
                    </SelectBox>
                    <FormikCheckBox
                        name="isAvailableForChart"
                        checked={props.values.isAvailableForChart}
                        onChange={() =>
                            props.setFieldValue(
                                'isAvailableForChart',
                                !props.values.isAvailableForChart,
                            )
                        }
                        label="Dostępność na wykresie"
                    />
                    {props.values.isAvailableForChart ? (
                        <FormikCheckBox
                            name="isDefaultShowingAtCharts"
                            checked={props.values.isDefaultShowingAtCharts}
                            onChange={() =>
                                props.setFieldValue(
                                    'isDefaultShowingAtCharts',
                                    !props.values.isDefaultShowingAtCharts,
                                )
                            }
                            label="Wyświetlaj domyślnie po wejściu do Analiz"
                        />
                    ) : null}
                    {props.values.isOnlyForReading &&
                    props.values.isOnlyForReading.value === 2 ? (
                        <>
                            <SelectBox>
                                <FormikSelect
                                    name="firstOrder"
                                    value={props.values.firstOrder}
                                    cacheOptions
                                    defaultOptions
                                    options={orderedParameters}
                                    onChange={(option: any) =>
                                        props.setFieldValue(
                                            'firstOrder',
                                            option,
                                        )
                                    }
                                    onBlur={props.handleBlur}
                                    placeholder="Parameter rozkazu - lewa strona"
                                />
                            </SelectBox>
                            <SelectBox>
                                <FormikSelect
                                    name="secondOrder"
                                    cacheOptions
                                    value={props.values.secondOrder}
                                    options={orderedParameters}
                                    defaultOptions
                                    onChange={(option: any) =>
                                        props.setFieldValue(
                                            'secondOrder',
                                            option,
                                        )
                                    }
                                    onBlur={props.handleBlur}
                                    placeholder="Parameter rozkazu - prawa strona"
                                />
                            </SelectBox>
                        </>
                    ) : null}
                </ContentContainer>
            </StyledForm>
            <SubmitPanel
                onCancel={props.onCancel}
                submitForm={props.submitForm}
            />
        </FormContainer>
    );
}
