import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducer';
import { addAlarmFormData } from '../selectors/alarms';
import { Alarm } from '../types/Alarm';
import { resetAlarmForm, updateAlarmForm } from '../actions/alarms';

interface UseAlarmFormOutput {
    formData: Partial<Alarm>;
    updateFormData: (data: Alarm) => void;
    resetFormData: () => void;
}

export function useAlarmForm(): UseAlarmFormOutput {
    const dispatch = useDispatch();
    const formData = useSelector((state: AppState) => addAlarmFormData(state));

    function updateFormData(data: Alarm) {
        dispatch(updateAlarmForm(data));
    }

    function resetFormData() {
        dispatch(resetAlarmForm());
    }

    return {
        formData,
        updateFormData,
        resetFormData,
    };
}
