import React from 'react';
import { TopPanelContainer } from '../../../styled';
import { default as MaterialDrawer } from '@material-ui/core/Drawer/Drawer';
import { DrawerContentContainer } from '../../../../../components/Drawer/styled';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/core/SvgIcon/SvgIcon';
import { AddEditForm } from './forms';
import { useAlarmForm } from '../../hooks/useAlarmForm';
import { formInitValues } from './forms/AddEditForm/constants/formInitValues';

interface TopPanelProps {
    isOpen: boolean;
    closeAddAlarm: () => void;
    openAddAlarm: () => void;
}

export function TopPanel(props: TopPanelProps) {
    const { formData, updateFormData } = useAlarmForm();

    return (
        <TopPanelContainer>
            <MaterialDrawer
                anchor="right"
                open={props.isOpen}
                onClose={props.closeAddAlarm}
            >
                <DrawerContentContainer size="normal">
                    <AddEditForm
                        formData={{ ...formInitValues, ...formData }}
                        updateFormData={updateFormData}
                    />
                </DrawerContentContainer>
            </MaterialDrawer>
            <Button onClick={props.openAddAlarm} variant="contained">
                <AddIcon />
                Dodaj nowy
            </Button>
        </TopPanelContainer>
    );
}
