import React from 'react';
import { ContentContainer } from '../styled';
import { TopPanel } from './components';
import { Heading } from '../../../components/Heading';
import { useDrawerVisible } from '../../../hooks';

export function Parameters() {
    const { drawerState, closeDrawer, openDrawer } = useDrawerVisible();
    return (
        <ContentContainer>
            <Heading color="black">Parametry</Heading>
            <TopPanel
                isOpen={drawerState}
                closeAddParameter={closeDrawer}
                openAddParameter={openDrawer}
            />
        </ContentContainer>
    );
}
