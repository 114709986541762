import React from 'react';
import { Formik, FormikProps } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { useObjectForm } from '../../../../../Objects/hooks';
import { BasicDataScheme } from './constants/validation';
import { BasicData } from '../../../../../Objects/types';
import { Fields } from './Fields';
import { useUnits } from '../../../../../../../hooks';
import { StepProps } from '../../../../types/StepProps';
import { ParameterTypes } from '../../../../../Parameters/types/ParameterTypes';

interface FormProps extends StepProps, RouteComponentProps<{ id: string }> {}

function FormComponent(props: FormProps) {
    const { id } = props.match.params;
    const [state, setData] = useObjectForm();
    useUnits();
    const onSubmitHandle = (values: BasicData) => {
        setData({ ...state, basicData: values });
        props.nextStep();
    };
    function setTemplate(data: string) {
        const templateParameters = JSON.parse(data).parameters.map(
            (object: any) => {
                if (object.type === ParameterTypes.Binary) {
                    return {
                        ...object,
                        bitNumber:
                            typeof object.bitNumber === 'number'
                                ? {
                                      label: object.bitNumber,
                                      value: object.bitNumber,
                                  }
                                : object.bitNumber,
                    };
                }
                return object;
            },
        );

        setData({
            ...state,
            ...JSON.parse(data),
            parameters: templateParameters,
        });
    }

    return (
        <Formik<BasicData>
            validationSchema={BasicDataScheme}
            enableReinitialize={true}
            initialValues={{ ...state.basicData, organizationId: Number(id) }}
            onSubmit={onSubmitHandle}
            render={(otherProps: FormikProps<BasicData>) => (
                <Fields
                    {...otherProps}
                    setTemplate={setTemplate}
                    isObjectEdit={props.isObjectEdit}
                />
            )}
        />
    );
}

export const Form = withRouter(FormComponent);
