import React, { useState } from 'react';
import { FormikProps } from 'formik';
import { FigureParameter } from '../../../../types/FigureParameter';
import { SelectBox, WideField } from '../../../../../../../styled/';
import {
    ContentContainer,
    InlineFieldContainer,
    InlineFieldDivider,
    StyledForm,
    VerticalDivider,
} from '../../../../../formStyled';
import {
    FormikAsyncSelect,
    FormikCheckBox,
    FormikSelect,
} from '../../../../../../../tools/FormikFields';
import { PARAMETER_TYPES } from '../selectData/selectData';
import { useSelectParameterForm } from '../../../../hooks/useSelectParameterForm';
import { useFetchAsyncSelectData } from '../../../../hooks/useFetchAsyncSelectData';
import { FormContainer } from '../../../../styled';
import { SubmitPanel } from '../../../SubmitPanel';
import { SubHeading } from '../../../SubHeading';
import { COUNT_AFTER_AT } from '../../../../constants/countAfterAtData';
import { ParameterTypesNames } from '../../../../types/ParameterTypes';

interface FieldsProps extends FormikProps<FigureParameter> {
    onCancel: () => void;
}

export function Fields(props: FieldsProps) {
    const setSelectType = useSelectParameterForm<FigureParameter>(props);
    const [unitesByTypeId, setUnitsByTypeIds] = useState<any>([]);
    const {
        fetchNumberFormat,
        fetchUnits,
        fetchReadWrite,
    } = useFetchAsyncSelectData();

    async function setUnits(option: any) {
        props.setFieldValue('unitRAW', option);
        const units: any = await fetchUnits(option.unitTypeId);
        setUnitsByTypeIds(units);
    }

    return (
        <FormContainer>
            <SubHeading />
            <StyledForm onSubmit={props.handleSubmit}>
                <ContentContainer>
                    <WideField
                        name="name"
                        value={props.values.name?.label || ''}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.name && props.touched.name}
                        label="Nazwa"
                        variant="outlined"
                        disabled
                    />
                    <InlineFieldContainer>
                        <FormikSelect
                            name="type"
                            cacheOptions
                            options={PARAMETER_TYPES}
                            defaultValue={{
                                label: ParameterTypesNames[props.values.type],
                                value: props.values.type,
                            }}
                            error={props.errors.type && props.touched.type}
                            onChange={setSelectType}
                            onBlur={props.handleBlur}
                            placeholder="Typ parametru"
                        />
                        <InlineFieldDivider />
                        <FormikAsyncSelect
                            name="format"
                            cacheOptions
                            loadOptions={fetchNumberFormat}
                            value={props.values.format}
                            defaultOptions
                            error={props.errors.format && props.touched.format}
                            onChange={(option: any) =>
                                props.setFieldValue('format', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Format"
                        />
                    </InlineFieldContainer>
                    <WideField
                        name="address"
                        value={props.values.address}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.address && props.touched.address}
                        label="Adres/Nazwa zmiennej"
                        variant="outlined"
                    />
                    <InlineFieldContainer>
                        <WideField
                            name="minRAW"
                            type="number"
                            value={props.values.minRAW}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={props.errors.minRAW && props.touched.minRAW}
                            label="Min RAW"
                            variant="outlined"
                        />
                        <InlineFieldDivider />
                        <WideField
                            name="maxRAW"
                            type="number"
                            value={props.values.maxRAW}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={props.errors.maxRAW && props.touched.maxRAW}
                            label="Max RAW"
                            variant="outlined"
                        />
                    </InlineFieldContainer>
                    <InlineFieldContainer>
                        <WideField
                            name="minShowing"
                            type="number"
                            value={props.values.minShowing}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={
                                props.errors.minShowing &&
                                props.touched.minShowing
                            }
                            label="Min wyświetalna"
                            variant="outlined"
                        />
                        <InlineFieldDivider />
                        <WideField
                            name="maxShowing"
                            type="number"
                            value={props.values.maxShowing}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={
                                props.errors.maxShowing &&
                                props.touched.maxShowing
                            }
                            label="Max wyświetalna"
                            variant="outlined"
                        />
                    </InlineFieldContainer>
                    <SelectBox>
                        <FormikSelect
                            name="countAfterAt"
                            cacheOptions
                            defaultOptions
                            value={props.values.countAfterAt}
                            options={COUNT_AFTER_AT}
                            error={
                                props.errors.countAfterAt &&
                                props.touched.countAfterAt
                            }
                            onChange={(option: any) =>
                                props.setFieldValue('countAfterAt', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Liczba miejsc po przecinku"
                        />
                    </SelectBox>
                    <WideField
                        name="shortDescription"
                        value={props.values.shortDescription}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        multiline
                        rows={3}
                        error={
                            props.errors.shortDescription &&
                            props.touched.shortDescription
                        }
                        label="Krótki opis (do 19 znaków)"
                        variant="outlined"
                    />
                    <WideField
                        name="fullDescription"
                        value={props.values.fullDescription}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        multiline
                        rows={5}
                        error={
                            props.errors.fullDescription &&
                            props.touched.fullDescription
                        }
                        label="Pełny opis"
                        variant="outlined"
                    />
                </ContentContainer>
                <VerticalDivider />
                <ContentContainer>
                    <SelectBox>
                        <FormikAsyncSelect
                            name="unitRAW"
                            cacheOptions
                            loadOptions={fetchUnits}
                            value={props.values.unitRAW}
                            defaultOptions
                            error={
                                props.errors.unitRAW && props.touched.unitRAW
                            }
                            onChange={setUnits}
                            onBlur={props.handleBlur}
                            placeholder="Jednostka RAW"
                        />
                    </SelectBox>
                    <SelectBox>
                        <FormikSelect
                            name="unitShowing"
                            cacheOptions
                            isDisabled={!props.values.unitRAW}
                            value={props.values.unitShowing}
                            options={unitesByTypeId}
                            defaultOptions
                            error={
                                props.errors.unitShowing &&
                                props.touched.unitShowing
                            }
                            onChange={(option: any) =>
                                props.setFieldValue('unitShowing', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Jednostka wyświetlana"
                        />
                    </SelectBox>
                    <SelectBox>
                        <FormikAsyncSelect
                            name="isOnlyForReading"
                            cacheOptions
                            loadOptions={fetchReadWrite}
                            value={props.values.isOnlyForReading}
                            defaultOptions
                            error={
                                props.errors.isOnlyForReading &&
                                props.touched.isOnlyForReading
                            }
                            onChange={(option: any) =>
                                props.setFieldValue('isOnlyForReading', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Zmienna tylko do odczytu"
                        />
                    </SelectBox>
                    <FormikCheckBox
                        name="isAvailableForChart"
                        checked={props.values.isAvailableForChart}
                        onChange={() =>
                            props.setFieldValue(
                                'isAvailableForChart',
                                !props.values.isAvailableForChart,
                            )
                        }
                        label="Dostępność na wykresie"
                    />
                    {props.values.isAvailableForChart ? (
                        <FormikCheckBox
                            name="isDefaultShowingAtCharts"
                            checked={props.values.isDefaultShowingAtCharts}
                            onChange={() =>
                                props.setFieldValue(
                                    'isDefaultShowingAtCharts',
                                    !props.values.isDefaultShowingAtCharts,
                                )
                            }
                            label="Wyświetlaj domyślnie po wejściu do Analiz"
                        />
                    ) : null}
                </ContentContainer>
            </StyledForm>
            <SubmitPanel
                onCancel={props.onCancel}
                submitForm={props.submitForm}
            />
        </FormContainer>
    );
}
