import React from 'react';
import styled from 'styled-components';
import { FunctionComponentProps } from '../../../../types/FunctionComponentProps';

export function NavSection(props: FunctionComponentProps) {
    return (
        <NavSectionContentContainer>
            {props.children}
        </NavSectionContentContainer>
    );
}

const NavSectionContentContainer = styled.div``;
