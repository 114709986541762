import React from 'react';
import { ContentContainer, FileNameContainer, IconContainer } from './styled';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

interface FileProps {
    file: File;
    removeDocument: (file: File) => void;
}

export function File(props: FileProps) {
    function onClickHandle() {
        props.removeDocument(props.file);
    }
    return (
        <ContentContainer>
            <FileNameContainer>{props.file.name}</FileNameContainer>
            <IconContainer>
                <RemoveCircleIcon onClick={onClickHandle} />
            </IconContainer>
        </ContentContainer>
    );
}
