import React from 'react';
import { Paper, Table, TableHead, TableBody } from '@material-ui/core';

import { Row, Cell, RowMenu } from './components';
import { Heading } from './types';

interface DataGridProps<TP> {
    keys: string[];
    headings: Heading[];
    data: TP[];
    showMenu?: boolean;
    rowClickHandle?: (args: any) => void;
    cellWidth?: number | undefined;
}

export function DataGrid<TP>(props: DataGridProps<TP>) {
    const renderHeaderRows = () =>
        props.keys.map((key: string, index: number) => (
            <Cell key={index}>
                {props.headings.find(heading => heading.key === key)!.heading}
            </Cell>
        ));

    const renderBody = () =>
        props.data.map((item: TP, key: number) => (
            <Row onClick={props.rowClickHandle} item={item} key={key}>
                {// TODO to check TS resolved
                props.keys.map((dataGridKey: string, index: number) => (
                    <Cell width={props.cellWidth} key={index}>
                        {(item as any)[dataGridKey]}
                    </Cell>
                ))}
                {props.showMenu ? (
                    <RowMenu menuComponents={(item as any).menuComponents} />
                ) : null}
            </Row>
        ));

    return (
        <Paper>
            <Table>
                <TableHead>
                    <Row>
                        {renderHeaderRows()}
                        {props.showMenu ? <Cell>{}</Cell> : null}
                    </Row>
                </TableHead>
                <TableBody>{renderBody()}</TableBody>
            </Table>
        </Paper>
    );
}
