import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDrawerVisible } from '../../../hooks';
import { ContentContainer } from '../styled';
import { addGroupSteps } from './constants/addGroupSteps';
import {
    Heading,
    DndGrid,
    TopPanel,
    ModalBody,
    ApproveAction,
} from '../../../components';
import { useGroups } from './hooks/useGroups';
import { GridBodyItem } from './components/GridBodyItem';
import { DndGridItem } from '../../../components/DndGrid/DndGrid';
import { arrayMove, SortEnd } from 'react-sortable-hoc';
import { DndContainer } from './styled';
import { IMenuItem } from '../../../components/DataGrid/components/RowMenu/RowMenu';
import { Group } from './types/Group';
import { useAddEditGroup } from './hooks/useAddEditGroup';
import { editGroupSteps } from './constants/editGroupSteps';
import { Modal } from '@material-ui/core';

interface GroupsProps extends RouteComponentProps<{ id: string }> {}

export function Groups(props: GroupsProps) {
    const { id } = props.match.params;
    const { drawerState, openDrawer, closeDrawer } = useDrawerVisible();
    const { groups, updateOrder } = useGroups(Number(id));
    const { hydrateForm, removeGroup } = useAddEditGroup(Number(id));
    const [isEdit, setEditState] = useState(false);
    const [removedGroup, setRemovedGroup] = useState<{
        group: Group | null;
        modalIsOpen: boolean;
    }>({
        group: null,
        modalIsOpen: false,
    });

    function handleCloseModal() {
        setRemovedGroup({
            group: null,
            modalIsOpen: false,
        });
    }

    function onEndSortHandle(sort: SortEnd) {
        updateOrder(arrayMove(groups, sort.oldIndex, sort.newIndex));
    }

    function closeDrawerHandle() {
        setEditState(false);
        closeDrawer();
    }

    function editGroup(group: Group) {
        setEditState(true);
        hydrateForm(group);
        openDrawer();
    }

    function openRemoveModal(group: Group) {
        setRemovedGroup({
            group,
            modalIsOpen: true,
        });
    }

    function removeGroupHandle() {
        removeGroup(removedGroup!.group as Group);
    }

    const preparedData: DndGridItem[] = groups.map((group, index) => {
        const rowMenu: IMenuItem[] = [
            {
                name: 'Edytuj',
                clickHandle: editGroup,
                args: group,
            },
            {
                name: 'Usuń',
                clickHandle: openRemoveModal,
                args: group,
            },
        ];

        return {
            key: String(group!.value),
            element: (
                <GridBodyItem
                    menuComponents={rowMenu}
                    key={index}
                    group={group}
                />
            ),
            index,
        };
    });

    return (
        <ContentContainer>
            <Heading color="black">Grupy</Heading>
            <TopPanel
                drawerSteps={isEdit ? editGroupSteps : addGroupSteps}
                label="Dodaj nową"
                hasProgressStepper={false}
                isOpen={drawerState}
                openAddTemplate={openDrawer}
                closeAddTemplate={closeDrawerHandle}
            />
            <Modal open={removedGroup.modalIsOpen} onClose={handleCloseModal}>
                <ModalBody closeModalOnCLick={handleCloseModal}>
                    <ApproveAction
                        heading="Usuwanie Grupy"
                        desc="Czy napewno chcesz usunąc grupe?"
                        approveOnClick={removeGroupHandle}
                        cancelOnClick={handleCloseModal}
                    />
                </ModalBody>
            </Modal>
            <DndContainer>
                <DndGrid
                    onEndSortHandle={onEndSortHandle}
                    headings={['Nazwa', 'Data utworzenia', 'Utworzył', '']}
                    items={preparedData}
                />
            </DndContainer>
        </ContentContainer>
    );
}
