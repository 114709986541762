import React from 'react';
import { FormikProps } from 'formik';
import { BottomNavBar } from '../../../../../../../../components/ProgressStepper/components';
import { Flex } from '../../../../../../../../styled/';
import { FormValues } from './types';
import { WideField } from '../../styled';
import { FormikSelect } from '../../../../../../../../tools/FormikFields';
import { SelectOptions, Voivodeship } from '../../../../../../../../types';
import { DividerBox } from '../../../styled';
import { countries } from './constants';
import { useVoivodeships } from '../../../../../../../../containers/LocalizationFields/hooks';
import { SelectContainer } from './styled';

export function Fields(props: FormikProps<FormValues>) {
    const [
        voivodeshipsFetching,
        voivodeshipsFetched,
        allVoivodeships,
        getVoivodeships,
    ] = useVoivodeships();

    if (!voivodeshipsFetched && !voivodeshipsFetching) {
        getVoivodeships();
    }

    function setProvince({ label }: { label: string; id: number }) {
        props.setFieldValue('province', label.toLowerCase());
    }

    return (
        <form onSubmit={props.handleSubmit}>
            <WideField
                name="name"
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.name && props.touched.name}
                label="Nazwa"
                variant="outlined"
            />
            <WideField
                name="shortName"
                value={props.values.shortName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.shortName && props.touched.shortName}
                label="Nazwa skrócona"
                variant="outlined"
            />
            <WideField
                name="address"
                value={props.values.address}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.address && props.touched.address}
                label="Ulica oraz numer budynku"
                variant="outlined"
            />
            <Flex flexDirection="row">
                <WideField
                    name="addressTwo"
                    value={props.values.addressTwo}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.addressTwo && props.touched.addressTwo}
                    label="Numer lokalu"
                    variant="outlined"
                />
                <DividerBox />
                <WideField
                    name="postCode"
                    value={props.values.postCode}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.postCode && props.touched.postCode}
                    label="Kod pocztowy"
                    variant="outlined"
                />
            </Flex>
            <SelectContainer>
                <FormikSelect
                    name="province"
                    cacheOptions
                    options={allVoivodeships}
                    getOptionLabel={(opt: Voivodeship) => opt.label}
                    getOptionValue={(opt: Voivodeship) => opt.id}
                    defaultOptions
                    onChange={setProvince}
                    onBlur={props.handleBlur}
                    placeholder="Województwo"
                />
            </SelectContainer>
            <WideField
                name="town"
                value={props.values.town}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.town && props.touched.town}
                label="Miasto"
                variant="outlined"
            />
            <WideField
                name="nip"
                value={props.values.nip}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.nip && props.touched.nip}
                label="NIP"
                variant="outlined"
            />
            <FormikSelect
                name="country"
                value={countries.find(
                    country => country.value === props.values.country,
                )}
                options={countries}
                onChange={(option: SelectOptions) =>
                    props.setFieldValue('country', option.value)
                }
                onBlur={props.handleBlur}
                error={props.errors.country && props.touched.country}
                label="Kraj"
            />
            <BottomNavBar
                showBack={false}
                showNext={true}
                buttonNextText="Dalej"
            />
        </form>
    );
}
