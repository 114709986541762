import styled, { css } from 'styled-components';
import { DrawerSizes } from '../types';

interface DrawerProps {
    size: DrawerSizes;
}

export const DrawerContentContainer = styled.div<DrawerProps>`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    ${(props: DrawerProps) => getSize(props.size)};

    && label {
        height: 55px;
    }
`;

function getSize(size: DrawerSizes) {
    switch (size) {
        case 'normal':
            return css`
                width: 60vw;
                height: 100%;
            `;
        case 'wide':
            return css`
                width: 85vw;
                height: 100%;
            `;

        case 'small':
            return css`
                width: 25vw;
                height: 100%;
            `;
        default:
            getSize('normal');
    }
}
