/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import { Group } from '../../../../types/Group';
import { useAddEditGroup } from '../../../../hooks/useAddEditGroup';
import { Fields } from './Fields';
import { generateRandomString } from '../../../../../../../tools';
import { NotificationHandlersContext } from '../../../../../../../containers/App/components/AppWithMenu/AppWithMenu';

interface FormProps {
    organizationId: number;
    closeDrawer: () => void;
    isEdit: boolean;
}

export function Form(props: FormProps) {
    const {
        stateAddNewGroup,
        addNewGroup,
        newGroupHasAdded,
        getGroupsHandle,
        resetStoreHandle,
        editGroupHandle,
        groupHasEditedState,
    } = useAddEditGroup(props.organizationId);
    const notifyHandlers = useContext(NotificationHandlersContext);

    function onSubmit(group: Group) {
        if (props.isEdit) {
            editGroupHandle(group);
            return;
        }
        addNewGroup(group);
    }

    useEffect(() => {
        if (newGroupHasAdded) {
            props.closeDrawer();
            getGroupsHandle();
            notifyHandlers.showSuccess('Grupa została dodana');
            resetStoreHandle();
        }
        if (groupHasEditedState) {
            props.closeDrawer();
            getGroupsHandle();
            notifyHandlers.showSuccess('Grupa została zedytowana');
            resetStoreHandle();
        }
    }, [newGroupHasAdded, groupHasEditedState]);

    const preparedInitValues = {
        ...stateAddNewGroup,
        objects: stateAddNewGroup.objects.map(item => ({
            ...item,
            tempId: generateRandomString(),
        })),
    };

    return (
        <Formik<Group> initialValues={preparedInitValues} onSubmit={onSubmit}>
            {(otherProps: FormikProps<Group>) => (
                <Fields
                    {...otherProps}
                    organizationId={props.organizationId}
                    isEdit={props.isEdit}
                />
            )}
        </Formik>
    );
}
