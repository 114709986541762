import { AppState } from '../../../reducer';
import { OrganizationsState } from '../reducer';
import { Organization } from '../../../types';
import { FormState } from '../Organizations/types';

function organizationsState(appState: AppState): OrganizationsState {
    return appState.organizations;
}

export function organizationsFetching(appState: AppState): boolean {
    return organizationsState(appState).organizationsIsFetching;
}

export function organizationsFetched(appState: AppState): boolean {
    return organizationsState(appState).organizationsFetched;
}

export function organizations(appState: AppState): Organization[] {
    return organizationsState(appState).organizations;
}

export function addOrganizationIsPending(appState: AppState): boolean {
    return organizationsState(appState).addOrganizationPending;
}

export function addOrganizationIsAdded(appState: AppState): boolean {
    return organizationsState(appState).organizationIsAdded;
}

export function addOrganizationErrorMessage(appState: AppState): string {
    return organizationsState(appState).addOrganizationErrorMessage;
}

export function addOrganizationFormData(appState: AppState): FormState {
    return organizationsState(appState).addOrganizationForm;
}
