import React from 'react';
import { FormikTextField } from '../../../../tools/FormikFields';
import Button from '@material-ui/core/Button';

export function Fields(props: any) {
    return (
        <form onSubmit={props.handleSubmit}>
            <FormikTextField
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.email}
                name="email"
                error={props.errors.email && props.touched.email}
                label="E-mail"
                variant="outlined"
            />
            <FormikTextField
                type="password"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.password}
                error={props.errors.password && props.touched.password}
                name="password"
                label="Hasło"
                variant="outlined"
            />
            <Button variant="contained" color="primary" type="submit">
                Zaloguj sie
            </Button>
        </form>
    );
}
