import React from 'react';
import { ButtonBox, ButtonsContainer } from '../../styled';
import { Button } from '@material-ui/core';

interface SubmitPanelProps {
    submitForm: () => void;
    onCancel: () => void;
}

export function SubmitPanel(props: SubmitPanelProps) {
    return (
        <ButtonsContainer>
            <ButtonBox>
                <Button variant="outlined" onClick={props.onCancel}>
                    Anuluj
                </Button>
            </ButtonBox>
            <Button onClick={props.submitForm} variant="contained">
                Zapisz
            </Button>
        </ButtonsContainer>
    );
}
