import { addItemToStorage, getItemFromStorage } from './storage';
import { StorageKeys } from '../constants/storageKeys';
import { MachineObject } from '../views/Organization/Objects/types';

export async function addObjectDraftToStorage(
    state: Partial<MachineObject>,
    id: string,
) {
    const draftsFromStorage = await getItemFromStorage(
        StorageKeys.AddObjectDrafts,
    );

    const drafts: any = draftsFromStorage ? JSON.parse(draftsFromStorage) : {};
    drafts[id] = {
        id,
        time: +new Date(),
        state,
    };

    addItemToStorage(StorageKeys.AddObjectDrafts, JSON.stringify(drafts));
}
