import React, { useState } from 'react';
import { EngineSymbol } from '../../../../../Objects/types/EngineSymbol';
import { useObjectForm } from '../../../../../Objects/hooks';
import { StepProps } from '../../../../types/StepProps';
import { Formik, FormikProps } from 'formik';
import { Fields } from './Fields';
import { ParameterTypes } from '../../../../../Parameters/types/ParameterTypes';
import { BinaryParameter } from '../../../../../Parameters/types/BinaryParameter';
import { validateRow } from '../../../../../../../tools/validateFields';
import { BasicDataScheme } from './constants/validation';

interface FormProps extends StepProps {}

export function Form(props: FormProps) {
    const [state, setData] = useObjectForm();
    const [errors, setErrors] = useState<string[]>([]);
    const onSubmitHandle = (values: EngineSymbol) => {
        const errors = validateRow(values.parameters, ['parameter']);
        if (errors.length) {
            setErrors(errors);
            return;
        }

        setData({ ...state, engineSymbol: values });
        props.nextStep();
    };

    const binaryOptions: BinaryParameter[] = state.parameters.filter(
        parameter => parameter.type === ParameterTypes.Binary,
    ) as BinaryParameter[];

    return (
        <Formik<EngineSymbol>
            enableReinitialize={true}
            initialValues={state.engineSymbol}
            onSubmit={onSubmitHandle}
            validationSchema={BasicDataScheme}
            render={(otherProps: FormikProps<EngineSymbol>) => (
                <Fields
                    {...otherProps}
                    validationErrors={errors}
                    prevStep={props.prevStep}
                    selectOptions={binaryOptions}
                />
            )}
        />
    );
}
