import React from 'react';
import { Step } from '../../../../../../components/ProgressStepper';
import { BasicData, ContactData, License, Billing, Summary } from '../steps';

export const steps: Step[] = [
    {
        title: 'Dane klienta',
        // @ts-ignore
        component: <BasicData />,
    },
    {
        title: 'Dane kontaktowy',
        // @ts-ignore
        component: <ContactData />,
    },
    {
        title: 'Licencja',
        // @ts-ignore
        component: <License />,
    },
    {
        title: 'Płatności',
        // @ts-ignore
        component: <Billing />,
    },
    {
        title: 'Podsumowanie',
        // @ts-ignore
        component: <Summary />,
    },
];
