import React from 'react';

import { ContentContainer } from './styled';
import { Form } from './Form/Form';

interface StepOneProps {
    prevStep: () => void;
    nextStep: () => void;
}

export function StepOne(props: StepOneProps) {
    return (
        <ContentContainer>
            <Form nextStepHandle={props.nextStep} />
        </ContentContainer>
    );
}
