import { useFetchAsyncSelectData } from '../../Parameters/hooks/useFetchAsyncSelectData';
import { useEffect, useState } from 'react';
import { SelectOption } from '../../../../types/SelectOption';

interface UseNumericConfigurationOutput {
    hasFetched: boolean;
    isLoading: boolean;
    numerical: SelectOption[];
}

export function useNumericConfiguration() {
    const { fetchNumericalConfiguration } = useFetchAsyncSelectData();
    const [variablesState, setVariablesState] = useState<
        UseNumericConfigurationOutput
    >({
        hasFetched: false,
        isLoading: false,
        numerical: [],
    });

    useEffect(() => {
        async function fetchVariablesHandle() {
            const data: SelectOption[] = await fetchNumericalConfiguration();

            setVariablesState({
                ...variablesState,
                hasFetched: true,
                numerical: data,
            });
        }

        if (!variablesState.hasFetched && !variablesState.isLoading) {
            setVariablesState({
                ...variablesState,
                isLoading: true,
            });
            fetchVariablesHandle();
        }
    }, [fetchNumericalConfiguration, variablesState]);

    return variablesState;
}
