import React from 'react';
import { Formik, FormikProps } from 'formik';
import { useParameterForm } from '../../../../hooks/useParameterForm';
import { formInitValues } from './constants/formInitValues';
import { OrderParameter } from '../../../../types/OrderParameter';
import { Fields } from './Fields';
import { OrderSchema } from './constants/validationScheme';

interface OrderProps {
    onSubmitHandle: (data: OrderParameter) => void;
    onCancel: () => void;
}

export function Order(props: OrderProps) {
    const [formData] = useParameterForm();

    return (
        <Formik<OrderParameter>
            initialValues={{ ...formInitValues, ...formData }}
            enableReinitialize={true}
            validationSchema={OrderSchema}
            onSubmit={props.onSubmitHandle}
            render={(formikProps: FormikProps<OrderParameter>) => (
                <Fields onCancel={props.onCancel} {...formikProps} />
            )}
        />
    );
}
