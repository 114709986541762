import { ParameterTypes } from '../../../../../types/ParameterTypes';
import { CalculatedParameter } from '../../../../../types/CalculatedParameter';

export const formInitValues: CalculatedParameter = {
    name: undefined,
    format: '',
    address: '',
    aggregationMethod: null,
    unitShowing: undefined,
    fullDescription: '',
    shortDescription: '',
    type: ParameterTypes.Calculated,
    isAvailableForChart: false,
};
