import * as Yup from 'yup';
import { baseValidation } from '../../constants';

export const FigureSchema = Yup.object().shape({
    ...baseValidation,
    format: Yup.string().required('Required'),
    unitShowing: Yup.string().required('Required'),
    countAfterAt: Yup.string().required('Required'),
    unitRAW: Yup.string().required('Required'),
    isOnlyForReading: Yup.string().required('Required'),
});
