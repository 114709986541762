import React from 'react';
import { StepOne, StepTwo } from '../..';
import { StepFourth } from '../StepFourth/StepFourth';

export const steps = [
    {
        title: 'Dane osobowe',
        // @ts-ignore
        component: <StepOne />,
    },
    {
        title: 'Uprawnienia',
        // @ts-ignore
        component: <StepTwo />,
    },
    {
        title: 'Podsumowanie',
        // @ts-ignore
        component: <StepFourth />,
    },
];
