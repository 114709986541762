import React from 'react';
import { StyledTextField } from './styled';

interface InputProps {
    id: string;
    label: string;
    onChange: React.ChangeEventHandler<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >;
    value: string;
    name: string;
    className?: string;
}

export function Input(props: InputProps) {
    return <StyledTextField {...props} margin="normal" variant="outlined" />;
}
