import { RouteConfig } from '../../../types/RouteConfig';
import { Manage, Organizations } from '../../../views';

export const customerRouting: RouteConfig[] = [
    {
        component: Manage,
        exact: true,
        path: '/manage',
    },
    {
        component: Manage,
        exact: true,
        path: '/',
    },
    {
        component: Organizations,
        exact: true,
        path: '/manage/organizations',
    },
];
