import { AppState } from '../../../reducer';
import { LoginState } from '../reducer';

function getAuthorisationState(appState: AppState): LoginState {
    return appState.authorization;
}

export function isAuthorizated(appState: AppState): boolean {
    return getAuthorisationState(appState).authorizationIsSuccess;
}

export function authorizationErrorMessage(appState: AppState): string {
    return getAuthorisationState(appState).authorizationErrorMessage;
}

export function authorizationPending(appState: AppState): boolean {
    return getAuthorisationState(appState).authorizationPending;
}
