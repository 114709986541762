import React from 'react';
import { Form } from './components';

interface LocalizationProps {
    prevStep: () => void;
    nextStep: () => void;
}

export function Localization(props: LocalizationProps) {
    return <Form prevStep={props.prevStep} nextStep={props.nextStep} />;
}
