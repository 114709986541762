import { Unit } from '../../types';

export enum UnitsActionTypes {
    FetchUnits = '[Units] fetch units',
    FetchUnitsSuccess = '[Units] fetch units success',
}

export interface FetchUnits {
    type: typeof UnitsActionTypes.FetchUnits;
}

export interface FetchUnitsSuccess {
    type: typeof UnitsActionTypes.FetchUnitsSuccess;
    units: Unit[];
}

export type ActionUnitsUnion = FetchUnits | FetchUnitsSuccess;
