import React from 'react';
import { Formik, FormikProps } from 'formik';

import { useOrganizationFormState } from '../../../hooks/useOrganizationFormState';
import { AccountType, FormValues } from './types';
import { ContactDataScheme } from './constants';
import { Fields } from './Fields';

interface FormProps {
    fieldsKey: string;
    accountType: AccountType;
    forwardedRef: any;
}

export function Form(props: FormProps) {
    const [state, setData] = useOrganizationFormState();

    const onSubmit = (formValues: FormValues) => {
        setData({ [props.fieldsKey]: formValues });
    };

    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={ContactDataScheme}
            initialValues={{
                name: state[props.accountType].name,
                surname: state[props.accountType].surname,
                email: state[props.accountType].email,
                phoneNumber: state[props.accountType].phoneNumber,
            }}
        >
            {(formikProps: FormikProps<FormValues>) => (
                <Fields {...formikProps} ref={props.forwardedRef} />
            )}
        </Formik>
    );
}
