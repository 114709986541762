import React from 'react';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/core/SvgIcon/SvgIcon';
import { MachineObject } from '../../../../../Objects/types';

interface DevDataProps {
    data: MachineObject;
}

export function DevData(props: DevDataProps) {
    return (
        <div style={{ maxWidth: '35vw', overflow: 'scroll', marginTop: 20 }}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>DEV JSON</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <pre>{JSON.stringify(props.data, null, 4)}</pre>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}
