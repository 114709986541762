import React from 'react';
import { Formik, FormikProps } from 'formik';
import { TimeParameter } from '../../../../types/TimeParameter';
import { formInitValues } from './constants/formInitValues';
import { useParameterForm } from '../../../../hooks/useParameterForm';
import { Fields } from './Fields';

interface TimeProps {
    onSubmitHandle: (data: TimeParameter) => void;
    onCancel: () => void;
}

export function Time(props: TimeProps) {
    const [formData] = useParameterForm();

    return (
        <Formik<TimeParameter>
            initialValues={{ ...formInitValues, ...formData }}
            enableReinitialize={true}
            onSubmit={props.onSubmitHandle}
            render={(formikProps: FormikProps<TimeParameter>) => (
                <Fields onCancel={props.onCancel} {...formikProps} />
            )}
        />
    );
}
