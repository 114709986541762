import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducer';
import {
    counties,
    countiesHasFetched,
    countiesIsFetching,
} from '../../../selectors';
import { County } from '../../../types';
import { getCounties } from '../../../actions/location';

type CountiesOutput = [
    boolean,
    boolean,
    County[],
    (voivodeshipId: number) => void,
];

export function useCounties(): CountiesOutput {
    const dispatch = useDispatch();
    const countiesFetching = useSelector((appState: AppState) =>
        countiesIsFetching(appState),
    );

    const countiesFetched = useSelector((appState: AppState) =>
        countiesHasFetched(appState),
    );

    const allCounties = useSelector((appState: AppState) => counties(appState));

    function fetchCounties(voivodeshipId: number) {
        dispatch(getCounties(voivodeshipId));
    }

    return [countiesFetching, countiesFetched, allCounties, fetchCounties];
}
