import React from 'react';
import { SelectOption } from '../../../../../../../../../../../types/SelectOption';
import Checkbox from '@material-ui/core/Checkbox';
import { FormikAsyncCreteableSelect } from '../../../../../../../../../../../tools/FormikFields';
import { ObjectAtGroup } from '../../../../../../../../types/Group';
import { useFetchAsyncSelectData } from '../../../../../../../../../Parameters/hooks/useFetchAsyncSelectData';
import { Flex } from '../../../../../../../../../../../styled/';

interface RowProps {
    isChecked: boolean;
    object: ObjectAtGroup;
    selectRow: (object: ObjectAtGroup) => void;
    setObject: (object: ObjectAtGroup, option: any) => void;
    organizationId: number;
}

export function Row(props: RowProps) {
    const { fetchObjects } = useFetchAsyncSelectData();

    function onCheckHandle() {
        props.selectRow(props.object);
    }

    function setObject(option: SelectOption) {
        props.setObject(props.object, option);
    }

    return (
        <Flex>
            <Checkbox
                checked={props.isChecked}
                onChange={onCheckHandle}
                value="checkedB"
                color="primary"
            />
            <FormikAsyncCreteableSelect
                name="objectType"
                cacheOptions
                loadOptions={() => fetchObjects(props.organizationId)}
                defaultOptions
                value={props.object.label ? props.object : null}
                onChange={setObject}
                placeholder="Wybierz obiekt"
            />
        </Flex>
    );
}
