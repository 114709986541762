import styled from 'styled-components';
import 'react-day-picker/lib/style.css';

export const DatePickerWrapper = styled.div`
    & .DayPickerInput {
        width: 100%;
    }

    & .DayPickerInput-OverlayWrapper {
        z-index: 2;
    }

    & input {
        width: 100%;
        height: 56px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        font-size: 14px;
        background-color: white;
        padding-left: 10px;
        box-sizing: border-box;
    }
`;
