import React from 'react';
import { Formik, FormikProps } from 'formik';
import { Grid } from './Grid';
import { FormValues } from './types';
import { useOrganizationFormState } from '../../../../hooks/useOrganizationFormState';

interface FormProps {}

export function UserTable(props: FormProps) {
    const [state, setState] = useOrganizationFormState();

    const onSubmitHandle = (values: any) => {
        setState({ ...state, billing: { ...state.billing, users: values } });
    };

    let initialValue = {
        'unitPrice-1': state.billing.users['unitPrice-1'],
        'clientPrice-1': state.billing.users['clientPrice-1'],
        'allowance-1': state.billing.users['allowance-1'],
        'licenseCount-1': state.license.userCounts,
        'monthSummary-1': state.license.userCounts * 50,
        'unitPrice-2': state.billing.users['unitPrice-2'],
        'clientPrice-2': state.billing.users['clientPrice-2'],
        'allowance-2': state.billing.users['allowance-2'],
        'licenseCount-2': state.license.objectsCounts,
        'monthSummary-2': state.license.objectsCounts * 50,
    };

    return (
        <Formik onSubmit={onSubmitHandle} initialValues={initialValue}>
            {(props: FormikProps<FormValues>) => <Grid {...props} />}
        </Formik>
    );
}
