import { Unit } from '../types';
import {
    ActionUnitsUnion,
    UnitsActionTypes,
} from '../constants/actionTypes/units';

interface UnitState {
    unitsIsFetching: boolean;
    unitsHasFetched: boolean;
    units: Unit[];
}

const initState: UnitState = {
    units: [],
    unitsHasFetched: false,
    unitsIsFetching: false,
};

export function unitReducer(
    state: UnitState = initState,
    action: ActionUnitsUnion,
) {
    switch (action.type) {
        case UnitsActionTypes.FetchUnits: {
            return {
                ...state,
                unitsIsFetching: true,
            };
        }

        case UnitsActionTypes.FetchUnitsSuccess: {
            return {
                ...state,
                unitsHasFetched: true,
                unitsIsFetching: false,
                units: action.units,
            };
        }

        default:
            return state;
    }
}
