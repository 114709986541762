import { useDispatch, useSelector } from 'react-redux';

import {
    addUserError,
    editUserError,
    getAddUserFormData,
    organizationUsers,
    userHasAdded,
    userHasEdited,
    usersFetched,
    usersIsFetching,
} from '../selectors/users';
import {
    addUser,
    deleteUser,
    editUser,
    getUsers,
    resetErrors,
} from '../actions/users';
import { AppState } from '../../../../reducer';
import { User } from '../types';
import { setAddUserFormData } from '../actions/addEditform';
import { AddUserWithPermissions } from '../types/AddUserWithPermissions';

interface useUserDataOutput {
    addNewUser: () => void;
    getUsersAction: () => void;
    updateAddUserForm: (userData: Partial<AddUserWithPermissions>) => void;
    userFormData: AddUserWithPermissions;
    users: User[];
    updateUser: () => void;
    errorAddUser: string;
    errorEditUser: string;
    userHasEditedState: boolean;
    userHasAddedState: boolean;
    usersHasFetched: boolean;
    usersFetching: boolean;
    resetErrorsHandle: () => void;
    deleteUserHandle: (user: User) => Promise<void>;
}

export function useUserData(organizationId: number): useUserDataOutput {
    const userFormData = useSelector((state: AppState) =>
        getAddUserFormData(state, organizationId),
    );

    const errorAddUser = useSelector((state: AppState) =>
        addUserError(state, organizationId),
    );

    const errorEditUser = useSelector((state: AppState) =>
        editUserError(state, organizationId),
    );

    const userHasEditedState = useSelector((state: AppState) =>
        userHasEdited(state, organizationId),
    );

    const userHasAddedState = useSelector((state: AppState) =>
        userHasAdded(state, organizationId),
    );

    const usersHasFetched = useSelector((state: AppState) =>
        usersFetched(state, organizationId),
    );

    const usersFetching = useSelector((state: AppState) =>
        usersIsFetching(state, organizationId),
    );

    const users = useSelector((state: AppState) =>
        organizationUsers(state, organizationId),
    );
    const dispatch = useDispatch();

    function addNewUser() {
        dispatch(addUser(userFormData));
    }

    function updateUser() {
        dispatch(editUser(userFormData));
    }

    function updateAddUserForm(userData: Partial<User>) {
        dispatch(setAddUserFormData(organizationId, userData));
    }

    async function getUsersAction() {
        dispatch(getUsers(organizationId));
    }

    async function deleteUserHandle(user: User) {
        await deleteUser(user);
        dispatch(getUsers(organizationId));
    }

    function resetErrorsHandle() {
        dispatch(resetErrors(organizationId));
    }

    return {
        addNewUser,
        getUsersAction,
        updateAddUserForm,
        userFormData,
        users,
        updateUser,
        errorAddUser,
        errorEditUser,
        userHasEditedState,
        userHasAddedState,
        usersHasFetched,
        usersFetching,
        resetErrorsHandle,
        deleteUserHandle,
    };
}
