import styled from 'styled-components';
import { Label } from '../../../../../../../../../../Users/components/TopPanel/components/Steps/StepFourth/styled';

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    ${Label} {
        margin-bottom: 2px;
    }
`;
