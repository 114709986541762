import { useDispatch, useSelector } from 'react-redux';
import { OrganizationValues } from '../../../types';
import {
    addNewOrganization, resetError,
    resetStore,
} from '../../../../actions/organizations';
import { AppState } from '../../../../../../reducer';
import {
    addOrganizationErrorMessage,
    addOrganizationIsAdded,
    addOrganizationIsPending,
} from '../../../../selectors/organizations';

type output = [() => void, boolean, boolean, string, () => void, () => void];

export function useAddOrganization(formState: OrganizationValues): output {
    const dispatch = useDispatch();
    const addOrganizationPending = useSelector((appState: AppState) =>
        addOrganizationIsPending(appState),
    );
    const addOrganizationAdded = useSelector((appState: AppState) =>
        addOrganizationIsAdded(appState),
    );
    const addOrganizationMessageError = useSelector((appState: AppState) =>
        addOrganizationErrorMessage(appState),
    );

    async function add() {
        dispatch(addNewOrganization(formState));
    }

    async function resetStoreHandle() {
        dispatch(resetStore());
    }

    async function resetErrorHandle() {
        dispatch(resetError());
    }

    return [
        add,
        addOrganizationPending,
        addOrganizationAdded,
        addOrganizationMessageError,
        resetStoreHandle,
        resetErrorHandle,
    ];
}
