import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';

import { steps } from './components/Steps/constants/config';
import { useAddEditForm } from '../../hooks/useAddEditForm';
import { IconContainer } from './styled';
import { Drawer } from '../../../../../components';
import { TopPanelContainer } from '../../../styled';

interface TopPanelProps {
    organizationId: number;
}

export function TopPanel(props: TopPanelProps) {
    const [
        addFormIsOpened,
        editFormIsOpened,
        ,
        openAddForm,
        closeForm,
        clearForm,
    ] = useAddEditForm(props.organizationId);
    function openAddUser() {
        clearForm();
        openAddForm();
    }

    useEffect(() => {}, [addFormIsOpened, editFormIsOpened]);

    return (
        <TopPanelContainer>
            <Drawer
                approveClose={true}
                data={steps}
                hasProgressStepper={true}
                drawerIsVisible={addFormIsOpened || editFormIsOpened}
                setDrawerHide={closeForm}
            />
            <Button onClick={openAddUser} variant="contained">
                <IconContainer />
                Dodaj nowego
            </Button>
        </TopPanelContainer>
    );
}
