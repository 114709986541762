import React from 'react';
import Button from '@material-ui/core/Button';
import { ButtonContainer, ContentContainer } from './styled';

interface BottomPanelProps<T> {
    actionHandleArg: T;
    primaryButtonText: string;
    secondButtonText: string;
    secondButtonActionHandle: () => void;
    primaryButtonActionHandle: (arg: T) => void;
}

export function BottomPanel<T>(props: BottomPanelProps<T>) {
    function acceptHandle() {
        props.primaryButtonActionHandle(props.actionHandleArg);
    }

    return (
        <ContentContainer>
            <ButtonContainer>
                <Button
                    onClick={props.secondButtonActionHandle}
                    variant="outlined"
                >
                    {props.secondButtonText}
                </Button>
            </ButtonContainer>
            <ButtonContainer>
                <Button onClick={acceptHandle} variant="contained">
                    {props.primaryButtonText}
                </Button>
            </ButtonContainer>
        </ContentContainer>
    );
}
