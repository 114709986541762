import styled from 'styled-components';

export const ItemContainer = styled.div`
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    border-radius: 40px;
    background-color: ${props => props.theme.colors.primary.background};
    margin-bottom: 10px;
    cursor: pointer;

    & svg {
        color: white;
    }

    &:hover {
        background-color: ${props => props.theme.colors.secondary.background};
    }
`;
