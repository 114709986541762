import { LogoContainer } from './styled';
import { ReactComponent as Logo } from '../../assets/bumerang-logo.svg';
import React from 'react';

export function PanelLogo() {
    return (
        <LogoContainer>
            <Logo />
        </LogoContainer>
    );
}
