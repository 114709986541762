import { Community, County, Voivodeship } from '../types';
import { ActionTypes, LocationUnion } from '../constants/actionTypes/location';

export interface LocationState {
    counties: County[];
    countyIsFetching: boolean;
    countyHasFetched: boolean;
    countyFetchError: string;

    communities: Community[];
    communityIsFetching: boolean;
    communityHasFetched: boolean;
    communityError: string;

    voivodeships: Voivodeship[];
    voivodeshipIsFetching: boolean;
    voivodeshipHasFetched: boolean;
    voivodeshipError: string;
}

const initialState: LocationState = {
    communities: [],
    communityHasFetched: false,
    communityIsFetching: false,
    communityError: '',

    counties: [],
    countyHasFetched: false,
    countyIsFetching: false,
    countyFetchError: '',

    voivodeships: [],
    voivodeshipHasFetched: false,
    voivodeshipIsFetching: false,
    voivodeshipError: '',
};

export function location(
    state: LocationState = initialState,
    action: LocationUnion,
): LocationState {
    switch (action.type) {
        case ActionTypes.FetchCommunities: {
            return {
                ...state,
                communityIsFetching: true,
            };
        }

        case ActionTypes.FetchCommunitiesFailed: {
            return {
                ...state,
                communityError: action.message,
                communityHasFetched: false,
                communities: [],
                communityIsFetching: false,
            };
        }

        case ActionTypes.FetchCommunitiesSuccess: {
            return {
                ...state,
                communityHasFetched: true,
                communityIsFetching: false,
                communities: action.communities,
            };
        }

        case ActionTypes.FetchCounties: {
            return {
                ...state,
                countyIsFetching: true,
            };
        }

        case ActionTypes.FetchCountiesSuccess: {
            return {
                ...state,
                countyIsFetching: false,
                countyHasFetched: true,
                counties: action.counties,
            };
        }

        case ActionTypes.FetchCountiesFailed: {
            return {
                ...state,
                countyFetchError: action.message,
                counties: [],
                countyHasFetched: false,
                countyIsFetching: false,
            };
        }

        case ActionTypes.FetchVoivodeshipies: {
            return {
                ...state,
                voivodeshipIsFetching: true,
            };
        }

        case ActionTypes.FetchVoivodeshipiesSuccess: {
            return {
                ...state,
                voivodeshipIsFetching: false,
                voivodeshipHasFetched: true,
                voivodeships: action.voivodeships,
            };
        }

        case ActionTypes.FetchVoivodeshipiesFailed: {
            return {
                ...state,
                voivodeships: [],
                voivodeshipHasFetched: false,
                voivodeshipIsFetching: false,
                voivodeshipError: action.message,
            };
        }

        default:
            return state;
    }
}
