import { Alarm } from '../types/Alarm';

export enum AlarmsActionTypes {
    FetchAlarms = '[Alarms] fetch alarms',
    FetchAlarmsSuccess = '[Alarms] fetch alarms success',
    FetchAlarmsFailed = '[Alarms] fetch alarms failed',

    ResetForm = '[Alarms] reset form',
    SetForm = '[Alarms] set form',
}

interface FetchAlarms {
    type: typeof AlarmsActionTypes.FetchAlarms;
}

interface FetchAlarmsSuccess {
    type: typeof AlarmsActionTypes.FetchAlarmsSuccess;
    alarms: Alarm[];
}

interface FetchAlarmsFailed {
    type: typeof AlarmsActionTypes.FetchAlarmsFailed;
}

interface ResetAddAlarmForm {
    type: typeof AlarmsActionTypes.ResetForm;
}

interface SetAddAlarmForm {
    type: typeof AlarmsActionTypes.SetForm;
    formData: Partial<Alarm>;
}

export type AlarmsUnion =
    | FetchAlarms
    | FetchAlarmsSuccess
    | FetchAlarmsFailed
    | ResetAddAlarmForm
    | SetAddAlarmForm;
