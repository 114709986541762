import React from 'react';
import { TopPanelContainer } from '../../../styled';
import AddIcon from '@material-ui/core/SvgIcon/SvgIcon';
import Button from '@material-ui/core/Button';
import { ParameterDrawer } from '../ParameterDrawer';

interface TopPanelProps {
    isOpen: boolean;
    openAddParameter: () => void;
    closeAddParameter: () => void;
}

export function TopPanel(props: TopPanelProps) {
    function submitHandle() {}
    return (
        <TopPanelContainer>
            <ParameterDrawer
                onSubmitHandle={submitHandle}
                openAddParameter={props.openAddParameter}
                isOpen={props.isOpen}
                closeAddParameter={props.closeAddParameter}
            />
            <Button onClick={props.openAddParameter} variant="contained">
                <AddIcon />
                Dodaj nowy
            </Button>
        </TopPanelContainer>
    );
}
