import React from 'react';
import { Step } from '../../../../../../components/ProgressStepper/types';
import {
    Appearances,
    BasicData,
    Documents,
    Localization,
    PhotoGallery,
    Settings,
    Summary,
    Variables,
    Alarms,
    EngineSymbol,
    MapParameters,
} from '../../../../Templates/components';

export const addObjectSteps: Step[] = [
    {
        title: 'Informacje podstawowe',
        // @ts-ignore
        component: <BasicData />,
    },
    {
        title: 'Lokalizacja',
        // @ts-ignore
        component: <Localization />,
    },
    {
        title: 'Ustawienia obiektu',
        // @ts-ignore
        component: <Settings />,
    },
    {
        title: 'Konfiguracja zmiennych',
        // @ts-ignore
        component: <Variables />,
    },
    {
        title: 'Lista alarmów',
        // @ts-ignore
        component: <Alarms />,
    },
    {
        title: 'Symbol zębatki',
        // @ts-ignore
        component: <EngineSymbol />,
    },
    {
        title: 'Parametry obiektu na mapie',
        // @ts-ignore
        component: <MapParameters />,
    },
    {
        title: 'Wygląd interfejsu',
        // @ts-ignore
        component: <Appearances />,
    },
    {
        title: 'Galeria zdjęć',
        // @ts-ignore
        component: <PhotoGallery />,
    },
    {
        title: 'Dokumentacja',
        // @ts-ignore
        component: <Documents />,
    },
    {
        title: 'Podsumowanie',
        // @ts-ignore
        component: <Summary />,
    },
];
