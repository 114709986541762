import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducer';
import {
    addGroupForm,
    groupAddingPending,
    groupEditionPending,
    groupHasAdded,
    groupHasEdited,
} from '../selectors/groups';
import { Group } from '../types/Group';
import {
    addGroup,
    editGroup,
    getGroups,
    hydrateEditForm, removeGroupAction,
    resetStore,
} from '../actions/groups';

interface useAddEditGroupOutput {
    groupIsAdding: boolean;
    groupHasEditedState: boolean;
    groupEditionIsPending: boolean;
    newGroupHasAdded: boolean;
    getGroupsHandle: () => void;
    resetStoreHandle: () => void;
    addNewGroup: (group: Group) => void;
    removeGroup: (group: Group) => void;
    editGroupHandle: (group: Group) => void;
    hydrateForm: (group: Group) => void;
    stateAddNewGroup: Group;
}

export function useAddEditGroup(organizationId: number): useAddEditGroupOutput {
    const dispatch = useDispatch();

    const groupIsAdding = useSelector((state: AppState) =>
        groupAddingPending(state),
    );
    const groupHasEditedState = useSelector((state: AppState) =>
        groupHasEdited(state),
    );
    const groupEditionIsPending = useSelector((state: AppState) =>
        groupEditionPending(state),
    );

    const newGroupHasAdded = useSelector((state: AppState) =>
        groupHasAdded(state),
    );
    const stateAddNewGroup = useSelector((state: AppState) =>
        addGroupForm(state),
    );

    function addNewGroup(group: Group) {
        dispatch(addGroup(group, organizationId));
    }

    async function removeGroup(group: Group) {
        await removeGroupAction(organizationId, group);
        dispatch(getGroups(organizationId));
    }

    function editGroupHandle(group: Group) {
        dispatch(editGroup(group, organizationId));
    }

    function hydrateForm(group: Group) {
        return dispatch(hydrateEditForm(group));
    }

    function getGroupsHandle() {
        dispatch(getGroups(organizationId));
    }

    function resetStoreHandle() {
        dispatch(resetStore());
    }

    return {
        groupIsAdding,
        getGroupsHandle,
        newGroupHasAdded,
        groupHasEditedState,
        groupEditionIsPending,
        addNewGroup,
        removeGroup,
        editGroupHandle,
        resetStoreHandle,
        hydrateForm,
        stateAddNewGroup,
    };
}
