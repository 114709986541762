import styled, { css } from 'styled-components';

interface PointContainerProps {
    isLast: boolean;
}

export const PointContainer = styled.div<PointContainerProps>`
    display: flex;
    padding-bottom: 70px;
    position: relative;
    align-items: center;
    &:after {
        ${props => drawLine(props.isLast)}
    }
`;

function drawLine(lastLine: boolean) {
    if (lastLine) {
        return css``;
    }

    return css`
        content: '';
        position: absolute;
        border: 0.5px solid ${props => props.theme.colors.primary.border};
        top: 20px;
        bottom: 5px;
        left: 7px;
    `;
}
