import { request } from '../tools';
import { Community, County, Voivodeship } from '../api/api';
import {
    Community as ICommunity,
    County as ICounty,
    Voivodeship as IVoivodeship,
} from '../types';
import { ActionTypes } from '../constants/actionTypes/location';
import { Dispatch } from 'redux';

export function getVoivodeship() {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionTypes.FetchVoivodeshipies,
        });

        try {
            const voivodeships: IVoivodeship[] = await request<IVoivodeship[]>(
                'GET',
                Voivodeship,
            );
            dispatch(getVoivodeshipSuccess(voivodeships));
        } catch (error) {
            dispatch(getVoivodeshipFailed(JSON.stringify(error)));
        }
    };
}

function getVoivodeshipSuccess(voivodeships: IVoivodeship[]) {
    return {
        type: ActionTypes.FetchVoivodeshipiesSuccess,
        voivodeships,
    };
}

function getVoivodeshipFailed(message: string) {
    return {
        type: ActionTypes.FetchVoivodeshipiesFailed,
        message,
    };
}

export function getCounties(voivodeshipId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionTypes.FetchCounties,
        });

        try {
            const counties: ICounty[] = await request<ICounty[]>(
                'GET',
                County(voivodeshipId),
            );
            dispatch(getCountiesSuccess(counties));
        } catch (error) {
            dispatch(getCountiesFailed(JSON.stringify(error)));
        }
    };
}

function getCountiesSuccess(counties: ICounty[]) {
    return {
        type: ActionTypes.FetchCountiesSuccess,
        counties,
    };
}

function getCountiesFailed(message: string) {
    return {
        type: ActionTypes.FetchCountiesFailed,
        message,
    };
}

export function getCommunities(countyId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionTypes.FetchCommunities,
        });

        try {
            const communities: ICommunity[] = await request<ICommunity[]>(
                'GET',
                Community(countyId),
            );
            dispatch(getCommunitiesSuccess(communities));
        } catch (error) {
            dispatch(getCommunitiesFailed(JSON.stringify(error)));
        }
    };
}

function getCommunitiesSuccess(communities: ICommunity[]) {
    return {
        type: ActionTypes.FetchCommunitiesSuccess,
        communities,
    };
}

function getCommunitiesFailed(message: string) {
    return {
        type: ActionTypes.FetchCommunitiesFailed,
        message,
    };
}
