// @ts-ignore
import * as Yup from 'yup';

export const LicenseDataScheme = Yup.object().shape({
    userCounts: Yup.number()
        .min(1)
        .required('Required'),
    objectsCounts: Yup.number()
        .min(1)
        .required('Required'),
    modules: Yup.string().required('Required'),
    licenseType: Yup.string().required('Required'),
    smsPackage: Yup.number(),
});
