import { MachineObject } from '../types';

export enum ObjectsActionTypes {
    GetObjects = '[Objects] get objects',
    GetObjectsSuccessfully = '[Objects] get objects successfully',
    GetObjectsFailure = '[Objects] get objects failure',

    ChangeOrder = '[Objects] change order',

    GetObjectTypes = '[Objects] get objects types',
    GetObjectTypesSuccess = '[Objects] get objects types success',
    GetObjectTypesFailed = '[[Objects] get objects types failed',

    GetObjectParameters = '[Objects] get objects parameters',
    GetObjectParametersSuccess = '[Objects] get objects parameters success',
    GetObjectParametersFailed = '[Objects] get objects parameters failed',

    GetObjectSettings = '[Objects] get objects settings',
    GetObjectSettingsSuccess = '[Objects] get objects settings success',
    GetObjectSettingsFailed = '[Objects] get objects settings failed',

    UpdateAddObjectFormData = '[Objects] update add object form',
    ResetAddObjectFormData = '[Objects] reset add object form',
    ResetErrorsAddObjectFormData = '[Objects] reset erros add object form',

    SetRemovedParameters = '[Objects] set removed parameters',

    AddObject = '[Objects] add object',
    AddObjectSuccess = '[Objects] add object success',
    AddObjectFailed = '[Objects] add object failed',

    UpdateObject = '[Objects] update object',
    UpdateObjectSuccess = '[Objects] update object success',
    UpdateObjectFailed = '[Objects] update object failed',
}

export interface ResetErrorsAddObjectFormData {
    type: typeof ObjectsActionTypes.ResetErrorsAddObjectFormData;
}

export interface UpdateObject {
    type: typeof ObjectsActionTypes.UpdateObject;
}

export interface UpdateObjectSuccess {
    type: typeof ObjectsActionTypes.UpdateObjectSuccess;
}

export interface UpdateObjectFailed {
    type: typeof ObjectsActionTypes.UpdateObjectFailed;
    error: string;
}

export interface AddObject {
    type: typeof ObjectsActionTypes.AddObject;
}

export interface AddObjectSuccess {
    type: typeof ObjectsActionTypes.AddObjectSuccess;
}

export interface AddObjectFailed {
    type: typeof ObjectsActionTypes.AddObjectFailed;
    error: string;
}

export interface ResetAddObjectForm {
    type: typeof ObjectsActionTypes.ResetAddObjectFormData;
}

export interface GetObjectTypes {
    type: typeof ObjectsActionTypes.GetObjectTypes;
}

export interface GetObjectTypesSuccess {
    type: typeof ObjectsActionTypes.GetObjectTypesSuccess;
    objectTypes: any;
}

export interface GetObjectTypesFailed {
    type: typeof ObjectsActionTypes.GetObjectTypesFailed;
    error: string;
}

export interface GetObjectParameters {
    type: typeof ObjectsActionTypes.GetObjectParameters;
}

export interface SetRemovedParameters {
    type: typeof ObjectsActionTypes.SetRemovedParameters;
    parameters: string[];
}

export interface GetObjectParametersSuccess {
    type: typeof ObjectsActionTypes.GetObjectParametersSuccess;
    objectParameters: any;
}

export interface GetObjectParametersFailed {
    type: typeof ObjectsActionTypes.GetObjectParametersFailed;
    error: string;
}

export interface GetObjectSettings {
    type: typeof ObjectsActionTypes.GetObjectSettings;
}

export interface GetObjectSettingsSuccess {
    type: typeof ObjectsActionTypes.GetObjectSettingsSuccess;
    objectSettings: any;
}

export interface GetObjectSettingsFailed {
    type: typeof ObjectsActionTypes.GetObjectSettingsFailed;
    error: string;
}

export interface GetObjects {
    type: typeof ObjectsActionTypes.GetObjects;
    organizationId: number;
}

export interface GetObjectsSuccessfully {
    type: typeof ObjectsActionTypes.GetObjectsSuccessfully;
    objects: MachineObject[];
    organizationId: number;
}

export interface GetObjectsFailure {
    type: typeof ObjectsActionTypes.GetObjectsFailure;
    organizationId: number;
}

export interface ChangeOrder {
    type: typeof ObjectsActionTypes.ChangeOrder;
    organizationId: number;
    objects: MachineObject[];
}

export interface UpdateAddObjectForm {
    type: typeof ObjectsActionTypes.UpdateAddObjectFormData;
    formValue: Partial<MachineObject>;
    draftId: string;
}

export type ObjectsUnion =
    | UpdateAddObjectForm
    | ResetAddObjectForm
    | GetObjects
    | GetObjectsSuccessfully
    | GetObjectsFailure
    | GetObjectTypes
    | ChangeOrder
    | GetObjectTypesSuccess
    | GetObjectTypesFailed
    | GetObjectParameters
    | GetObjectParametersSuccess
    | GetObjectParametersFailed
    | GetObjectSettings
    | GetObjectSettingsSuccess
    | GetObjectSettingsFailed
    | AddObject
    | AddObjectSuccess
    | ResetErrorsAddObjectFormData
    | AddObjectFailed
    | UpdateObject
    | SetRemovedParameters
    | UpdateObjectSuccess
    | UpdateObjectFailed;
