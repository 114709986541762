import { AppState } from '../../../../reducer';
import { AlarmsReducerState } from '../reducer/alarmReducer';

function alarmsState(state: AppState): AlarmsReducerState {
    return state.alarms;
}

export function fetchedAlarms(state: AppState) {
    return alarmsState(state).alarms;
}

export function alarmsHasFetched(state: AppState) {
    return alarmsState(state).alarmsHasFetched;
}

export function alarmsFetching(state: AppState) {
    return alarmsState(state).alarmsFetching;
}

export function addAlarmFormData(state: AppState) {
    return alarmsState(state).formData;
}
