import { AddUserWithPermissions } from '../../types/AddUserWithPermissions';

export const initFormValues: AddUserWithPermissions = {
    name: '',
    surname: '',
    email: '',
    password: '',
    phoneMobile: '',
    organizationId: 0,
    permission: {
        allObjects: false,
        permission: {
            admin: false,
            admin_panel: false,
            alarms: true,
            description: 'Zapis do obiektów',
            label: 'Operator',
            read: true,
            value: 2,
            write: true,
        },
        objects: [],
    },
};
