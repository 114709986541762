import styled from 'styled-components';

export const IconContainer = styled.div`
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
`;
