import styled from 'styled-components';
import {
    spacing,
    palette,
    SpacingProps,
    PaletteProps,
} from '@material-ui/system';

const Box = styled.div<PaletteProps & SpacingProps>`${spacing}${palette}`;

export default Box;
