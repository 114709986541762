import React, { useState } from 'react';
import {
    ButtonWrapped,
    ContentContainer,
    ContentContainerWithButton,
} from '../../styled';
import { useDrawerVisible } from '../../../../../../../hooks';
import { EditBox } from '../EditBox/EditBox';
import { DndAppearance } from '../DndAppearance';
import { useObjectForm } from '../../../../../Objects/hooks';
import {
    AppearanceBase,
    BoxAppearance,
} from '../../../../../Objects/types/AppearanceBase';
import { generateRandomString } from '../../../../../../../tools';
import { BoxSizes } from '../../types/BoxSizes';

export function StatusAppearance() {
    const { drawerState, openDrawer, closeDrawer } = useDrawerVisible();
    const [state, setData] = useObjectForm();
    const [editedBox, setEditedBox] = useState<BoxAppearance>();

    function editBoxHandle(boxId: string) {
        setEditedBox(state.statusAppearance.box[boxId]);
        openDrawer();
    }

    function updateConfig(config: AppearanceBase) {
        setData({
            ...state,
            statusAppearance: config,
        });
    }

    function addNewBox() {
        const boxId = generateRandomString();
        const columnId = Object.keys(state.statusAppearance.columns)[0];
        updateConfig({
            ...state.statusAppearance,
            box: {
                ...state.statusAppearance.box,
                [boxId]: {
                    id: boxId,
                    size: BoxSizes.Small,
                    name: 'Nowy Box',
                    description: '',
                    parameters: [],
                    type: undefined,
                },
            },
            columns: {
                ...state.statusAppearance.columns,
                [columnId]: {
                    ...state.statusAppearance.columns[columnId],
                    boxIds: [
                        ...state.statusAppearance.columns[columnId].boxIds,
                        boxId,
                    ],
                },
            },
        });
    }

    function removeBox(id: string) {
        closeDrawer();
        let columnId = '';
        Object.keys(state.statusAppearance.columns).forEach(column => {
            if (state.statusAppearance.columns[column].boxIds.includes(id)) {
                columnId = column;
            }
        });
        const updatedConfig = { ...state };
        delete updatedConfig.statusAppearance.box[id];

        updateConfig({
            ...updatedConfig.statusAppearance,
            columns: {
                ...state.statusAppearance.columns,
                [columnId]: {
                    ...state.statusAppearance.columns[columnId],
                    boxIds: state.statusAppearance.columns[
                        columnId
                    ].boxIds.filter(boxId => boxId !== id),
                },
            },
        });
    }

    return (
        <ContentContainerWithButton>
            <ButtonWrapped onClick={addNewBox}>Dodaj nowy box</ButtonWrapped>
            <ContentContainer>
                <EditBox
                    parameters={state.parameters}
                    removeBox={removeBox}
                    updateConfig={updateConfig}
                    formData={editedBox!}
                    drawerIsOpen={drawerState}
                    closeDrawer={closeDrawer}
                    config={state.statusAppearance}
                />
                <DndAppearance
                    updateConfig={updateConfig}
                    config={state.statusAppearance}
                    editBoxHandle={editBoxHandle}
                />
            </ContentContainer>
        </ContentContainerWithButton>
    );
}
