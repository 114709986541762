import { Heading } from '../../../../../../../../components/DataGrid/types';

export const summaryMonthDataGridKeys: string[] = [
    'monthSummary',
    'monthSummaryOffer',
    'offer',
];
export const summaryMonthDataGridHeading: Heading[] = [
    {
        key: 'monthSummary',
        heading: 'SUMA MIESIĘCZNIE',
    },
    {
        key: 'monthSummaryOffer',
        heading: 'SUMA MIESIĘCZNIE Z RABATEM',
    },
    {
        key: 'offer',
        heading: 'RABAT',
    },
];
