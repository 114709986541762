import { User } from '../types';
import { initFormValues } from './constants/addUserInitState';
import { ActionTypes, UsersActionsUnion } from '../models/actionTypes';
import { AddUserWithPermissions } from '../types/AddUserWithPermissions';

export interface UsersState {
    addUserForm: AddUserWithPermissions;
    usersIsFetching: boolean;
    usersHasFetched: boolean;
    users: User[];
    usersFetchError: string;
    addEditUserIsPending: boolean;
    addFormIsOpen: boolean;
    editFormIsOpen: boolean;
    userAddError: string;
    userEditError: string;
    userHasUpdate: boolean;
    userHasAdded: boolean;
}

export const initState: UsersState = {
    addUserForm: initFormValues,
    users: [],
    usersHasFetched: false,
    usersIsFetching: false,
    usersFetchError: '',
    userAddError: '',
    userEditError: '',
    addEditUserIsPending: false,
    editFormIsOpen: false,
    addFormIsOpen: false,
    userHasUpdate: false,
    userHasAdded: false,
};

export interface UsersStateByOrganizationId {
    [key: string]: UsersState;
}

function usersReducer(
    state: UsersState = initState,
    action: UsersActionsUnion,
): UsersState {
    switch (action.type) {
        case ActionTypes.GetUsers: {
            return {
                ...state,
                usersIsFetching: true,
            };
        }

        case ActionTypes.GetUsersSuccess: {
            return {
                ...state,
                usersHasFetched: true,
                usersIsFetching: false,
                users: action.users,
            };
        }

        case ActionTypes.GetUserFailed: {
            return {
                ...state,
                usersIsFetching: false,
                usersHasFetched: false,
                usersFetchError: action.errorMessage,
            };
        }

        case ActionTypes.SetAddUserFormData: {
            return {
                ...state,
                addUserForm: { ...state.addUserForm, ...action.formData },
            };
        }

        case ActionTypes.ResetAddUserFormData: {
            return {
                ...state,
                addUserForm: initFormValues,
                userHasAdded: false,
                userHasUpdate: false,
            };
        }

        case ActionTypes.ResetErrors: {
            return {
                ...state,
                userAddError: '',
                userEditError: '',
                usersFetchError: '',
            };
        }

        case ActionTypes.EditUser: {
            return {
                ...state,
                addEditUserIsPending: true,
            };
        }

        case ActionTypes.EditUserSuccess: {
            return {
                ...state,
                userHasUpdate: true,
                addEditUserIsPending: false,
                userEditError: '',
                userAddError: '',
                userHasAdded: false,
            };
        }

        case ActionTypes.EditUserFailed: {
            return {
                ...state,
                userHasUpdate: false,
                addEditUserIsPending: false,
                userEditError: action.errorMessage,
                userAddError: '',
                userHasAdded: false,
            };
        }

        case ActionTypes.AddUser: {
            return {
                ...state,
                addEditUserIsPending: true,
            };
        }

        case ActionTypes.AddUserSuccess: {
            return {
                ...state,
                userHasAdded: true,
                addEditUserIsPending: false,
                userEditError: '',
                userAddError: '',
                userHasUpdate: false,
            };
        }

        case ActionTypes.AddUserFailed: {
            return {
                ...state,
                userHasAdded: false,
                addEditUserIsPending: false,
                userAddError: action.errorMessage,
                userEditError: '',
                userHasUpdate: false,
            };
        }

        case ActionTypes.CloseAddEditForm: {
            return {
                ...state,
                addFormIsOpen: false,
                editFormIsOpen: false,
            };
        }

        case ActionTypes.OpenAddForm: {
            return {
                ...state,
                addFormIsOpen: true,
                userHasAdded: false,
                addEditUserIsPending: false,
                userEditError: '',
                userAddError: '',
                userHasUpdate: false,
            };
        }

        case ActionTypes.OpenEditForm: {
            return {
                ...state,
                editFormIsOpen: true,
                userHasAdded: false,
                addEditUserIsPending: false,
                userEditError: '',
                userAddError: '',
                userHasUpdate: false,
                addUserForm: { ...state.addUserForm, ...action.formData },
            };
        }

        default:
            return state;
    }
}

export function userIdByOrganizationId(
    state: UsersStateByOrganizationId = {},
    action: UsersActionsUnion,
): UsersStateByOrganizationId {
    switch (action.type) {
        case ActionTypes.EditUserFailed:
        case ActionTypes.AddUserFailed:
        case ActionTypes.AddUserSuccess:
        case ActionTypes.EditUserSuccess:
        case ActionTypes.AddUser:
        case ActionTypes.EditUser:
        case ActionTypes.SetAddUserFormData:
        case ActionTypes.GetUserFailed:
        case ActionTypes.GetUsersSuccess:
        case ActionTypes.CloseAddEditForm:
        case ActionTypes.ResetAddUserFormData:
        case ActionTypes.OpenAddForm:
        case ActionTypes.ResetErrors:
        case ActionTypes.OpenEditForm:
        case ActionTypes.GetUsers: {
            return {
                ...state,
                [action.organizationId]: usersReducer(
                    state[action.organizationId],
                    action,
                ),
            };
        }

        default:
            return state;
    }
}
