import React from 'react';
import { FormikProps } from 'formik';

import { DataGrid } from '../../../../../../../../../components/DataGrid';
import { calculateClientPrice } from '../tools';
import { FormValues } from './types';
import { calculate } from './tools';
import { Input } from '../Input';
import { headings } from './constants/headings';

interface Data {
    module: string;
    unitPrice: React.ReactNode;
    clientPrice: React.ReactNode;
    allowance: React.ReactNode;
    licenseCount: React.ReactNode;
    monthSummary: React.ReactNode;
}

export function Grid(props: FormikProps<FormValues>) {
    async function onBlurHandle(event: any) {
        const rowId = event.target.name.split('-')[1];
        const result = calculate(props.values, rowId);
        const unitPriceKey: keyof Partial<
            FormValues
        > = `unitPrice-${rowId}` as keyof Partial<FormValues>;
        const allowanceKey: keyof Partial<
            FormValues
        > = `allowance-${rowId}` as keyof Partial<FormValues>;
        const clientPriceKey: keyof Partial<
            FormValues
        > = `clientPrice-${rowId}` as keyof Partial<FormValues>;
        const monthSummaryKey: keyof Partial<
            FormValues
        > = `monthSummary-${rowId}` as keyof Partial<FormValues>;

        const clientPriceResult = calculateClientPrice(
            props.values[unitPriceKey],
            props.values[allowanceKey],
        );
        await props.setFieldValue(
            event.target.name,
            Number(event.target.value),
        );
        await props.setFieldValue(clientPriceKey, Number(clientPriceResult));
        await props.setFieldValue(monthSummaryKey, Number(result));
        await props.submitForm();
    }

    const data: Data[] = [
        {
            module: 'Użytkownik',
            unitPrice: (
                <Input
                    type="number"
                    name="unitPrice-1"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['unitPrice-1']}
                />
            ),
            clientPrice: (
                <Input
                    type="number"
                    disabled
                    name="clientPrice-1"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['clientPrice-1']}
                />
            ),
            allowance: (
                <Input
                    type="number"
                    name="allowance-1"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['allowance-1']}
                />
            ),
            licenseCount: (
                <Input
                    type="number"
                    disabled
                    name="licenseCount-1"
                    onChange={props.handleChange}
                    value={props.values['licenseCount-1']}
                />
            ),
            monthSummary: (
                <Input
                    type="number"
                    disabled
                    name="monthSummary-1"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['monthSummary-1']}
                />
            ),
        },
        {
            module: 'Obiekt',
            unitPrice: (
                <Input
                    type="number"
                    name="unitPrice-2"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['unitPrice-2']}
                />
            ),
            clientPrice: (
                <Input
                    type="number"
                    disabled
                    name="clientPrice-2"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['clientPrice-2']}
                />
            ),
            allowance: (
                <Input
                    type="number"
                    name="allowance-2"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['allowance-2']}
                />
            ),
            licenseCount: (
                <Input
                    type="number"
                    disabled
                    name="licenseCount-2"
                    onChange={props.handleChange}
                    value={props.values['licenseCount-2']}
                />
            ),
            monthSummary: (
                <Input
                    type="number"
                    disabled
                    name="monthSummary-2"
                    onBlur={onBlurHandle}
                    onChange={props.handleChange}
                    value={props.values['monthSummary-2']}
                />
            ),
        },
    ];

    return (
        <form onSubmit={props.handleSubmit}>
            <DataGrid<Data>
                keys={[
                    'module',
                    'unitPrice',
                    'clientPrice',
                    'allowance',
                    'licenseCount',
                    'monthSummary',
                ]}
                headings={headings}
                data={data}
            />
        </form>
    );
}
