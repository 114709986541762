import React from 'react';
import { FormikProps } from 'formik';
import { TimeParameter } from '../../../../types/TimeParameter';
import {
    ContentContainer,
    InlineFieldContainer,
    InlineFieldDivider,
    StyledForm,
    VerticalDivider,
} from '../../../../../formStyled';
import { SelectBox, WideField } from '../../../../../../../styled/';
import {
    FormikAsyncSelect,
    FormikCheckBox,
    FormikSelect,
} from '../../../../../../../tools/FormikFields';
import { PARAMETER_TYPES } from '../selectData/selectData';
import { useSelectParameterForm } from '../../../../hooks/useSelectParameterForm';
import { useFetchAsyncSelectData } from '../../../../hooks/useFetchAsyncSelectData';
import { SubHeading } from '../../../SubHeading';
import { FormContainer } from '../../../../styled';
import { SubmitPanel } from '../../../SubmitPanel';
import { ParameterTypesNames } from '../../../../types/ParameterTypes';

interface FieldsProps extends FormikProps<TimeParameter> {
    onCancel: () => void;
}

export function Fields(props: FieldsProps) {
    const setSelectType = useSelectParameterForm<TimeParameter>(props);
    const {
        fetchTimeFormat,
        fetchUnits,
        fetchReadWrite,
    } = useFetchAsyncSelectData();
    return (
        <FormContainer>
            <SubHeading />
            <StyledForm onSubmit={props.handleSubmit}>
                <ContentContainer>
                    <WideField
                        name="name"
                        value={props.values.name?.label || ''}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.name && props.touched.name}
                        label="Nazwa"
                        variant="outlined"
                        disabled
                    />
                    <InlineFieldContainer>
                        <FormikSelect
                            name="type"
                            cacheOptions
                            options={PARAMETER_TYPES}
                            defaultValue={{
                                value: props.values.type,
                                label: ParameterTypesNames[props.values.type],
                            }}
                            error={props.errors.type && props.touched.type}
                            onChange={setSelectType}
                            onBlur={props.handleBlur}
                            placeholder="Typ parametru"
                        />
                        <InlineFieldDivider />
                        <FormikAsyncSelect
                            name="format"
                            cacheOptions
                            loadOptions={fetchTimeFormat}
                            defaultOptions
                            error={props.errors.format && props.touched.format}
                            onChange={(option: any) =>
                                props.setFieldValue('format', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Format"
                        />
                    </InlineFieldContainer>
                    <WideField
                        name="address"
                        value={props.values.address}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.address && props.touched.address}
                        label="Adres/Nazwa zmiennej"
                        variant="outlined"
                    />
                    <WideField
                        name="maxNumberCount"
                        value={props.values.maxNumberCount}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={
                            props.errors.maxNumberCount &&
                            props.touched.maxNumberCount
                        }
                        label="Maksymalna liczba cyfr"
                        variant="outlined"
                    />
                    <WideField
                        name="shortDescription"
                        value={props.values.shortDescription}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        multiline
                        rows={3}
                        error={
                            props.errors.shortDescription &&
                            props.touched.shortDescription
                        }
                        label="Krótki opis (do 19 znaków)"
                        variant="outlined"
                    />
                    <WideField
                        name="fullDescription"
                        value={props.values.fullDescription}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        multiline
                        rows={5}
                        error={
                            props.errors.fullDescription &&
                            props.touched.fullDescription
                        }
                        label="Pełny opis"
                        variant="outlined"
                    />
                </ContentContainer>
                <VerticalDivider />
                <ContentContainer>
                    <SelectBox>
                        <FormikAsyncSelect
                            name="unitRAW"
                            cacheOptions
                            defaultOptions
                            loadOptions={fetchUnits}
                            error={
                                props.errors.unitRAW && props.touched.unitRAW
                            }
                            onChange={(option: any) =>
                                props.setFieldValue('unitRAW', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Jednostka RAW"
                        />
                    </SelectBox>
                    <SelectBox>
                        <FormikAsyncSelect
                            name="presentationMode"
                            cacheOptions
                            defaultOptions
                            loadOptions={fetchUnits}
                            error={
                                props.errors.presentationMode &&
                                props.touched.presentationMode
                            }
                            onChange={(option: any) =>
                                props.setFieldValue('presentationMode', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Sposób prezentacji"
                        />
                    </SelectBox>
                    <SelectBox>
                        <FormikAsyncSelect
                            name="isOnlyForReading"
                            cacheOptions
                            defaultOptions
                            loadOptions={fetchReadWrite}
                            error={
                                props.errors.isOnlyForReading &&
                                props.touched.isOnlyForReading
                            }
                            onChange={(option: any) =>
                                props.setFieldValue('isOnlyForReading', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Zmienna tylko do odczytu"
                        />
                    </SelectBox>
                    <FormikCheckBox
                        name="isAvailableForChart"
                        checked={props.values.isAvailableForChart}
                        onChange={() =>
                            props.setFieldValue(
                                'isAvailableForChart',
                                !props.values.isAvailableForChart,
                            )
                        }
                        label="Dostępność na wykresie"
                    />
                </ContentContainer>
            </StyledForm>
            <SubmitPanel
                onCancel={props.onCancel}
                submitForm={props.submitForm}
            />
        </FormContainer>
    );
}
