import { Profile } from '../types';
import {
    ProfileActionTypes,
    ProfileUnion,
} from '../constants/actionTypes/pofile';

const initialState: Profile = {
    organizationId: 0,
    id: 0,
    isGlobalAdmin: false,
    name: '',
    surname: '',
    userType: 0,
    phoneMobile: '',
    email: '',
    isBlocked: false,
    addDate: '',
};

export function profileReducer(
    state: Profile = initialState,
    action: ProfileUnion,
): Profile {
    switch (action.type) {
        case ProfileActionTypes.RemoveProfileData: {
            return {
                ...initialState,
            };
        }

        case ProfileActionTypes.SetProfileData: {
            return {
                ...state,
                ...action.data,
            };
        }

        default:
            return state;
    }
}
