import { useEffect, useState } from 'react';
import { SelectOption } from '../../../../types/SelectOption';
import { useFetchAsyncSelectData } from '../../Parameters/hooks/useFetchAsyncSelectData';

interface UseTextualConfigurationOutput {
    hasFetched: boolean;
    isLoading: boolean;
    textual: SelectOption[];
}

export function useTextualConfiguration(): UseTextualConfigurationOutput {
    const { fetchTextualConfiguration } = useFetchAsyncSelectData();
    const [variablesState, setVariablesState] = useState<
        UseTextualConfigurationOutput
    >({
        hasFetched: false,
        isLoading: false,
        textual: [],
    });

    useEffect(() => {
        async function fetchVariablesHandle() {
            const data: SelectOption[] = await fetchTextualConfiguration();

            setVariablesState({
                ...variablesState,
                hasFetched: true,
                textual: data,
            });
        }

        if (!variablesState.hasFetched && !variablesState.isLoading) {
            setVariablesState({
                ...variablesState,
                isLoading: true,
            });
            fetchVariablesHandle();
        }
    }, [fetchTextualConfiguration, variablesState]);

    return variablesState;
}
