import { ParameterTypes } from '../../../../../types/ParameterTypes';
import { TimeParameter } from '../../../../../types/TimeParameter';

export const formInitValues: TimeParameter = {
    name: undefined,
    format: '',
    address: '',
    maxNumberCount: 0,
    fullDescription: '',
    shortDescription: '',
    type: ParameterTypes.Time,
    isAvailableForChart: false,
    unitRAW: undefined,
};
