import styled, { css } from 'styled-components';
import { BoxSize, BoxSizes } from '../../../types/BoxSizes';

interface BoxProps {
    size: BoxSize;
}

export const ContentContainer = styled.div<BoxProps>`
    ${props => getSize(props.size)}
    background-color: ${props => props.theme.colors.third.background};
    position: relative;
    text-align: center;
    margin-bottom: 10px;
`;

function getSize(size: BoxSize): any {
    switch (size) {
        case BoxSizes.Medium: {
            return css`
                width: 100%;
                height: 300px;
            `;
        }

        case BoxSizes.Small: {
            return css`
                width: 100%;
                height: 100px;
            `;
        }

        default:
            return getSize(BoxSizes.Medium);
    }
}
