import React from 'react';
import { FormikProps } from 'formik';
import { Group, ObjectAtGroup } from '../../../../types/Group';
import { WideField } from '../../../../../../../styled/';
import { Rows } from './components/Rows';
import { Button, Typography } from '@material-ui/core';
import { ButtonsBox } from './styled';

interface FieldsProps extends FormikProps<Group> {
    organizationId: number;
    isEdit: boolean;
}

export function Fields(props: FieldsProps) {
    function setRows(objects: ObjectAtGroup[]) {
        props.setValues({ ...props.values, objects });
    }

    return (
        <form onSubmit={props.handleSubmit}>
            <WideField
                name="label"
                value={props.values.label}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.label && props.touched.label}
                label="Nazwa grupy"
                variant="outlined"
            />
            <Typography variant="h5">Lista obiektów</Typography>
            <Rows
                organizationId={props.organizationId}
                objects={props.values.objects}
                setRows={setRows}
            />
            <ButtonsBox>
                <Button
                    onClick={props.submitForm}
                    color="primary"
                    variant="contained"
                >
                    {props.isEdit ? 'Edytuj' : 'Stwórz'}
                </Button>
            </ButtonsBox>
        </form>
    );
}
