import { Community, County, Voivodeship } from '../../types';

export enum ActionTypes {
    FetchCounties = '[Location] fetch counties',
    FetchCountiesSuccess = '[Location] fetch counties success',
    FetchCountiesFailed = '[Location] fetch counties failed',

    FetchCommunities = '[Location] fetch communities',
    FetchCommunitiesSuccess = '[Location] fetch communities success',
    FetchCommunitiesFailed = '[Location] fetch communities failed',

    FetchVoivodeshipies = '[Location] fetch voivodeshipies',
    FetchVoivodeshipiesSuccess = '[Location] fetch voivodeshipies success',
    FetchVoivodeshipiesFailed = '[Location] fetch voivodeshipies failed',
}

export interface FetchCounties {
    type: typeof ActionTypes.FetchCounties;
}

export interface FetchCountiesSuccess {
    type: typeof ActionTypes.FetchCountiesSuccess;
    counties: County[];
}

export interface FetchCountiesFailed {
    type: typeof ActionTypes.FetchCountiesFailed;
    message: string;
}

export interface FetchCommunities {
    type: typeof ActionTypes.FetchCommunities;
}

export interface FetchCommunitiesSuccess {
    type: typeof ActionTypes.FetchCommunitiesSuccess;
    communities: Community[];
}

export interface FetchCommunitiesFailed {
    type: typeof ActionTypes.FetchCommunitiesFailed;
    message: string;
}

export interface FetchVoivodeshipies {
    type: typeof ActionTypes.FetchVoivodeshipies;
}

export interface FetchVoivodeshipiesSuccess {
    type: typeof ActionTypes.FetchVoivodeshipiesSuccess;
    voivodeships: Voivodeship[];
}

export interface FetchVoivodeshipiesFailed {
    type: typeof ActionTypes.FetchVoivodeshipiesFailed;
    message: string;
}

export type LocationUnion =
    | FetchCounties
    | FetchCountiesSuccess
    | FetchCountiesFailed
    | FetchCommunities
    | FetchCommunitiesSuccess
    | FetchCommunitiesFailed
    | FetchVoivodeshipies
    | FetchVoivodeshipiesSuccess
    | FetchVoivodeshipiesFailed;
