import { MachineObject } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { addObject } from '../actions/objects';
import { AppState } from '../../../../reducer';
import {
    addObjectError,
    addObjectIsPending,
    addObjectSuccess,
} from '../selectors/objects';

type UseAddObject = [
    boolean,
    boolean,
    string,
    (formState: MachineObject) => void,
];

export function useAddObject(): UseAddObject {
    const dispatch = useDispatch();
    const isPending = useSelector((state: AppState) =>
        addObjectIsPending(state),
    );
    const error = useSelector((state: AppState) => addObjectError(state));
    const success = useSelector((state: AppState) => addObjectSuccess(state));

    function add(formState: MachineObject) {
        dispatch(addObject(formState));
    }

    return [isPending, success, error, add];
}
