import { Modal } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { InformationModalBody } from '../../../../../components/InformationModalBody';
import { ModalBody } from '../../../../../components/ModalBody';
import { BottomNavBar } from '../../../../../components/ProgressStepper/components';
import { NotificationHandlersContext } from '../../../../../containers/App/components/AppWithMenu/AppWithMenu';
import { cookiesParse } from '../../../../../tools/cookiesParse';
import {
    useAddObject,
    useObjectForm,
    useObjects,
} from '../../../Objects/hooks';
import { useDrafts } from '../../../Objects/hooks/useDrafts';
import { useTemplates } from '../../hooks/useTemplates';
import { Template } from '../../types/Template';
import { searchUsedParameters } from '../tools/searchUsedParameters';
import { UsedParametersList } from '../Variables/components/Form/component/UsedParametersList';
import { UsedParameters } from '../Variables/components/Form/Fields';
import {
    Alarms,
    BasicData,
    DevData,
    Galleries,
    Parameters,
    SettingsData,
} from './components';
import { Form } from './Form';

interface SummaryProps extends RouteComponentProps<{ id: string }> {
    prevStep: () => void;
    closeDrawer: () => void;
}

function Component(props: SummaryProps) {
    const { id } = props.match.params;
    const notifyHandlers = useContext(NotificationHandlersContext);
    const [, addSuccess, addError, add] = useAddObject();
    const isDevMode = cookiesParse('isDevMode') === 'isDevMode=true';
    const [, , , getObjects] = useObjects(Number(id));
    const [state, , resetForm, resetErrors] = useObjectForm();
    const { removeDraft } = useDrafts();
    const [usedParameters, setUsedParameters] = useState<UsedParameters>({
        isVisible: false,
        parameters: [],
    });
    const { fetchTemplates } = useTemplates();
    const metadata = state.basicData.template
        ? JSON.parse((state.basicData.template as Template).metadata)
        : false;

    const isNewTemplate = metadata
        ? !isEqual(
              {
                  alarms: state.alarms,
                  settings: {
                      textual: null,
                      numeric: state.settings.numeric.map(n => ({
                          ...n,
                          value: null,
                      })),
                  },
                  parameters: state.parameters,
                  statusAppearance: state.statusAppearance,
                  parametersAppearance: state.parametersAppearance,
                  engineSymbol: state.engineSymbol,
                  mapParameters: state.mapParameters,
              },
              {
                  ...metadata,
                  settings: {
                      textual: null,
                      numeric: metadata.settings.numeric.map((n: any) => ({
                          ...n,
                          value: null,
                      })),
                  },
              },
          )
        : true;
    let removedParameters: any = [];

    if (state.basicData.template && metadata) {
        removedParameters = metadata.parameters
            .map((parameter: any) => ({
                id: parameter.tempId,
                name: parameter.name.label,
            }))
            .filter(
                (tempId: any) =>
                    !state.parameters
                        .map(param => param.tempId)
                        .includes(tempId.id),
            );
    }

    function checkUsedParameters() {
        const parameters = removedParameters
            .map((param: any) => ({
                name: param.name,
                id: param.id,
                steps: searchUsedParameters(state, param.id),
            }))
            .filter((param: any) => Object.keys(param.steps).length > 0);
        if (removedParameters.length && parameters.length > 0) {
            setUsedParameters({
                isVisible: true,
                parameters,
            });
            return true;
        }
        return false;
    }

    useEffect(() => {
        checkUsedParameters();
        // eslint-disable-next-line
    }, []);

    function addHandle() {
        if (checkUsedParameters()) {
            return;
        }

        if (metadata && isNewTemplate && !state.summary.templateName) {
            notifyHandlers.showError(
                'Obecny szablon został ' +
                    'zmieniony co powoduje że zostanie stworzony nowy szablon. ' +
                    'Należy wprowadzić nazwę nowego szablonu',
            );
            return;
        }
        if (!metadata && isNewTemplate && !state.summary.templateName) {
            notifyHandlers.showError('Należy wprowadzić nazwę nowego szablonu');
            return;
        }
        add(state);
    }

    if (addSuccess) {
        removeDraft(state.draftId);
        getObjects();
        resetForm();
        props.closeDrawer();
        notifyHandlers.showSuccess('Obiekt został dodany');
        if (isNewTemplate) {
            fetchTemplates();
        }
    }

    if (addError) {
        notifyHandlers.showError(addError);
        resetErrors();
    }

    function prevStepHandle() {
        resetErrors();
        props.prevStep();
    }

    function closeInformationModal() {
        setUsedParameters({
            parameters: [],
            isVisible: false,
        });
    }

    return (
        <>
            {isNewTemplate ? <Form /> : null}
            <BasicData
                localization={state.localization}
                basicData={state.basicData}
            />
            <SettingsData settings={state.settings} />
            <Parameters parameter={state.parameters} />
            <Alarms alarms={state.alarms} />
            <Galleries galleries={state.photoGalleries} />
            {isDevMode ? <DevData data={state} /> : null}
            <BottomNavBar
                prevStep={prevStepHandle}
                nextStep={addHandle}
                showBack={true}
                buttonNextText="Dodaj"
            />
            <Modal
                open={usedParameters.isVisible}
                onClose={closeInformationModal}
            >
                <ModalBody closeModalOnCLick={closeInformationModal}>
                    <InformationModalBody
                        heading="Uwaga"
                        desc="Następujące parametry zostały usunięte ale wciąż są w innych krokach:"
                        okClick={closeInformationModal}
                    >
                        <UsedParametersList usedParameters={usedParameters} />
                    </InformationModalBody>
                </ModalBody>
            </Modal>
        </>
    );
}

export const Summary = withRouter(Component);
