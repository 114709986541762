import React from 'react';
import { FormikProps } from 'formik';

import RadioGroup from '@material-ui/core/RadioGroup';
import { FormControlLabel } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';

import { FormValues } from './types';

export function Fields(props: FormikProps<FormValues>) {
    const changeHandle = ({ target }: any) => {
        props.setFieldValue('paymentMethod', target.value);
    };

    return (
        <RadioGroup
            row
            name="paymentMethod"
            value={props.values.paymentMethod}
            onChange={changeHandle}
        >
            <FormControlLabel
                value="monthly"
                control={<Radio color="primary" />}
                label="Miesięczna"
            />
            <FormControlLabel
                value="annually"
                control={<Radio color="primary" />}
                label="Roczna"
            />
            <FormControlLabel
                value="once"
                control={<Radio color="primary" />}
                label="Jednorazowa"
            />
        </RadioGroup>
    );
}
