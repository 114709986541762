import React from 'react';
import { ContentContainer } from './styled';

interface EmptyPlugProps {
    plugText: string;
}

export function EmptyPlug(props: EmptyPlugProps) {
    return (
        <ContentContainer>
            <span>{props.plugText}</span>
        </ContentContainer>
    );
}
