import { Dispatch } from 'redux';
import { ActionTypes } from '../models/actionTypes';
import { Login } from '../../../api/api';
import {
    request,
    addItemToStorage,
    removeItemFromStorage,
} from '../../../tools';
import { StorageKeys } from '../../../constants/storageKeys';
import history from '../../../containers/App/tools/history';
import { removeProfileData, setProfileData } from '../../../actions/profile';

export function login(email: string, password: string) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionTypes.Authorization,
        });
        try {
            const { data } = await request(
                'POST',
                Login,
                JSON.stringify({ email, password }),
            );
            dispatch(setProfileData(data.user));
            dispatch(loginSuccess(data));
        } catch (err) {
            dispatch(loginFailed(err));
        }
    };
}

function loginSuccess(data: any) {
    addItemToStorage(StorageKeys.Token, data.token);
    addItemToStorage(StorageKeys.ProfileData, JSON.stringify(data.user));
    history.push('/');

    return {
        type: ActionTypes.AuthorizationSuccess,
    };
}

function loginFailed(error: object) {
    return {
        type: ActionTypes.AuthorizationFailed,
        error,
    };
}

export function logOut() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: ActionTypes.LogOut,
        });
        dispatch(removeProfileData());
        removeItemFromStorage(StorageKeys.Token);
        removeItemFromStorage(StorageKeys.ProfileData);
        history.push('/login');
    };
}
