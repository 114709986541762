import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { ContentContainer } from './styled';

interface DragAndDropZoneProps {
    changeHandle: (files: File[]) => void;
    acceptFiles: string[];
}

export function DragAndDropZone(props: DragAndDropZoneProps) {
    const onDrop = useCallback(
        acceptedFiles => {
            props.changeHandle(acceptedFiles);
        },
        [props],
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: props.acceptFiles,
    });

    return (
        <ContentContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <div>
                <div>Przeciągnij plik</div>
                <div>lub kliknij</div>
            </div>
        </ContentContainer>
    );
}
