import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';

import { ContentContainer } from './styled';
import { SideNavMenu } from '../../../../components/SideNavMenu';
import { EmptyPlug } from './components/EmptyPlug';
import {
    WithNotificationProps,
    withNotifications,
} from '../../../../hocs/withNotifications';
import { useGlobalAdmin } from '../../../../hooks/useGlobalAdmin';

interface AppWithMenuProps extends WithNotificationProps {
    children: React.ReactNode;
}

export const NotificationHandlersContext = React.createContext({
    showSuccess: (text: string) => {},
    showError: (text: string) => {},
});

function Component(props: AppWithMenuProps) {
    const [menuState, setMenuState] = useState<{
        drawerIsOpen: boolean;
        DrawerComponent: React.ReactNode;
    }>({
        drawerIsOpen: false,
        DrawerComponent: <EmptyPlug />,
    });
    const { DrawerComponent } = menuState;
    const { checkAndRedirect } = useGlobalAdmin();
    function openDrawer(child: React.ReactNode) {
        setMenuState({
            ...menuState,
            drawerIsOpen: true,
            DrawerComponent: child,
        });
    }

    useEffect(() => {
        checkAndRedirect();
    }, [checkAndRedirect]);

    function closeDrawer() {
        setMenuState({ ...menuState, drawerIsOpen: false });
    }

    return (
        <ContentContainer>
            <SideNavMenu
                closeDrawerHandle={closeDrawer}
                openDrawerHandle={openDrawer}
            />
            <Drawer open={menuState.drawerIsOpen} onClose={closeDrawer}>
                {DrawerComponent}
            </Drawer>
            <NotificationHandlersContext.Provider
                value={{
                    showSuccess: props.showSuccessMessage,
                    showError: props.showFailedMessage,
                }}
            >
                {props.children}
            </NotificationHandlersContext.Provider>
        </ContentContainer>
    );
}

export const AppWithMenu = withNotifications(Component);
