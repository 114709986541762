import React from 'react';
import { Formik, FormikProps } from 'formik';
import { FormValues } from './types';
import { Grid } from './Grid';
import { useOrganizationFormState } from '../../../../hooks/useOrganizationFormState';

export function ModulesTable() {
    const [state, setState] = useOrganizationFormState();

    const onSubmit = (values: any) => {
        setState({ ...state, billing: { ...state.billing, modules: values } });
    };

    return (
        <Formik
            initialValues={{
                'unitPrice-1': state.billing.modules['unitPrice-1'],
                'clientPrice-1': state.billing.modules['clientPrice-1'],
                'allowance-1': state.billing.modules['allowance-1'],
                'summaryPrice-1': state.billing.modules['summaryPrice-1'],

                'unitPrice-2': state.billing.modules['unitPrice-2'],
                'clientPrice-2': state.billing.modules['clientPrice-2'],
                'allowance-2': state.billing.modules['allowance-2'],
                'summaryPrice-2': state.billing.modules['summaryPrice-2'],

                'unitPrice-3': state.billing.modules['unitPrice-3'],
                'clientPrice-3': state.billing.modules['clientPrice-3'],
                'allowance-3': state.billing.modules['allowance-3'],
                'summaryPrice-3': state.billing.modules['clientPrice-3'],

                'unitPrice-4': state.billing.modules['unitPrice-4'],
                'clientPrice-4': state.billing.modules['clientPrice-4'],
                'allowance-4': state.billing.modules['allowance-4'],
                'summaryPrice-4': state.billing.modules['summaryPrice-4'],

                'unitPrice-5': state.billing.modules['unitPrice-5'],
                'clientPrice-5': state.billing.modules['unitPrice-5'],
                'allowance-5': state.billing.modules['allowance-5'],
                'summaryPrice-5': state.billing.modules['summaryPrice-5'],

                'unitPrice-6': state.billing.modules['unitPrice-6'],
                'clientPrice-6': state.billing.modules['clientPrice-6'],
                'allowance-6': state.billing.modules['allowance-6'],
                'summaryPrice-6': state.billing.modules['summaryPrice-6'],

                'unitPrice-7': state.billing.modules['unitPrice-7'],
                'clientPrice-7': state.billing.modules['clientPrice-7'],
                'allowance-7': state.billing.modules['allowance-7'],
                'summaryPrice-7': state.billing.modules['summaryPrice-7'],
            }}
            onSubmit={onSubmit}
        >
            {(props: FormikProps<FormValues>) => <Grid {...props} />}
        </Formik>
    );
}
