import React from 'react';
import { Grid, List } from '@material-ui/core';
import { ResultItem } from '..';
import { Organization } from '../../../../../../types';
import { ContentContainer, ListContainer } from './styled';
import { EmptyPlug } from '../../../../../EmptyPlug';

interface SearchResultProps {
    children?: React.ReactNode;
    closeDrawerHandle: () => void;
    foundElements: Organization[];
}

export function SearchResult(props: SearchResultProps) {
    const renderItems = () =>
        props.foundElements.map((item: Organization) => (
            <ResultItem
                closeDrawerHandle={props.closeDrawerHandle}
                key={item.id}
                id={item.id}
                objectsCount={10}
                title={item.name}
            />
        ));
    return (
        <ContentContainer>
            <Grid container spacing={2}>
                <ListContainer item>
                    <h3>Wyniki wyszukiwania</h3>
                    <div>
                        {props.foundElements.length ? (
                            <List dense={false}>{renderItems()}</List>
                        ) : (
                            <EmptyPlug plugText="Brak wynikow wyszukiwania" />
                        )}
                    </div>
                </ListContainer>
            </Grid>
        </ContentContainer>
    );
}
