import { AppState } from '../../../../reducer';
import { TemplatesState } from '../reducer/templates';
import { Template } from '../types/Template';

function templatesState(state: AppState): TemplatesState {
    return state.templates;
}

export function templatesIsFetching(state: AppState) {
    return templatesState(state).templatesIsFetching;
}

export function templatesHasFetched(state: AppState) {
    return templatesState(state).templatesHasFetched;
}

export function fetchedTemplates(state: AppState): Template[] {
    return templatesState(state).templates;
}

export function addingTemplateIsPending(state: AppState) {
    return templatesState(state).addingTemplateIsPending;
}

export function addingTemplateError(state: AppState) {
    return templatesState(state).addingTemplateError;
}

export function templateHasAdded(state: AppState) {
    return templatesState(state).templateHasAdded;
}
