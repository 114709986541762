import { useDispatch } from 'react-redux';
import { ParameterType } from '../types/ParameterTypes';
import { setParameterType } from '../actions/parameters';
import { FormikProps } from 'formik';
import { BaseParameter } from '../types/BaseParameter';

export function useSelectParameterForm<T extends Partial<BaseParameter>>(
    formikProps: FormikProps<T>,
) {
    const dispatch = useDispatch();

    return (parameter: { value: ParameterType; label: ParameterType }) => {
        formikProps.setFieldValue('type', parameter.value);
        dispatch(setParameterType(parameter.value));
    };
}
