export function addItemToStorage(itemKey: string, value: string) {
    localStorage.setItem(itemKey, value);
}

export function getItemFromStorage(itemKey: string): string | null {
    return localStorage.getItem(itemKey);
}

export function removeItemFromStorage(itemKey: string) {
    localStorage.removeItem(itemKey);
}
