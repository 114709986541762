import React from 'react';
import { FormikProps } from 'formik';

import { FormikSelect } from '../../../../../../../../tools/FormikFields';
import { licenseDuration, licenseType, modules } from './constants';
import { BottomNavBar } from '../../../../../../../../components';
import { SelectOptions } from '../../../../../../../../types';
import { Flex } from '../../../../../../../../styled/';
import { TimeTypeSectionContainer } from './styled';
import { SelectBox, WideField } from '../../styled';
import { TimeTypeLicenseFields } from '../../';
import { DividerBox } from '../../../styled';
import { FormValues } from './types';

interface FieldsProps extends FormikProps<FormValues> {
    prevStep: () => void;
}

export function Fields(props: FieldsProps) {
    const smsPackageRound = ({ target }: any) => {
        if (target.value % 100 !== 0) {
            props.setFieldValue(
                'smsPackage',
                Math.ceil(target.value / 100) * 100,
            );
        }
    };

    const onChangeHandle = (value: any, { action, removedValue }: any) => {
        switch (action) {
            case 'remove-value':
            case 'pop-value':
                if (removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                value = modules.filter(v => v.isFixed);
                break;
        }
        props.setFieldValue(
            'modules',
            value.map((opt: SelectOptions) => opt.value),
        );
    };

    return (
        <form onSubmit={props.handleSubmit}>
            <Flex flexDirection="row">
                <WideField
                    name="userCounts"
                    type="number"
                    value={props.values.userCounts}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.userCounts && props.touched.userCounts}
                    label="Liczba użytkowników"
                    variant="outlined"
                />
                <DividerBox />
                <WideField
                    name="objectsCounts"
                    type="number"
                    value={props.values.objectsCounts}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                        props.errors.objectsCounts &&
                        props.touched.objectsCounts
                    }
                    label="Liczba obiektów"
                    variant="outlined"
                />
            </Flex>
            <SelectBox>
                <FormikSelect
                    name="modules"
                    isMulti
                    isClearable={props.values.modules.some(v =>
                        modules.find(
                            ({ value, isFixed }) => value === v && !isFixed,
                        ),
                    )}
                    value={
                        props.values.modules
                            ? modules.filter(module =>
                                  props.values.modules.includes(module.value),
                              )
                            : ''
                    }
                    options={modules}
                    onChange={onChangeHandle}
                    onBlur={props.handleBlur}
                    error={props.errors.modules && props.touched.modules}
                    label="Moduły"
                />
            </SelectBox>
            <SelectBox>
                <FormikSelect
                    name="licenseType"
                    value={
                        props.values.licenseType
                            ? licenseType.filter(
                                  (type: SelectOptions<number>) =>
                                      type.value === props.values.licenseType,
                              )
                            : ''
                    }
                    options={licenseType}
                    onChange={(option: SelectOptions<number>) =>
                        props.setFieldValue('licenseType', option.value)
                    }
                    onBlur={props.handleBlur}
                    error={
                        props.errors.licenseType && props.touched.licenseType
                    }
                    label="License"
                />
            </SelectBox>
            <TimeTypeSectionContainer>
                {props.values.licenseType === 1 ? (
                    <TimeTypeLicenseFields<FormValues, number>
                        {...props}
                        dateFromKey="dateFrom"
                        dateToKey="dateTo"
                        durationKey="licenseDuration"
                        selectOptions={licenseDuration}
                    />
                ) : null}
            </TimeTypeSectionContainer>
            <WideField
                name="smsPackage"
                type="number"
                value={props.values.smsPackage}
                onChange={props.handleChange}
                onBlur={props.handleBlur && smsPackageRound}
                error={props.errors.smsPackage && props.touched.smsPackage}
                label="Pakiet SMS"
                variant="outlined"
            />
            <BottomNavBar
                prevStep={props.prevStep}
                showNext={true}
                buttonNextText="Dalej"
            />
        </form>
    );
}
