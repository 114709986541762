import React from 'react';
import { GroupWork } from '@material-ui/icons';
import { MenuItem } from '../../../../components/DeepMenu/components/Menu/types';

export const menuItems: MenuItem[] = [
    {
        title: 'Klient',
        icon: <GroupWork />,
        url: '/manage/organizations',
    },
];
