import React, { useState } from 'react';
import {
    ButtonWrapped,
    ContentContainer,
    ContentContainerWithButton,
} from '../../styled';
import { DndAppearance } from '../DndAppearance';
import { useDrawerVisible } from '../../../../../../../hooks';
import { useObjectForm } from '../../../../../Objects/hooks';
import {
    AppearanceBase,
    BoxAppearance,
} from '../../../../../Objects/types/AppearanceBase';
import { EditBox } from '../EditBox/EditBox';
import { generateRandomString } from '../../../../../../../tools';
import { BoxSizes } from '../../types/BoxSizes';

export function ParametersAppearance() {
    const { drawerState, openDrawer, closeDrawer } = useDrawerVisible();
    const [state, setData] = useObjectForm();
    const [editedBox, setEditedBox] = useState<BoxAppearance>();

    function editBoxHandle(boxId: string) {
        setEditedBox(state.parametersAppearance.box[boxId]);
        openDrawer();
    }

    function updateConfig(config: AppearanceBase) {
        setData({
            ...state,
            parametersAppearance: config,
        });
    }

    function addNewBox() {
        const boxId = generateRandomString();
        const columnId = Object.keys(state.parametersAppearance.columns)[0];
        updateConfig({
            ...state.parametersAppearance,
            box: {
                ...state.parametersAppearance.box,
                [boxId]: {
                    id: boxId,
                    size: BoxSizes.Small,
                    name: 'Nowy Box',
                    description: '',
                    parameters: [],
                    type: undefined,
                },
            },
            columns: {
                ...state.parametersAppearance.columns,
                [columnId]: {
                    ...state.parametersAppearance.columns[columnId],
                    boxIds: [
                        ...state.parametersAppearance.columns[columnId].boxIds,
                        boxId,
                    ],
                },
            },
        });
    }

    function removeBox(id: string) {
        closeDrawer();
        let columnId = '';
        Object.keys(state.parametersAppearance.columns).forEach(column => {
            if (
                state.parametersAppearance.columns[column].boxIds.includes(id)
            ) {
                columnId = column;
            }
        });
        const updatedConfig = { ...state };
        delete updatedConfig.parametersAppearance.box[id];

        updateConfig({
            ...updatedConfig.parametersAppearance,
            columns: {
                ...state.parametersAppearance.columns,
                [columnId]: {
                    ...state.parametersAppearance.columns[columnId],
                    boxIds: state.parametersAppearance.columns[
                        columnId
                    ].boxIds.filter(boxId => boxId !== id),
                },
            },
        });
    }

    return (
        <ContentContainerWithButton>
            <ButtonWrapped onClick={addNewBox}>Dodaj nowy box</ButtonWrapped>
            <ContentContainer>
                <EditBox
                    parameters={state.parameters}
                    formData={editedBox!}
                    removeBox={removeBox}
                    drawerIsOpen={drawerState}
                    closeDrawer={closeDrawer}
                    updateConfig={updateConfig}
                    config={state.parametersAppearance}
                />
                <DndAppearance
                    updateConfig={updateConfig}
                    config={state.parametersAppearance}
                    editBoxHandle={editBoxHandle}
                />
            </ContentContainer>
        </ContentContainerWithButton>
    );
}
