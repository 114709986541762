import React from 'react';
import { ContentBox, ContentContainer, Overlay } from './styled';

interface ModalBodyProps {
    children: any;
    closeModalOnCLick: () => void;
}

export function ModalBody(props: ModalBodyProps) {
    return (
        <ContentContainer>
            <Overlay onClick={props.closeModalOnCLick} />
            <ContentBox>{props.children}</ContentBox>
        </ContentContainer>
    );
}
