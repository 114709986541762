import React from 'react';
import { Menu, MenuItem } from './components';
import { ContentContainer } from './styled';

interface DeepMenuContainerProps {
    children: React.ReactNode;
    menuItems: MenuItem[];
    header: string;
    headerLinkPath: string;
}

export function DeepMenu(props: DeepMenuContainerProps) {
    return (
        <ContentContainer>
            <Menu
                header={props.header}
                menuItems={props.menuItems}
                headerLinkPath={props.headerLinkPath}
            />
            {props.children}
        </ContentContainer>
    );
}
