import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Fields } from './Fields';
import { useObjectForm } from '../../../../../Objects/hooks';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Alarm } from '../../../../../Alarms/types/Alarm';
import { validateRow } from '../../../../../../../tools/validateFields';

interface FormProps extends RouteComponentProps<{ id: string }> {
    prevStepHandle: () => void;
    nextStepHandle: () => void;
}

export function FormComponent(props: FormProps) {
    const [state, setData] = useObjectForm();
    const [errors, setErrors] = useState<string[]>([]);

    function prevHandle(values: Alarm[]) {
        setData({ ...state, alarms: values });
        props.prevStepHandle();
    }

    function onSubmitHandle(values: Alarm[]) {
        setErrors([]);
        const alarmsError = validateRow(values, [
            'name',
            'priority',
            'variableName',
        ]);

        if (alarmsError.length) {
            setErrors(alarmsError);
            return;
        }

        setData({ ...state, alarms: values });
        props.nextStepHandle();
    }

    return (
        <Formik<Alarm[]>
            enableReinitialize={true}
            initialValues={state.alarms}
            onSubmit={onSubmitHandle}
            render={(formikProps: FormikProps<Alarm[]>) => (
                <Fields
                    alarmsRowErrors={errors}
                    prevStepHandle={prevHandle}
                    {...formikProps}
                />
            )}
        />
    );
}

export const Form = withRouter(FormComponent);
