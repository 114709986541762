import styled, { css } from 'styled-components';
import { Flex } from '../../../../../../styled/';

interface RowWithErrorProps {
    hasError: boolean;
}

export const RowWithError = styled(Flex)<RowWithErrorProps>`
    height: 65px;
    padding-top: 10px;
    @keyframes errorNotify {
        0% {
            background-color: antiquewhite;
        }
        100% {
            background-color: transparent;
        }
    }

    ${({ hasError }) =>
        hasError
            ? css`
                  animation: errorNotify 3s;
              `
            : ''}
`;
