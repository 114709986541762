import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { DayPickerInputProps } from 'react-day-picker';
import 'moment/locale/pl';

import { DatePickerWrapper } from './styled';

interface DatePickerProps extends Omit<DayPickerInputProps, 'format'> {}

export function DatePicker(props: DatePickerProps) {
    return (
        <DatePickerWrapper>
            <DayPickerInput
                {...props}
                format="L"
                dayPickerProps={{
                    locale: 'pl',
                    // @ts-ignore
                    localeUtils: MomentLocaleUtils,
                }}
            />
        </DatePickerWrapper>
    );
}
