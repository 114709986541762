import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ContentContainer, IconContainer } from './styled';
import EditIcon from '@material-ui/icons/Edit';
import { BoxAppearance } from '../../../../../Objects/types/AppearanceBase';

interface BoxProps {
    box: BoxAppearance;
    index: number;
    editHandle: (boxId: string) => void;
}

export function Box(props: BoxProps) {
    function editClickHandle() {
        props.editHandle(props.box.id);
    }

    return (
        <Draggable index={props.index} draggableId={props.box.id}>
            {provided => (
                <ContentContainer
                    size={props.box.size}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <h4
                        style={{
                            margin: 0,
                            whiteSpace: 'normal',
                            padding: '20px',
                        }}
                    >
                        {props.box.name}
                    </h4>
                    <IconContainer>
                        <EditIcon onClick={editClickHandle} />
                    </IconContainer>
                </ContentContainer>
            )}
        </Draggable>
    );
}
