import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { SelectBox } from '../../../../../Variables/components/Form/component/Row/styled';
import {
    FormikSelect,
    FormikTextField,
} from '../../../../../../../../../tools/FormikFields';
import { BinaryParameter } from '../../../../../../../Parameters/types/BinaryParameter';
import { RowParameter } from '../../../../../../../Objects/types/EngineSymbol';
import { getTempIdOrId } from '../../../../../tools/getTempIdOrId';
import { RowWithError } from '../../../../../../../Objects/components/TopPanel/styled';

interface RowProps {
    item: RowParameter;
    options: BinaryParameter[];
    isChecked: boolean;
    selectRow: (id: string) => void;
    setParameter: (item: RowParameter, parameter: BinaryParameter) => void;
    setValue: (item: RowParameter, value: number) => void;
    hasError: boolean;
}

export function Row(props: RowProps) {
    function setOption(option: any) {
        props.setParameter(props.item, option);
    }

    function setValue(event: any) {
        props.setValue(props.item, Number(event.target.value));
    }

    function onCheckHandle() {
        props.selectRow(props.item.id);
    }

    return (
        <RowWithError hasError={props.hasError}>
            <Checkbox
                checked={props.isChecked}
                onChange={onCheckHandle}
                value="checkedB"
                color="primary"
            />
            <SelectBox>
                <FormikSelect
                    name="parameter"
                    cacheOptions
                    options={props.options}
                    value={props.item.parameter}
                    getOptionLabel={(opt: BinaryParameter) => opt.name!.label}
                    getOptionValue={(opt: BinaryParameter) =>
                        getTempIdOrId(opt)
                    }
                    onChange={setOption}
                    placeholder="Parameter"
                />
            </SelectBox>
            <SelectBox>
                <FormikTextField
                    name="value"
                    type="number"
                    max={1}
                    value={props.item.value}
                    variant="outlined"
                    onChange={setValue}
                    placeholder="Wartość"
                />
            </SelectBox>
        </RowWithError>
    );
}
