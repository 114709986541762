import React from 'react';
import { Step } from '../../../../components/ProgressStepper/types';
import { Localization, Settings, BasicData } from '../../Templates/components';

export const editObjectSteps: Step[] = [
    {
        title: 'Informacje podstawowe',
        // @ts-ignore
        component: <BasicData />,
    },
    {
        title: 'Lokalizacja',
        // @ts-ignore
        component: <Localization />,
    },
    {
        title: 'Ustawienia obiektu',
        // @ts-ignore
        component: <Settings />,
    },
];
