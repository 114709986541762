import React from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/core/SvgIcon/SvgIcon';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';

import { Form } from '../../Form/Form';
import { AccountType } from '../../Form/types';

interface ComponentProps {
    isExpanded: boolean;
    onChangeHandle: () => void;
    heading: string;
    fieldsKey: AccountType;
    forwardedRef: any;
}

export function ContactExpansionPanel(props: ComponentProps) {
    return (
        <ExpansionPanel
            expanded={props.isExpanded}
            onChange={props.onChangeHandle}
        >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span>{props.heading}</span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Form
                    accountType={props.fieldsKey}
                    fieldsKey={props.fieldsKey}
                    forwardedRef={props.forwardedRef}
                />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}
