import styled from 'styled-components';
import { PointCircleProps } from '../types';

export const TextContainer = styled.div<PointCircleProps>`
    margin-left: 10px;
    color: ${props =>
        props.isActive
            ? props.theme.colors.black
            : props.isApprove
            ? props.theme.colors.black
            : props.theme.colors.primary.border};
`;
