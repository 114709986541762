import React from 'react';
import { Formik, FormikProps } from 'formik';
import { useObjectForm } from '../../../Objects/hooks';
import { Summary } from '../../../Objects/types';
import { Fields } from './Fields';
import { templateNameValidationScheme } from './constants/fieldValidation';

export function Form() {
    const [state, setData] = useObjectForm();

    function onSubmitHandle(value: Summary) {
        setData({ ...state, summary: value });
    }

    return (
        <Formik
            onSubmit={onSubmitHandle}
            enableReinitialize={true}
            initialValues={state.summary}
            validationSchema={templateNameValidationScheme}
            render={(otherProps: FormikProps<Summary>) => (
                <Fields {...otherProps} />
            )}
        />
    );
}
