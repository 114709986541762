import React, { useState } from 'react';

import { BottomNavBar } from '../../../../../../../components';
import { onSubmitFormik } from './Form/types/OnSubmitFormik';
import { ContactExpansionPanel } from './components';

interface ContactDataProps {
    prevStep: () => void;
    nextStep: () => void;
}

export function ContactData(props: ContactDataProps) {
    const [expanded, setExpanded] = useState({
        majorContact: true,
        technicalContact: false,
        accountContact: false,
    });

    const majorFieldsRef = React.createRef<onSubmitFormik>();
    const technicalFieldsRef = React.createRef<onSubmitFormik>();
    const accountantFieldsRef = React.createRef<onSubmitFormik>();

    const nextStep = async () => {
        await majorFieldsRef.current!.onSubmit();
        await technicalFieldsRef.current!.onSubmit();
        await technicalFieldsRef.current!.onSubmit();
        props.nextStep();
    };

    const setMajorExpand = () =>
        setExpanded({ ...expanded, majorContact: !expanded.majorContact });
    const setTechnicalExpand = () =>
        setExpanded({
            ...expanded,
            technicalContact: !expanded.technicalContact,
        });
    const setAccountExpand = () =>
        setExpanded({ ...expanded, accountContact: !expanded.accountContact });

    return (
        <>
            <ContactExpansionPanel
                forwardedRef={majorFieldsRef}
                isExpanded={expanded.majorContact}
                onChangeHandle={setMajorExpand}
                heading="Kontakt główny"
                fieldsKey="majorContact"
            />
            <ContactExpansionPanel
                isExpanded={expanded.technicalContact}
                onChangeHandle={setTechnicalExpand}
                heading="Kontakt techniczny"
                fieldsKey="technicalContact"
                forwardedRef={technicalFieldsRef}
            />
            <ContactExpansionPanel
                isExpanded={expanded.accountContact}
                onChangeHandle={setAccountExpand}
                heading="Kontakt księgowy"
                fieldsKey="accountantContact"
                forwardedRef={accountantFieldsRef}
            />
            <BottomNavBar
                prevStep={props.prevStep}
                nextStep={nextStep}
                showNext={true}
                buttonNextText="Dalej"
            />
        </>
    );
}
