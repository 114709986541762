import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { WrappedTableRow } from './styled';

interface BodyItemProps {
    item: JSX.Element;
}

function Component(props: BodyItemProps) {
    return <WrappedTableRow>{props.item}</WrappedTableRow>;
}

export const BodyItem = SortableElement(Component);
