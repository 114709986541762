import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { Box } from '../Box';
import { ContentContainer } from './styled';
import {
    BoxAppearance,
    ColumnAppearance,
} from '../../../../../Objects/types/AppearanceBase';

interface ColumnProps {
    column: ColumnAppearance;
    boxes: BoxAppearance[];
    editHandle: (boxId: string) => void;
}

export function Column(props: ColumnProps) {
    function renderBox() {
        return props.boxes.map((box, index) => (
            <Box
                editHandle={props.editHandle}
                index={index}
                key={box.id}
                box={box}
            />
        ));
    }

    return (
        <Droppable droppableId={props.column.id}>
            {provided => (
                <ContentContainer
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {renderBox()}
                </ContentContainer>
            )}
        </Droppable>
    );
}
