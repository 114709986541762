import { Heading } from '../../../../../../../../components/DataGrid/types';

export const headingNumericKeys = ['name', 'value', 'unit'];

export const headingsNumeric: Heading[] = [
    {
        key: 'name',
        heading: 'Nazwa',
    },
    {
        key: 'value',
        heading: 'Wartość',
    },
    {
        key: 'unit',
        heading: 'Jednostka',
    },
];

export const headingTextualKeys = ['name', 'description'];

export const headingTextual: Heading[] = [
    {
        key: 'name',
        heading: 'Nazwa',
    },
    {
        key: 'description',
        heading: 'Opis',
    },
];
