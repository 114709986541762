import React from 'react';
import { FormikProps } from 'formik';
import { Summary } from '../../../Objects/types';
import { WideField } from '../../../../../styled/';

interface FieldsProps extends FormikProps<Summary> {}

export function Fields(props: FieldsProps) {
    return (
        <form onSubmit={props.handleSubmit}>
            <WideField
                name="templateName"
                value={props.values.templateName}
                onChange={props.handleChange}
                onBlur={props.handleSubmit}
                error={props.errors.templateName && props.touched.templateName}
                label="Nazwa"
                variant="outlined"
            />
        </form>
    );
}
