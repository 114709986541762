import React, { useState } from 'react';
import { NumericSettings, Settings } from '../../../../../../../Objects/types';
import Checkbox from '@material-ui/core/Checkbox';

import {
    FormikCreteableSelect,
    FormikSelect,
    FormikTextField,
} from '../../../../../../../../../tools/FormikFields';
import { SelectBox } from '../../../../../Variables/components/Form/component/Row/styled';
import { FormikProps } from 'formik';
import { Unit } from '../../../../../../../../../types';
import { getTempIdOrId } from '../../../../../tools/getTempIdOrId';
import { RowWithError } from '../../../../../../../Objects/components/TopPanel/styled';
import { SelectOption } from '../../../../../../../../../types/SelectOption';

interface NumericRowProps {
    setting: NumericSettings;
    isChecked: boolean;
    onCheck: (setting: NumericSettings) => void;
    formikProps: FormikProps<Settings>;
    unitsById: (typeId?: number) => Unit[];
    hasError: boolean;
    isEdit: boolean;
    numerical: SelectOption[];
}

export function NumericRow(props: NumericRowProps) {
    const [units, setUnits] = useState();
    function onCheckHandle() {
        props.onCheck(props.setting);
    }

    function setName(option: any) {
        if (option) {
            setUnits(props.unitsById(option.unitTypeId));
        }
        props.formikProps.setValues({
            ...props.formikProps.values,
            numeric: props.formikProps.values.numeric.map(numeric => {
                if (getTempIdOrId(numeric) === getTempIdOrId(props.setting)) {
                    return {
                        ...numeric,
                        name: option,
                    };
                }
                return numeric;
            }),
        });
    }

    function setValue(event: any) {
        props.formikProps.setValues({
            ...props.formikProps.values,
            numeric: props.formikProps.values.numeric.map(numeric => {
                if (getTempIdOrId(numeric) === getTempIdOrId(props.setting)) {
                    return {
                        ...numeric,
                        value: event.target.value,
                    };
                }
                return numeric;
            }),
        });
    }

    function setValueEdit(event: any) {
        props.formikProps.setValues({
            ...props.formikProps.values,
            numeric: props.formikProps.values.numeric.map(numeric => {
                if (numeric.name?.label === props.setting.name?.label) {
                    return {
                        ...numeric,
                        value: event.target.value,
                    };
                }
                return numeric;
            }),
        });
    }

    // if (props.isEdit && !units) {
    if (!units) {
        setUnits(props.unitsById((props.setting.unit as any)?.unitTypeId));
    }

    function setUnit(option: any) {
        props.formikProps.setValues({
            ...props.formikProps.values,
            numeric: props.formikProps.values.numeric.map(numeric => {
                if (numeric?.unit?.value === props.setting?.unit?.value) {
                    return {
                        ...numeric,
                        unit: option,
                    };
                }
                return numeric;
            }),
        });
    }

    return (
        <RowWithError hasError={props.hasError}>
            {props.isEdit ? null : (
                <Checkbox
                    checked={props.isChecked}
                    onChange={onCheckHandle}
                    value="checkedB"
                    color="primary"
                />
            )}
            <SelectBox>
                <FormikCreteableSelect
                    options={props.numerical}
                    defaultOptions
                    isDisabled={props.isEdit}
                    value={props.setting.name}
                    onChange={setName}
                    placeholder="Nazwa"
                />
            </SelectBox>
            <SelectBox>
                <FormikTextField
                    name="value"
                    value={props.setting.value}
                    variant="outlined"
                    onChange={props.isEdit ? setValueEdit : setValue}
                    placeholder="Wartość"
                />
            </SelectBox>
            <SelectBox>
                <FormikSelect
                    name="unit"
                    defaultValue={props.setting.unit}
                    cacheOptions
                    options={units}
                    onChange={setUnit}
                    placeholder="Jednostka"
                />
            </SelectBox>
        </RowWithError>
    );
}
