import React from 'react';
import {
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    ListItemSecondaryAction,
    IconButton,
} from '@material-ui/core';
import { BmgCustomer } from '../../../../../../types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';

interface ResultItemProps extends BmgCustomer {
    closeDrawerHandle: () => void;
}

export function ResultItem(props: ResultItemProps) {
    const generateAvatar = () => {
        if (props.title.length > 2) {
            return `${props.title[0].toUpperCase()}${props.title[1].toUpperCase()}`;
        }

        return `${props.title[0].toUpperCase()}`;
    };

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>{generateAvatar()}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={props.title}
                secondary={`Ilość obiektów: ${props.objectsCount}`}
            />
            <ListItemSecondaryAction>
                <Link
                    onClick={props.closeDrawerHandle}
                    to={`/organization/${props.id}/details`}
                >
                    <IconButton edge="end" aria-label="show">
                        <VisibilityIcon />
                    </IconButton>
                </Link>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
