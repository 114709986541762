import { Parameter } from '../types/ParameterTypes';
import { ParametersActionTypes, ParametersUnion } from '../models/actionTypes';

export interface ParametersState {
    parameters: Parameter[];
    parametersHasFetched: boolean;
    parametersIsFetching: boolean;
    addParameterPending: boolean;
    formData: Partial<Parameter>;
}

const initState: ParametersState = {
    parameters: [],
    parametersHasFetched: false,
    parametersIsFetching: false,
    addParameterPending: false,
    formData: {},
};

export function parameterReducer(
    state: ParametersState = initState,
    action: ParametersUnion,
): ParametersState {
    switch (action.type) {
        case ParametersActionTypes.AddParameter: {
            return {
                ...state,
            };
        }

        case ParametersActionTypes.AddParameterSuccess: {
            return {
                ...state,
            };
        }

        case ParametersActionTypes.FetchParameters: {
            return {
                ...state,
            };
        }

        case ParametersActionTypes.FetchParametersFailed: {
            return {
                ...state,
            };
        }

        case ParametersActionTypes.FetchParametersSuccess: {
            return {
                ...state,
            };
        }

        case ParametersActionTypes.ResetForm: {
            return {
                ...state,
                formData: {},
            };
        }

        case ParametersActionTypes.SetParameterType: {
            return {
                ...state,
                formData: {
                    ...state.formData,
                    type: action.parameterType,
                },
            };
        }

        case ParametersActionTypes.UpdateForm: {
            return {
                ...state,
                formData: action.data,
            };
        }

        default:
            return state;
    }
}
