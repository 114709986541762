import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducer';
import {
    communities,
    communitiesIsFetching,
    communitiesHasFetched,
} from '../../../selectors/location';
import { Community } from '../../../types';
import { getCommunities } from '../../../actions/location';

type CommunitiesOutput = [
    boolean,
    boolean,
    Community[],
    (countyId: number) => void,
];

export function useCommunities(): CommunitiesOutput {
    const dispatch = useDispatch();
    const communitiesFetching = useSelector((appState: AppState) =>
        communitiesIsFetching(appState),
    );

    const communitiesFetched = useSelector((appState: AppState) =>
        communitiesHasFetched(appState),
    );

    const allCommunities = useSelector((appState: AppState) =>
        communities(appState),
    );

    function get(countyId: number) {
        dispatch(getCommunities(countyId));
    }

    return [communitiesFetching, communitiesFetched, allCommunities, get];
}
