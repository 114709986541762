import React from 'react';
import { FormikProps } from 'formik';
import { Button } from '@material-ui/core';
import * as generator from 'generate-password';
import SyncIcon from '@material-ui/icons/Sync';

import {
    FieldContainer,
    BoxWithButton,
    PasswordField,
    UserSurnameContainer,
    UserSurnameDivider,
    WideField,
} from './styled';
import {
    PhoneInput,
    BottomNavBar,
} from '../../../../../../../../../components';
import { FormikTextField } from '../../../../../../../../../tools/FormikFields';
import { AddUserWithPermissions } from '../../../../../../types/AddUserWithPermissions';

interface FieldsProps extends FormikProps<AddUserWithPermissions> {
    openAddOrganization: () => void;
}

export function Fields(props: FieldsProps) {
    function generatePassword() {
        props.setFieldValue('password', generator.generate({ length: 10 }));
    }

    function setPhoneNumber(value: string) {
        props.setFieldValue('phoneMobile', value);
    }

    return (
        <form onSubmit={props.handleSubmit}>
            <UserSurnameContainer>
                <WideField
                    name="name"
                    value={props.values.name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.name && props.touched.name}
                    label="Imię"
                    variant="outlined"
                />
                <UserSurnameDivider />
                <WideField
                    name="surname"
                    value={props.values.surname}
                    onChange={props.handleChange}
                    error={props.errors.surname && props.touched.surname}
                    label="Nazwisko"
                    variant="outlined"
                />
            </UserSurnameContainer>
            <FormikTextField
                name="email"
                value={props.values.email}
                onChange={props.handleChange}
                error={props.errors.email && props.touched.email}
                label="E-mail"
                variant="outlined"
            />
            <FieldContainer>
                <PhoneInput
                    inputExtraProps={{
                        name: 'phoneMobile',
                        required: true,
                        autoFocus: true,
                    }}
                    autoFormat={false}
                    defaultCountry="pl"
                    value={props.values.phoneMobile}
                    onChange={setPhoneNumber}
                />
            </FieldContainer>
            <BoxWithButton>
                <PasswordField
                    name="password"
                    value={props.values.password}
                    onChange={props.handleChange}
                    hasError={props.errors.password && props.touched.password}
                    error={props.errors.password}
                    label="Hasło"
                    variant="outlined"
                />
                <Button
                    variant="contained"
                    color="default"
                    onClick={generatePassword}
                >
                    <SyncIcon />
                    Generuj haslo
                </Button>
            </BoxWithButton>
            <BottomNavBar
                showBack={false}
                showNext={true}
                buttonNextText="Dalej"
            />
        </form>
    );
}
