import React from 'react';
import { TopPanelContainer } from '../../views/Organization/styled';
import { Drawer } from '../Drawer';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Step } from '../ProgressStepper/types';

interface TopPanelProps {
    openAddTemplate: () => void;
    closeAddTemplate: () => void;
    isOpen: boolean;
    label: string;
    drawerSteps: Step[];
    hasProgressStepper?: boolean;
}

export function TopPanel(props: TopPanelProps) {
    return (
        <TopPanelContainer>
            <Drawer
                approveClose={true}
                data={props.drawerSteps}
                hasProgressStepper={props.hasProgressStepper || true}
                drawerIsVisible={props.isOpen}
                setDrawerHide={props.closeAddTemplate}
            />
            <Button onClick={props.openAddTemplate} variant="contained">
                <AddIcon />
                {props.label}
            </Button>
        </TopPanelContainer>
    );
}
