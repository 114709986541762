import React, { useState } from 'react';
import { FormikProps } from 'formik';
import { MapParameter } from '../../../../../Objects/types/MapParameter';
import { Parameter } from '../../../../../Parameters/types/ParameterTypes';
import { BottomPanel } from '../../../BottomPanel';
import { generateRandomString } from '../../../../../../../tools';
import { Row } from './components';
import { BottomNavBar } from '../../../../../../../components/ProgressStepper/components';
import { getTempIdOrId } from '../../../tools/getTempIdOrId';

interface FieldsProps extends FormikProps<MapParameter[]> {
    selectOptions: Parameter[];
    prevStep: () => void;
    validationErrors: string[];
}
export function Fields(props: FieldsProps) {
    const [selectedRows, setRow] = useState<string[]>([]);
    function addNewRow() {
        props.setValues([
            ...props.values,
            {
                id: generateRandomString(),
                parameter: undefined,
            },
        ]);
    }

    function removeRows() {
        props.setValues(
            props.values.filter(item => !selectedRows.includes(item.id)),
        );
        setRow([]);
    }

    function selectRow(id: string) {
        setRow([...selectedRows, id]);
    }

    function setParameter(item: MapParameter, parameter: Parameter) {
        props.setValues(
            props.values.map(value => {
                if (value.id === item.id) {
                    return {
                        ...value,
                        parameter,
                    };
                }
                return value;
            }),
        );
    }

    function renderRows() {
        return props.values.map(row => (
            <Row
                setParameter={setParameter}
                item={row}
                key={row.id}
                hasError={props.validationErrors.includes(row.id)}
                selectRow={selectRow}
                parameters={props.selectOptions.filter(
                    option =>
                        !props.values
                            .filter(param => param.parameter)
                            .map(param => getTempIdOrId(param.parameter))
                            .includes(getTempIdOrId(option)),
                )}
                isChecked={selectedRows.includes(row.id)}
            />
        ));
    }

    return (
        <form onSubmit={props.handleSubmit}>
            {renderRows()}
            <BottomPanel<null>
                actionHandleArg={null}
                primaryButtonActionHandle={addNewRow}
                primaryButtonText="Dodaj"
                secondButtonActionHandle={removeRows}
                secondButtonText="Usuń"
            />
            <BottomNavBar prevStep={props.prevStep} buttonNextText="Dalej" />
        </form>
    );
}
