import React from 'react';
import { FormikProps } from 'formik';
import { Alarm } from '../../../../types/Alarm';
import { SelectBox, WideField } from '../../../../../../../styled/';
import {
    FormikAsyncSelect,
    FormikSelect,
} from '../../../../../../../tools/FormikFields';
import {
    StyledForm,
    VerticalDivider,
    ContentContainer,
} from '../../../../../formStyled';
import { useObjectForm } from '../../../../../Objects/hooks';
import { ParameterTypes } from '../../../../../Parameters/types/ParameterTypes';
import { useFetchAsyncSelectData } from '../../../../../Parameters/hooks/useFetchAsyncSelectData';
import { CONDITION_VALUE } from './constants/conditionValue';

export function Fields(props: FormikProps<Alarm>) {
    const [formData] = useObjectForm();
    const {
        fetchAlertPriorities,
        fetchAlertChannels,
    } = useFetchAsyncSelectData();
    return (
        <StyledForm onSubmit={props.handleSubmit}>
            <ContentContainer>
                <WideField
                    name="name"
                    value={props.values.name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.name && props.touched.name}
                    label="Nazwa"
                    variant="outlined"
                />
                <SelectBox>
                    <FormikAsyncSelect
                        name="priority"
                        cacheOptions
                        
                        value={props.values.priority}
                        loadOptions={fetchAlertPriorities}
                        defaultOptions
                        error={props.errors.priority && props.touched.priority}
                        onChange={(option: any) =>
                            props.setFieldValue('priority', option)
                        }
                        onBlur={props.handleBlur}
                        placeholder="Priorytet"
                    />
                </SelectBox>
                <SelectBox>
                    <FormikAsyncSelect
                        name="platform"
                        cacheOptions
                        
                        loadOptions={fetchAlertChannels}
                        value={props.values.platform}
                        defaultOptions
                        isMulti
                        error={props.errors.platform && props.touched.platform}
                        onChange={(option: any) =>
                            props.setFieldValue('platform', option)
                        }
                        onBlur={props.handleBlur}
                        placeholder="Platform"
                    />
                </SelectBox>
                <WideField
                    name="description"
                    value={props.values.description}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    multiline
                    rows={5}
                    error={
                        props.errors.description && props.touched.description
                    }
                    label="Pełny opis"
                    variant="outlined"
                />
            </ContentContainer>
            <VerticalDivider />
            <ContentContainer>
                <SelectBox>
                    <FormikSelect
                        name="variableName"
                        cacheOptions
                        
                        value={props.values.variableName}
                        options={formData.parameters
                            .filter(
                                parameter =>
                                    parameter.type === ParameterTypes.Binary,
                            )
                            .map(value => ({
                                value: value.tempId,
                                label: value.name!.label,
                            }))}
                        error={
                            props.errors.variableName &&
                            props.touched.variableName
                        }
                        onChange={(option: any) =>
                            props.setFieldValue('variableName', option)
                        }
                        onBlur={props.handleBlur}
                        placeholder="Nazwa zmiennej"
                    />
                </SelectBox>
                <FormikSelect
                    name="value"
                    value={props.values.value}
                    options={CONDITION_VALUE}
                    placeholder="Wartość"
                    onChange={(option: any) =>
                        props.setFieldValue('value', option)
                    }
                    
                />
            </ContentContainer>
        </StyledForm>
    );
}
