import { MachineObject } from '../types';
import { ObjectsActionTypes, ObjectsUnion } from '../models/actionTypes';
import { initBasicData } from './constants/initBasicData';
import { initLocalization } from './constants/initLocalization';
import { initSettings } from './constants/initSettings';
import { initPhotoGalleries } from './constants/initPhotoGalleries';
import { initStatusAppearance } from './constants/initStatusAppearance';
import { initParametersAppearance } from './constants/initParametersAppearance';
import { initEngineSymbol } from './constants/initEngineSymbol';

interface StateByOrganizationId {
    objects: MachineObject[];
    objectFetching: boolean;
    objectHasFetched: boolean;
}

export interface OrganizationDetailsState {
    objectTypes: any;
    objectTypesIsFetching: any;
    objectTypesHasFetched: any;
    objectParameters: any;
    objectParametersIsFetching: any;
    objectParametersHasFetched: any;
    objectSettings: any;
    objectSettingsIsFetching: any;
    objectSettingsHasFetched: any;
    addObjectForm: MachineObject;
    removedParameters: string[];
    addObjectIsPending: boolean;
    addObjectError: string;
    addObjectIsSuccess: boolean;
    updateObjectIsPending: boolean;
    updateObjectError: string;
    updateObjectIsSuccess: boolean;
    [key: number]: StateByOrganizationId;
}

export const initialStateByOrganizationId: StateByOrganizationId = {
    objects: [],
    objectFetching: false,
    objectHasFetched: false,
};

const initialOrganizationDetails: OrganizationDetailsState = {
    addObjectForm: {
        draftId: '',
        summary: {
            templateName: '',
        },
        basicData: initBasicData,
        localization: initLocalization,
        settings: initSettings,
        parameters: [],
        alarms: [],
        engineSymbol: initEngineSymbol,
        mapParameters: [],
        statusAppearance: initStatusAppearance,
        parametersAppearance: initParametersAppearance,
        photoGalleries: initPhotoGalleries,
        documents: [],
    },
    objectTypes: null,
    objectParametersHasFetched: false,
    objectParametersIsFetching: false,
    removedParameters: [],
    addObjectIsSuccess: false,
    objectSettingsHasFetched: false,
    objectSettingsIsFetching: false,
    objectTypesHasFetched: false,
    objectTypesIsFetching: false,
    objectParameters: null,
    objectSettings: null,
    addObjectIsPending: false,
    addObjectError: '',
    updateObjectIsPending: false,
    updateObjectError: '',
    updateObjectIsSuccess: false,
};

function objectsByOrganization(
    state: StateByOrganizationId = initialStateByOrganizationId,
    action: ObjectsUnion,
): StateByOrganizationId {
    switch (action.type) {
        case ObjectsActionTypes.GetObjects: {
            return {
                ...state,
                objectFetching: true,
            };
        }

        case ObjectsActionTypes.ChangeOrder: {
            return {
                ...state,
                objects: [...action.objects],
            };
        }

        case ObjectsActionTypes.GetObjectsSuccessfully: {
            return {
                ...state,
                objectFetching: false,
                objectHasFetched: true,
                objects: action.objects,
            };
        }

        case ObjectsActionTypes.GetObjectsFailure: {
            return {
                ...state,
                objectFetching: false,
                objectHasFetched: false,
                objects: [],
            };
        }

        default:
            return state;
    }
}

export function objectsReducer(
    state: OrganizationDetailsState = initialOrganizationDetails,
    action: ObjectsUnion,
): OrganizationDetailsState {
    switch (action.type) {
        case ObjectsActionTypes.GetObjects:
        case ObjectsActionTypes.ChangeOrder:
        case ObjectsActionTypes.GetObjectsSuccessfully:
        case ObjectsActionTypes.GetObjectsFailure: {
            return {
                ...state,
                [action.organizationId]: objectsByOrganization(
                    state[action.organizationId],
                    action,
                ),
            };
        }

        case ObjectsActionTypes.AddObject: {
            return {
                ...state,
                addObjectIsPending: true,
                addObjectIsSuccess: false,
            };
        }

        case ObjectsActionTypes.AddObjectSuccess: {
            return {
                ...state,
                addObjectIsPending: false,
                addObjectIsSuccess: true,
            };
        }

        case ObjectsActionTypes.AddObjectFailed: {
            return {
                ...state,
                addObjectIsPending: false,
                addObjectError: action.error,
            };
        }

        case ObjectsActionTypes.SetRemovedParameters: {
            return {
                ...state,
                removedParameters: action.parameters,
            };
        }

        case ObjectsActionTypes.GetObjectTypes: {
            return {
                ...state,
                objectTypesIsFetching: true,
            };
        }

        case ObjectsActionTypes.GetObjectTypesSuccess: {
            return {
                ...state,
                objectTypesIsFetching: false,
                objectTypesHasFetched: true,
                objectTypes: action.objectTypes,
            };
        }

        case ObjectsActionTypes.GetObjectTypesFailed: {
            return {
                ...state,
                objectTypesIsFetching: false,
                objectTypesHasFetched: false,
                objectTypes: null,
            };
        }

        case ObjectsActionTypes.GetObjectSettings: {
            return {
                ...state,
                objectSettingsIsFetching: true,
            };
        }

        case ObjectsActionTypes.GetObjectSettingsSuccess: {
            return {
                ...state,
                objectSettingsIsFetching: false,
                objectSettingsHasFetched: true,
                objectSettings: action.objectSettings,
            };
        }

        case ObjectsActionTypes.GetObjectSettingsFailed: {
            return {
                ...state,
                objectSettingsIsFetching: false,
                objectSettingsHasFetched: false,
                objectSettings: null,
            };
        }

        case ObjectsActionTypes.UpdateObject: {
            return {
                ...state,
                updateObjectIsPending: true,
                updateObjectIsSuccess: false,
            };
        }

        case ObjectsActionTypes.UpdateObjectSuccess: {
            return {
                ...state,
                updateObjectIsPending: false,
                updateObjectIsSuccess: true,
            };
        }

        case ObjectsActionTypes.UpdateObjectFailed: {
            return {
                ...state,
                updateObjectIsPending: false,
                updateObjectIsSuccess: false,
                updateObjectError: action.error,
            };
        }

        case ObjectsActionTypes.GetObjectParameters: {
            return {
                ...state,
                objectParametersIsFetching: true,
            };
        }

        case ObjectsActionTypes.GetObjectParametersSuccess: {
            return {
                ...state,
                objectParametersHasFetched: true,
                objectParametersIsFetching: false,
                objectSettings: action.objectParameters,
            };
        }

        case ObjectsActionTypes.GetObjectParametersFailed: {
            return {
                ...state,
                objectParametersHasFetched: false,
                objectParametersIsFetching: false,
                objectSettings: null,
            };
        }

        case ObjectsActionTypes.UpdateAddObjectFormData: {
            return {
                ...state,
                addObjectForm: {
                    ...state.addObjectForm,
                    ...action.formValue,
                    draftId: action.draftId,
                },
            };
        }

        case ObjectsActionTypes.ResetErrorsAddObjectFormData: {
            return {
                ...state,
                updateObjectError: '',
                addObjectError: '',
            };
        }

        case ObjectsActionTypes.ResetAddObjectFormData: {
            return {
                ...state,
                addObjectForm: initialOrganizationDetails.addObjectForm,
                addObjectIsPending: false,
                addObjectIsSuccess: false,
                updateObjectIsPending: false,
                updateObjectIsSuccess: false,
                updateObjectError: '',
            };
        }

        default:
            return state;
    }
}
