import React, { useEffect, useState } from 'react';

import { DataGrid } from '../../../../../components/DataGrid';
import { RouteComponentProps } from 'react-router-dom';
import { useUserData } from '../../hooks/useUserData';
import { headings } from './constants/gridHeadings';
import { gridKeys } from './constants/gridKeys';
import { User } from '../../types';
import { useAddEditForm } from '../../hooks/useAddEditForm';
import { LoaderContainer } from '../../../../../styled/LoaderContainer';
import { Loader } from '../../../../../components/Loader';
import { Modal } from '@material-ui/core';
import { ApproveAction, ModalBody } from '../../../../../components';

interface GridProps extends RouteComponentProps<{ id: string }> {}

export function Grid(props: GridProps) {
    const { id } = props.match.params;
    const organizationId = Number(id);
    const {
        users,
        getUsersAction,
        usersFetching,
        deleteUserHandle,
    } = useUserData(organizationId);
    const [, , openEditForm, ,] = useAddEditForm(organizationId);
    const [
        removeUserModalApproveIsOpen,
        setRemoveUserModalApproveIsOpen,
    ] = useState<{
        state: boolean;
        user: User | null;
    }>({
        state: false,
        user: null,
    });
    function clickHandle({ user }: { user: User }) {
        openEditForm(user);
    }

    function deleteUser() {
        if (removeUserModalApproveIsOpen.user) {
            deleteUserHandle(removeUserModalApproveIsOpen.user);
            closeApproveModal();
        }
    }

    function closeApproveModal() {
        setRemoveUserModalApproveIsOpen({ state: false, user: null });
    }

    function openApproveModal({ user }: { user: User }) {
        setRemoveUserModalApproveIsOpen({
            state: true,
            user,
        });
    }

    const preparedArr = users.map(user => ({
        ...user,
        menuComponents: [
            {
                name: 'Edytuj',
                clickHandle,
                args: { user: user },
            },
            {
                name: 'Usuń',
                clickHandle: openApproveModal,
                args: { user: { ...user, organizationId } },
            },
        ],
    }));

    useEffect(() => {
        getUsersAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (usersFetching) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );
    }

    return (
        <div>
            <Modal
                open={removeUserModalApproveIsOpen.state}
                onClose={closeApproveModal}
            >
                <ModalBody closeModalOnCLick={closeApproveModal}>
                    <ApproveAction
                        desc="Czy napewno chcesz usunąć tego użytkownika?"
                        approveOnClick={deleteUser}
                        cancelOnClick={closeApproveModal}
                    />
                </ModalBody>
            </Modal>
            <DataGrid
                showMenu={true}
                headings={headings}
                keys={gridKeys}
                data={preparedArr}
            />
        </div>
    );
}
