import React, { useImperativeHandle } from 'react';
import { Formik, FormikProps } from 'formik';
import { Alarm } from '../../../../types/Alarm';
import { Fields } from './Fields';
import { AlarmsSchema } from './constants/validationScheme';

interface AddEditFormProps {
    formData: Alarm;
    updateFormData: (alarm: Alarm) => void;
}

function Form(props: AddEditFormProps, ref: any) {
    let formikRefProps: FormikProps<Alarm>;

    useImperativeHandle(ref, () => ({
        ...formikRefProps,
    }));

    function onSubmit(data: Alarm) {
        props.updateFormData(data);
    }

    return (
        <Formik<Alarm>
            initialValues={props.formData}
            enableReinitialize={true}
            validationSchema={AlarmsSchema}
            onSubmit={onSubmit}
            render={(props: FormikProps<Alarm>) => {
                formikRefProps = props;
                return <Fields {...props} />;
            }}
        />
    );
}

export const AddEditForm = React.forwardRef(Form);
