import { useEffect, useState } from 'react';
import { useFetchAsyncSelectData } from '../../Parameters/hooks/useFetchAsyncSelectData';
import { SelectOption } from '../../../../types/SelectOption';

interface UseVariablesOutput {
    hasFetched: boolean;
    isLoading: boolean;
    variables: SelectOption[];
}

export function useVariables(): UseVariablesOutput {
    const { fetchVariables } = useFetchAsyncSelectData();
    const [variablesState, setVariablesState] = useState<UseVariablesOutput>({
        hasFetched: false,
        isLoading: false,
        variables: [],
    });

    useEffect(() => {
        async function fetchVariablesHandle() {
            const data: SelectOption[] = await fetchVariables();

            setVariablesState({
                ...variablesState,
                hasFetched: true,
                variables: data,
            });
        }

        if (!variablesState.hasFetched && !variablesState.isLoading) {
            setVariablesState({
                ...variablesState,
                isLoading: true,
            });
            fetchVariablesHandle();
        }
    }, [fetchVariables, variablesState]);

    return variablesState;
}
