import React from 'react';
import { FormikCheckBox } from '../../../../../../../../../../../tools/FormikFields';
import { ObjectWithPermission } from '../../../../../../../../types/ObjectsWithPermissions';
import { useFetchAsyncSelectData } from '../../../../../../../../../Parameters/hooks/useFetchAsyncSelectData';
import { TableCell, TableRow } from '@material-ui/core';
import { WrappedFormikAsyncSelect } from './styled';
import { MachineObject } from '../../../../../../../../../Objects/types';

interface RowProps {
    objectPermission: ObjectWithPermission;
    organizationId: string;
    setAlarmPermission: (object: ObjectWithPermission) => void;
    setOrderPermission: (object: ObjectWithPermission) => void;
    setDataShowingPermission: (object: ObjectWithPermission) => void;
    selectRow: (object: ObjectWithPermission) => void;
    selectObject: (
        object: ObjectWithPermission,
        machineObject: MachineObject,
    ) => void;
    isSelected: boolean;
    orderIsDisabled: boolean;
    alarmsCreateIsDisabled: boolean;
    usedObjects: string[];
}

export function Row(props: RowProps) {
    const { fetchObjects } = useFetchAsyncSelectData();

    function setOrderPermissionHandle() {
        props.setOrderPermission(props.objectPermission);
    }

    function setAlarmPermissionHandle() {
        props.setAlarmPermission(props.objectPermission);
    }

    function setDataShowingPermissionHandle() {
        props.setDataShowingPermission(props.objectPermission);
    }

    function selectRowHandle() {
        props.selectRow(props.objectPermission);
    }

    function selectObject(value: MachineObject) {
        props.selectObject(props.objectPermission, value);
    }

    return (
        <TableRow style={{ height: 75 }}>
            <TableCell align="center" padding="checkbox" style={{ width: 45 }}>
                <FormikCheckBox
                    checked={props.isSelected}
                    onChange={selectRowHandle}
                />
            </TableCell>
            <TableCell style={{ minWidth: 200 }}>
                <WrappedFormikAsyncSelect
                    name="object"
                    cacheOptions
                    loadOptions={async () => {
                        const data: any = await fetchObjects(
                            Number(props.organizationId),
                        );
                        return data.filter(
                            (item: any) =>
                                !props.usedObjects.includes(item.value),
                        );
                    }}
                    value={props.objectPermission.object}
                    onChange={selectObject}
                    defaultOptions
                    placeholder="Obiekt"
                />
            </TableCell>
            <TableCell align="center" padding="checkbox">
                <FormikCheckBox
                    name="dataIsShowing"
                    checked={props.objectPermission.permissions.dataIsShowing}
                    onChange={setDataShowingPermissionHandle}
                />
            </TableCell>
            <TableCell align="center" padding="checkbox">
                <FormikCheckBox
                    name="order"
                    disabled={props.orderIsDisabled}
                    checked={props.objectPermission.permissions.order}
                    onChange={setOrderPermissionHandle}
                />
            </TableCell>
            <TableCell align="center" padding="checkbox">
                <FormikCheckBox
                    name="createAlarms"
                    disabled={props.alarmsCreateIsDisabled}
                    checked={props.objectPermission.permissions.createAlarms}
                    onChange={setAlarmPermissionHandle}
                />
            </TableCell>
        </TableRow>
    );
}
