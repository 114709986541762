export const CONDITION_VALUE = [
    {
        label: 0,
        value: 0,
    },
    {
        label: 1,
        value: 1,
    },
]
