import React from 'react';
import { Alarm } from '../../../../../../../Alarms/types/Alarm';
import { StyledCheckbox } from '../../../../../../../styled/StyledCheckbox';
import { WideField } from '../../../../../../../../../styled/';
import { FormikSelect } from '../../../../../../../../../tools/FormikFields';
import {
    InlineButtonBox,
    InlineFieldDivider,
} from '../../../../../../../formStyled';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { RowWithError } from '../../../../../../../Objects/components/TopPanel/styled';

interface AlarmFieldProps {
    alarm: Alarm;
    editHandle: (alarm: Alarm) => void;
    checkHandle: (name: string) => void;
    isChecked: boolean;
    changeAlarmName: (name: string, tempId: string) => void;
    hasError: boolean;
}

export function AlarmField(props: AlarmFieldProps) {
    function onCheckHandle() {
        props.checkHandle(props.alarm.tempId);
    }

    function onEditHandle() {
        props.editHandle(props.alarm);
    }

    function editName(event: React.ChangeEvent<HTMLInputElement>) {
        props.changeAlarmName(event.target.value, props.alarm.tempId);
    }

    return (
        <RowWithError hasError={props.hasError}>
            <StyledCheckbox
                checked={props.isChecked}
                onChange={onCheckHandle}
                color="primary"
            />
            <WideField
                name="name"
                value={props.alarm.name}
                variant="outlined"
                onChange={editName}
                placeholder="Nazwa alarmu"
            />
            <InlineFieldDivider />
            <FormikSelect
                name="priority"
                value={props.alarm.priority}
                cacheOptions
                isDisabled={true}
                defaultOptions
                placeholder="Priorytet"
            />
            <InlineFieldDivider />
            <FormikSelect
                name="platform"
                value={props.alarm.platform}
                isMulti
                cacheOptions
                isDisabled={true}
                defaultOptions
                placeholder="Powiadomienie"
            />
            <InlineFieldDivider />
            <InlineButtonBox>
                <Button onClick={onEditHandle} variant="text">
                    <EditIcon />
                </Button>
            </InlineButtonBox>
        </RowWithError>
    );
}
