import React from 'react';
import { DataGrid } from '../../../../../../../components/DataGrid';
import {
    NumericSettings,
    Settings,
    TextualSettings,
} from '../../../../../Objects/types';
import {
    headingNumericKeys,
    headingsNumeric,
    headingTextual,
    headingTextualKeys,
} from './constants/headings';
import { CELL_WIDTH } from '../../constants/cellWidth';

interface SettingsDataProps {
    settings: Settings;
}

export function SettingsData(props: SettingsDataProps) {
    return (
        <>
            <h2>Ustawienia</h2>
            <h3>Tekstowe</h3>
            <DataGrid<TextualSettings>
                cellWidth={CELL_WIDTH}
                data={props.settings.textual.map(value => ({
                    ...value,
                    name: value.name?.label || ('' as any),
                }))}
                keys={headingTextualKeys}
                headings={headingTextual}
            />
            <h3>Numeryczne</h3>
            <DataGrid<NumericSettings>
                cellWidth={CELL_WIDTH}
                data={props.settings.numeric.map(value => ({
                    ...value,
                    name: value.name?.label || ('' as any),
                    unit: value.unit?.label || ('' as any),
                }))}
                keys={headingNumericKeys}
                headings={headingsNumeric}
            />
        </>
    );
}
