import React, { useRef } from 'react';
import { default as MaterialDrawer } from '@material-ui/core/Drawer/Drawer';
import { DrawerContentContainer } from '../../../../../../../components/Drawer/styled';
import { SubFormContainer } from '../../../../../formStyled';
import { Subheading } from '../../../../../../../components/Subheading';
import { BottomPanel } from '../../../../../../../components/ProgressStepper/components/BottomNavBar/styled';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
    AppearanceBase,
    BoxAppearance,
} from '../../../../../Objects/types/AppearanceBase';
import { Form } from './Form';
import { FormikProps } from 'formik';
import { Parameter } from '../../../../../Parameters/types/ParameterTypes';

interface EditBoxFormProps {
    formData: BoxAppearance;
    drawerIsOpen: boolean;
    closeDrawer: () => void;
    updateConfig: (config: AppearanceBase) => void;
    config: AppearanceBase;
    removeBox: (id: string) => void;
    parameters: Parameter[];
}

export function EditBox(props: EditBoxFormProps) {
    const formRef = useRef<FormikProps<any>>();

    async function submitEdit() {
        await formRef.current!.submitForm();
    }

    function editBoxHandle(appearance: BoxAppearance) {
        delete props.config.box[appearance.id];
        props.updateConfig({
            ...props.config,
            box: { ...props.config.box, [appearance.id]: appearance },
        });
    }

    function removeBox() {
        props.removeBox(props.formData.id);
    }

    return (
        <MaterialDrawer
            anchor="right"
            open={props.drawerIsOpen}
            onClose={props.closeDrawer}
        >
            <DrawerContentContainer size="normal">
                <SubFormContainer>
                    <Subheading>Edycja box-a</Subheading>
                    <Form
                        ref={formRef}
                        formData={props.formData}
                        updateFormData={editBoxHandle}
                        closeDrawer={props.closeDrawer}
                        parameters={props.parameters}
                    />
                    <BottomPanel>
                        <Button onClick={props.closeDrawer}>
                            <ArrowBackIosIcon />
                            Anuluj
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submitEdit}
                        >
                            zapisz
                        </Button>
                    </BottomPanel>
                    <BottomPanel>
                        <Button color="secondary" onClick={removeBox}>
                            Usuń box
                        </Button>
                    </BottomPanel>
                </SubFormContainer>
            </DrawerContentContainer>
        </MaterialDrawer>
    );
}
