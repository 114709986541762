import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import { Drawer } from '../../../../../components/Drawer';
import { TopPanelContainer } from '../../../styled';
import { Flex } from '../../../../../styled/';
import { Drafts } from './components';
import { ObjectDraft } from '../../types/ObjectDraft';

interface TopPanelProps {
    isOpen: boolean;
    openAddObject: () => void;
    closeAddObject: () => void;
    openDrafts: () => void;
    draftsIsOpened: boolean;
    closeDrafts: () => void;
    drafts: ObjectDraft[];
    openDraft: (id: string) => void;
    drawerSteps: any;
    removeDraft: (id: string) => void;
}

export function TopPanel(props: TopPanelProps) {
    return (
        <TopPanelContainer>
            <Drawer
                approveClose={true}
                data={props.drawerSteps}
                hasProgressStepper={true}
                drawerIsVisible={props.isOpen}
                setDrawerHide={props.closeAddObject}
            />
            <Drawer
                approveClose={false}
                hasProgressStepper={false}
                drawerIsVisible={props.draftsIsOpened}
                setDrawerHide={props.closeDrafts}
                size="small"
            >
                <Drafts
                    removeDraft={props.removeDraft}
                    openDraft={props.openDraft}
                    drafts={props.drafts.sort((a, b) => {
                        if (a.time > b.time) {
                            return -1;
                        }
                        if (a.time < b.time) {
                            return +1;
                        }
                        return 0;
                    })}
                />
            </Drawer>
            <Flex justifyContent="space-between">
                <Button onClick={props.openAddObject} variant="contained">
                    <AddIcon />
                    Dodaj nowy
                </Button>
                <Button onClick={props.openDrafts} variant="outlined">
                    Wersji robocze
                </Button>
            </Flex>
        </TopPanelContainer>
    );
}
