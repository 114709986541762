import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducer';
import {
    organizationObjects,
    organizationObjectsFetched,
    organizationObjectsFetching,
} from '../selectors/objects';
import { changeOrder, deleteObject, getObjects } from '../actions/objects';
import { MachineObject } from '../types';

type UseObjectsOutput = [
    MachineObject[],
    boolean,
    boolean,
    () => void,
    (objects: MachineObject[]) => void,
    (objectId: number) => void,
];

export function useObjects(organizationId: number): UseObjectsOutput {
    const dispatch = useDispatch();
    const objects = useSelector((state: AppState) =>
        organizationObjects(state, organizationId),
    );
    const objectsIsPending = useSelector((state: AppState) =>
        organizationObjectsFetching(state, organizationId),
    );
    const objectsFetched = useSelector((state: AppState) =>
        organizationObjectsFetched(state, organizationId),
    );

    function getOrganizationObjects() {
        dispatch(getObjects(organizationId));
    }

    function changeObjectsOrder(objects: MachineObject[]) {
        dispatch(changeOrder(objects, organizationId));
    }

    async function deleteObjectHandle(objectId: number) {
        await deleteObject(organizationId, objectId);
        getOrganizationObjects();
    }

    return [
        objects,
        objectsIsPending,
        objectsFetched,
        getOrganizationObjects,
        changeObjectsOrder,
        deleteObjectHandle,
    ];
}
