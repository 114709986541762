import { Localization } from '../../types';

export const initLocalization: Localization = {
    town: '',
    postCode: '',
    address: '',
    county: '',
    community: '',
    voivodeship: '',
    latitude: 0,
    longitude: 0,
};
