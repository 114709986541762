import styled from 'styled-components';
import { WideField } from '../../../styled/';

export const InlineFieldContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    ${WideField} {
        margin-bottom: 0;
    }
`;
