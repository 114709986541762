import styled from 'styled-components';
import { Flex } from '../../../styled/';
import { BottomPanel } from '../../../components/ProgressStepper/components/BottomNavBar/styled';

export const SubFormContainer = styled(Flex).attrs({
    flexDirection: 'column',
})`
    width: 100%;

    h2 {
        padding: 10px 20px 0 20px;
    }

    ${BottomPanel} {
        padding: 0 20px;
    }
`;
