import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { TableBody } from '@material-ui/core';

interface BodyContainerProps {
    children: JSX.Element | JSX.Element[];
}

function Component(props: BodyContainerProps) {
    return <TableBody>{props.children}</TableBody>;
}

export const BodyContainer = SortableContainer(Component);
